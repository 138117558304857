
import styles from "./style.module.css";
import Slider from "react-slick";

const FeatureScroll = ({ scrollToForm }) => {

    const settings = {
        dots: true,
        infinite: false,
        Animation: false,
        speed: 500,
        autoplaySpeed: 8000,
        autoplay: false,
        fade: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // margin: 20,
        // centerPadding: "20",
        dotsClass: "slick-dots categories-slick-dots dashboard-slick-dots slick-dots-mf-snippet",
    };


    // <Slider ref={sliderRef1} {...settings}></Slider>

    return (
        <section className={`${styles.featureScrollSection}`}>
            <div className={`text-center ${styles.GlobalText2}`} style={{ paddingBottom: '0' }}>
                Track the Performance of Your Investments
            </div>

            <div className={`${styles.featureSection} ${styles.featureSectionDesktopTab}`}>
                <section className={`${styles.featureContainer}`}>
                    <div className={`${styles.featureContentContainer}`}>
                        <div className={`${styles.featureTxtContent}`} >
                            <div className={`${styles.GlobalText2}`} style={{ padding: '0.8rem 0' }}>
                                Analyse the performance of funds you are invested in!
                            </div>
                            <div className={`${styles.Text1}`}>
                                Exit the funds that are not performing well.
                            </div>
                            <div className={`${styles.featureTxtContentBtn1}`} style={{ marginTop: '1.5rem' }} onClick={() => scrollToForm()}>
                                <span>Explore Now</span><i class="fa-solid fa-arrow-right"></i>
                            </div>
                        </div>
                        <div className={`${styles.featureImgContent}`}>
                            <img className={`${styles.featureImg2}`} src={process.env.REACT_APP_STATIC_URL + '/media/MFSnippet/feature1.svg'} alt="" />
                        </div>
                    </div>
                </section>
                <section className={`${styles.featureContainer}`}>
                    <div className={`${styles.featureContentContainer}`}>
                        <div className={`${styles.featureTxtContent}`} >
                            <div className={`${styles.GlobalText2}`} style={{ padding: '0.8rem 0' }}>
                                Sector wise allocation for a bird’s eye view!
                            </div>
                            <div className={`${styles.Text1}`}>
                                Ensure that your funds are diversified.
                            </div>
                            <div className={`${styles.featureTxtContentBtn1}`} style={{ marginTop: '1.5rem' }} onClick={() => scrollToForm()}>
                                <span>Explore Now</span><i class="fa-solid fa-arrow-right"></i>
                            </div>
                        </div>
                        <div className={`${styles.featureImgContent}`} >
                            <img className={`${styles.featureImg2}`} src={process.env.REACT_APP_STATIC_URL + '/media/MFSnippet/feature2.svg'} alt="" />
                        </div>
                    </div>
                </section>
                <section className={`${styles.featureContainer}`}>
                    <div className={`${styles.featureContentContainer}`}>
                        <div className={`${styles.featureTxtContent}`} >
                            <div className={`${styles.GlobalText2}`} style={{ padding: '0.8rem 0' }}>
                                Recommendations for optimum performance of your portfolio!
                            </div>
                            <div className={`${styles.Text1}`}>
                                Ensure you don’t lose out on opportunities to make gains.
                            </div>
                            <div className={`${styles.featureTxtContentBtn1}`} style={{ marginTop: '1.5rem' }} onClick={() => scrollToForm()}>
                                <span>Explore Now</span><i class="fa-solid fa-arrow-right"></i>
                            </div>
                        </div>
                        <div className={`${styles.featureImgContent}`} >
                            <img className={`${styles.featureImg2}`} src={process.env.REACT_APP_STATIC_URL + '/media/MFSnippet/feature3.svg'} alt="" />
                        </div>
                    </div>
                </section>
            </div>

            <div className={`${styles.featureSection} ${styles.featureSectionMobile}`}>
                <Slider {...settings}>
                    <section className={`${styles.featureContainer}`} style={{ height: '600px' }}>
                        <div className={`${styles.featureContentContainer}`}>
                            <div className={`${styles.featureTxtContent}`} >
                                <div className={`${styles.GlobalText2}`} style={{ padding: '0.8rem 0' }}>
                                    Analyse the performance of funds you are invested in!
                                </div>
                                <div className={`${styles.Text1}`}>
                                    Exit the funds that are not performing well.
                                </div>
                                <div className={`${styles.featureTxtContentBtn1}`} style={{ marginTop: '1.5rem' }} onClick={() => scrollToForm()}>
                                    <span>Explore Now</span><i class="fa-solid fa-arrow-right"></i>
                                </div>
                            </div>
                            <div className={`${styles.featureImgContent}`} style={{ height: '350px', display: 'flex', alignItems: 'center' }}>
                                <img className={`${styles.featureImg2}`} src={process.env.REACT_APP_STATIC_URL + '/media/MFSnippet/feature1.svg'} alt="" />
                            </div>
                        </div>
                    </section>
                    <section className={`${styles.featureContainer}`} style={{ height: '600px' }}>
                        <div className={`${styles.featureContentContainer}`}>
                            <div className={`${styles.featureTxtContent}`} >
                                <div className={`${styles.GlobalText2}`} style={{ padding: '0.8rem 0' }}>
                                    Sector wise allocation for a bird’s eye view!
                                </div>
                                <div className={`${styles.Text1}`}>
                                    Ensure that your funds are diversified.
                                </div>
                                <div className={`${styles.featureTxtContentBtn1}`} style={{ marginTop: '1.5rem' }} onClick={() => scrollToForm()}>
                                    <span>Explore Now</span><i class="fa-solid fa-arrow-right"></i>
                                </div>
                            </div>
                            <div className={`${styles.featureImgContent}`} style={{ height: '350px', display: 'flex', alignItems: 'center' }}>
                                <img className={`${styles.featureImg2}`} src={process.env.REACT_APP_STATIC_URL + '/media/MFSnippet/feature2.svg'} alt="" />
                            </div>
                        </div>
                    </section>
                    <section className={`${styles.featureContainer}`} style={{ height: '600px' }}>
                        <div className={`${styles.featureContentContainer}`}>
                            <div className={`${styles.featureTxtContent}`} >
                                <div className={`${styles.GlobalText2}`} style={{ padding: '0.8rem 0' }}>
                                    Recommendations for optimum performance of your portfolio!
                                </div>
                                <div className={`${styles.Text1}`}>
                                    Ensure you don’t lose out on opportunities to make gains.
                                </div>
                                <div className={`${styles.featureTxtContentBtn1}`} style={{ marginTop: '1.5rem' }} onClick={() => scrollToForm()}>
                                    <span>Explore Now</span><i class="fa-solid fa-arrow-right"></i>
                                </div>
                            </div>
                            <div className={`${styles.featureImgContent}`} style={{ height: '350px', display: 'flex', alignItems: 'center' }}>
                                <img className={`${styles.featureImg2}`} src={process.env.REACT_APP_STATIC_URL + '/media/MFSnippet/feature3.svg'} alt="" />
                            </div>
                        </div>
                    </section>


                </Slider>

            </div>

        </section>
    );
};

export default FeatureScroll;
