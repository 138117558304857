import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Link, useSearchParams } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import FormRangeSlider from "../CommonDashboardComponents/FormRangeSlider";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import FormSwitch from "../CommonDashboardComponents/formSwitch";
import SimpleReactValidator from "simple-react-validator";
// import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import OTPInput from "otp-input-react";
import {
  DMF_ADDFDBOND_API_URL,
  DMF_GETBANKLIST_API_URL,
  ADVISORY_ADD_ASSETS_API,
  ADVISORY_GET_ASSETS_API,
  ADVISORY_UPDATE_ASSETS_API,
  ADVISORY_CALCULATE_EPF_MATURITY_AMOUNT,
  IS_DIRECT,
  CHECK_SESSION,
  DMF_UPDATEBASICDETAILS_API_URL,
  DMF_GETUSERDETAILS_API_URL,
} from "../../../../constants";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import {
  fetchEncryptData,
  getUserId,
  apiCall,
  getFpUserDetailsId,
  getParentUserId,
  getItemLocal,
  fv,
} from "../../../../common_utilities";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { MdHomeMax } from "react-icons/md";
import LinkEPF from "./LinkEPF";

const numericRegex = new RegExp(/^\d*\.?\d*$/);

const initialState = {
  typeOfAsset: "",
  bankInstituteName: "",
  bankInstituteOtherName: "",
  purchaseDate: "",
  purchaseAmount: "",
  accountBalanceAsOnToday: "",
  installmentAmount: "",
  payoutType: "1",
  maturityDate: "",
  maturityAmount: "",
  monthlySalary: "",
  gratuityAmount: "",
  currentEPFBalance: "",
  employeesMonthlyContribution: "",
  employerMonthlyContribution: "",
  asset_sub_category_id: "",
  uan: "",
  // retirementAge:"",
  // lifeExpentancy:""
};

const defaultAssetDetails = {
  Created_By: 0,
  Updated_By: 0,
  asset_amount: "",
  asset_abreturn: "0",
  annual_growth_rate: "10",
  asset_broker_id: 0,
  asset_category_id: 38,
  asset_citytype: "0",
  asset_current_unit_price: "",
  asset_currency: false,
  asset_ecas_type: "manual",
  asset_epf_ismanual: "1",
  asset_folio_number: null,
  asset_footnote: null,
  asset_frequency: "1",
  asset_goal_link_id: 0,
  asset_goalname: null,
  asset_gold_karat: 0,
  asset_isActive: "1",
  asset_ismortgage: "0",
  asset_isperpetual: "3",
  asset_isallocation: false,
  asset_iselss: "1",
  asset_islinkable: true,
  asset_isrecurring: false,
  asset_isrented: "1",
  asset_maturity_amt: 0,
  asset_maturity_date: null,
  asset_member_id: 0,
  asset_mf_end_date: null,
  asset_name: "Art Investment",
  asset_pan: null,
  asset_payout_type: "1",
  asset_pin_code: "",
  asset_purchase_amount: "",
  asset_purchase_date: null,
  asset_rental_amount: "",
  asset_rental_income: null,
  asset_ror: "0",
  asset_sub_category_id: 64,
  asset_unique_code: "",
  asset_units: 0,
  categorydetail: "Debt",
  created_datetime: moment().format("DD/MM/YYYY"),
  employee_contribution: "",
  employer_contribution: "",
  installment_ispaid: 1,
  membername1: "",
  stock_mf: null,
  stock_name: null,
  subcategorydetail: "",
  totalinvestedvalue: "",
  totalpurchasevalue: "",
  totalmaturtiyamount: "",
  updated_datetime: moment().format("DD/MM/YYYY"),
  user_id: 0,
  scheme_equityshare: {},
  linked_goals_id: [],
};

const NewFdBondsFormView = () => {
  const [, forceUpdate] = useState(1);
  const [formData, setFormData] = useState(initialState);
  const [assetsDetails, setAssetsDetails] = useState(defaultAssetDetails);
  const [addForm, setAddForm] = useState(true);
  const [updateForm, setUpdateForm] = useState(false);
  const [assetEditId, setAssetEditId] = useState("");
  const [otpInput,setOtpInput] = useState("")
  const [activeIndex, setActiveIndex] = useState(1);
  const [sliderValue, setSliderValue] = useState("Yearly");
  const [maturityCalculation, setMaturityCalculation] = useState(0);

  const [activeIndex2, setActiveIndex2] = useState(4);
  const [isInstallmentPaid, setIsInstallmentPaid] = useState(true);

  const [interestRate, setInterestRate] = useState(0);
  const [rateOfReturn, setRateOfReturn] = useState(8.1);
  const [growthRate, setGrowthRate] = useState(10);
  const [yearsOfService, setYearsOfService] = useState("5");
  const [allBank, setAllBank] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [session, setSession] = useState([]);
  const [modalType, setModalType] = useState(0);

  // ---------------------------------------------------- EPF states ----------------------------------------------------
  const [showEPFForm, setShowEPFForm] = useState(false);
  const [showUANModal, setShowUANModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [isManual, setIsManual] = useState(false);

  // --------------------------------------------------------------------------------------------------------------------

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    checksession();
    // userDetails();
    // console.log(activeIndex,"kkkk")
  }, []);

  const checksession = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);

      if (session_data.error_code == "100") {
        setSession(session_data);
        
      } else {
        loginRedirectGuest();
      }

      
    } catch (error) {
      console.log(error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something Went Wrong1");
    }
  };

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
      let asset_id = urlParams.get("id");
      if (asset_id) {
        setUpdateForm(true);
        setAddForm(false);
        setAssetEditId(asset_id);
        editFdData(asset_id);

      }
  },[])
  const userDetails = async() =>{
    try {
    var payload = {
      url: DMF_GETUSERDETAILS_API_URL,
      method: "post",
      data: {
        user_id: getItemLocal("family") ? getParentUserId() : "" + getUserId(),
        is_direct: IS_DIRECT
      },
    };

    var res = await fetchEncryptData(payload);
    if (res.error_code * 1 == 100) {
      let temp_data = {};
      temp_data["retirementAge"] = res?.data?.retirement_age
      temp_data["lifeExpentancy"] = res?.data?.life_expectancy
      setFormData({...formData,...temp_data})
    }
  }
  catch (e){
    console.log(e);
  }
  }
  const formatDatefun = (date) => {
    // return moment(date).format("YYYY/MM/DD");
    console.log("uuuu: ", moment(date).toDate());

    return moment(date).toDate();
  };

  function editMaturityAmount({
    asset_ror,
    asset_purchase_date,
    asset_purchase_amount,
    asset_maturity_date,
  }) {
    // Convert dates to Date objects
    const purchaseDate = new Date(asset_purchase_date);
    const maturityDate = new Date(asset_maturity_date);

    const years =
      (maturityDate - purchaseDate) / (1000 * 60 * 60 * 24 * 365.25);

    const rate = asset_ror / 100;

    const purchaseAmount = parseFloat(asset_purchase_amount);

    const maturityAmount = purchaseAmount * Math.pow(1 + rate, years);

    return maturityAmount.toFixed(2);
  }

  const editFdData = async (id) => {
    try {
      let url = ADVISORY_GET_ASSETS_API;
      let payload = {
        id: id,
        user_id: getParentUserId(),
        asset_type: "none",
      };
      let editFdData = await apiCall(url, payload, true, false);
      if (editFdData["error_code"] == "100") {
        // var fdData = editFdData["data"][0];
        var msg = editFdData["data"][0]["subcategorydetail"]
          ? " - " + editFdData["data"][0]["subcategorydetail"]
          : "";

        var editData = editFdData["data"][0];

        console.log("editdata: ", editData);
        console.log("object111",getItemLocal("member"));
        let payout_type = "";

        if (editData["asset_payout_type"] == "2") {
          setActiveIndex2(editData["asset_frequency"]);
        }

        // let url2 = "https://stg.minty.co.in/direct-mutual-fund/api/common/getmaturityvalue";
        // let payload2 = {
        //   investment_date: moment(editData['asset_purchase_date']).format("YYYY-DD-MM"),
        //   maturity_date: moment(editData['asset_maturity_date']).format("YYYY-DD-MM"),
        //   amount: editData['asset_purchase_amount'],
        //   interest_rate: editData['asset_ror'],
        // };
        // let m_data = await apiCall(url2, payload2, true, false);
        // console.log(m_data,"2222",m_data.error_code)
        // if(m_data.error_code == "100")
        // {
        //   console.log(m_data,"444",m_data['data']['maturity_value'])
        //   setMaturityCalculation(Number(m_data['data']['maturity_value']))
        // }

        const asset = {
          asset_ror: editData["asset_ror"], // Interest Rate (%)
          asset_purchase_date: editData["asset_purchase_date"], // Purchase Date
          asset_purchase_amount: editData["asset_purchase_amount"], // Purchase Amount
          asset_maturity_date: editData["asset_maturity_date"], // Maturity Date
          asset_frequency: editData["asset_frequency"], // Payout Type (number of times interest is compounded per year)
        };

        // console.log(editMaturityAmount(asset),"??????")
        // console.log(editData["asset_maturity_amt"],"ffff",editData,">>>>")

        console.log(
          "ddddate: ",
          editData["asset_maturity_amt"],
          moment(editData["asset_purchase_date"], "DD/MM/YYYY").toDate()
        );
        console.log(
          "pp:",
          editData["subcategorydetail"] == "Govt Bonds"
            ? "Bonds"
            : editData["subcategorydetail"]
        );

        console.log("edi", editData, editData["asset_sub_category_id"] === 117);

        let temp_form_data = {};
        temp_form_data["typeOfAsset"] =
          editData["subcategorydetail"] == "Govt Bonds"
            ? "Bonds"
            : editData["subcategorydetail"];
        temp_form_data["asset_sub_category_id"] =
          editData["asset_sub_category_id"];

        if (editData["asset_sub_category_id"] === 117) {
          setIsManual(true);
          setShowEPFForm(true);

          temp_form_data["currentEPFBalance"] = Number(
            editData["asset_current_unit_price"]
          );
          temp_form_data["employeesMonthlyContribution"] = Number(
            editData["employee_monthly_contrib"]
          );
          temp_form_data["employerMonthlyContribution"] = Number(
            editData["employeer_monthly_contrib"]
          );
          setRateOfReturn(Number(editData["asset_ror"]));
          setGrowthRate(Number(editData["asset_annual_growth_rate"]));
          temp_form_data["maturityDate"] = moment(
            editData["asset_maturity_date"],
            "DD/MM/YYYY"
          ).toDate();
          setMaturityCalculation(Number(editData["asset_maturity_amt"]));
        } else if (editData["asset_sub_category_id"] === 82) {
          temp_form_data["monthlySalary"] = Number(
            editData["asset_current_unit_price"]
          );
          setYearsOfService(Number(editData["asset_annual_growth_rate"]));
          temp_form_data["gratuityAmount"] = Number(
            editData["asset_maturity_amt"]
          );
          temp_form_data["bankInstituteName"] = editData["asset_name"];
        } else if (editData["asset_sub_category_id"] === 120) {
          temp_form_data["accountBalanceAsOnToday"] = Number(
            editData["asset_current_unit_price"]
          );
          setMaturityCalculation(Number(editData["asset_maturity_amt"]));

          temp_form_data["maturityDate"] = moment(
            editData["asset_maturity_date"],
            "DD/MM/YYYY"
          ).toDate();
          // setInterestRate(editData["asset_ror"]);
          setRateOfReturn(Number(editData["asset_ror"]));
          temp_form_data["installmentAmount"] = Number(
            editData["asset_purchase_amount"]
          );
          setActiveIndex2(editData["asset_frequency"]);

          if (
            editData["asset_frequency"] === 4 &&
            editData["installment_ispaid"] == 1
          ) {
            // fdData["installment_ispaid"] == "1"
            setIsInstallmentPaid(true);
          } else {
            // fdData["installment_ispaid"] == "2"
            setIsInstallmentPaid(false);
          }
          console.log("installment_ispaid", editData["installment_ispaid"]);
        } else {
          temp_form_data["bankInstituteName"] = editData["asset_name"];
          // temp_form_data["purchaseDate"] = new Date(editData["asset_purchase_date"]);
          temp_form_data["purchaseDate"] = moment(
            editData["asset_purchase_date"],
            "DD/MM/YYYY"
          ).toDate();
          temp_form_data["purchaseAmount"] = Number(
            editData["asset_purchase_amount"]
          );
          temp_form_data["payoutType"] = editData["asset_payout_type"];
          temp_form_data["maturityDate"] = moment(
            editData["asset_maturity_date"],
            "DD/MM/YYYY"
          ).toDate();
          // temp_form_data["maturityAmount"] = editData["asset_purchase_amount"];
          setInterestRate(Number(editData["asset_ror"]));
          setMaturityCalculation(Number(editData["asset_maturity_amt"]));
        } 


        // var payload_data = {
        //   url: DMF_GETUSERDETAILS_API_URL,
        //   method: "post",
        //   data: {
        //     user_id: getItemLocal("family") ? getParentUserId() : "" + getUserId(),
        //     is_direct: IS_DIRECT
        //   },
        // };
    
        // var res = await fetchEncryptData(payload_data);
        // if (res.error_code * 1 == 100) {
        //   temp_form_data["retirementAge"] = res?.data?.retirement_age
        //   temp_form_data["lifeExpentancy"] = res?.data?.life_expectancy
        // }
        setFormData({ ...formData, ...temp_form_data });
        // userDetails();

        // setFormData({ ...formData,
        //   typeOfAsset:  editData["subcategorydetail"] == "Govt Bonds" ? "Bonds" : editData["subcategorydetail"],
        //   bankInstituteName: editData["asset_name"],
        //   // purchaseDate: new Date(editData["asset_purchase_date"]),
        //   purchaseDate: moment(editData["asset_purchase_date"], "DD/MM/YYYY").toDate(),
        //   purchaseAmount: editData["asset_purchase_amount"],
        //   payoutType: payout_type,
        //   maturityDate: moment(editData["asset_maturity_date"], "DD/MM/YYYY").toDate(),
        //   // maturityAmount: editData["asset_maturity_amt"],
        //   maturityAmount: editData["asset_purchase_amount"],
        //   // monthlySalary: "",
        //   // gratuityAmount: "",
        //   // currentEPFBalance: "",
        // });
        // setInterestRate(Number(editData["asset_ror"]));
        // setMaturityCalculation(Number(editData["asset_maturity_amt"]));

        // const fdData = {
        //   user_id: getUserId(),
        //   bank_name: formData.bankInstituteName,
        //   investment_date: investDate.format("YYYY-MM-DD"),
        //   maturity_date: maturityDate.format("YYYY-MM-DD"),
        //   amount: (formData.purchaseAmount * 1),
        //   maturity_value: (maturityCalculation * 1),
        //   interest_compounding: formData.payoutType === "Cumulative" ? "Yearly" : sliderValue,
        //   interest_rate: interestRate.toString(),
        //   is_direct: IS_DIRECT,
        //   is_active: "1",
        //   status: "1",
        //   payout_type: formData.payoutType,
        //   asset_type: formData.typeOfAsset
        // };
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const options = [
    { value: "Fixed Deposit", label: "Fixed Deposit" },
    { value: "Recurring Deposit", label: "Recurring Deposit" },
    { value: "Bonds", label: "Bonds" },
    { value: "Debentures", label: "Debentures" },
    { value: "Gratuity", label: "Gratuity" },
    // { value: "EPF", label: "EPF" },
    { value: "Others", label: "Others" },
  ];

  const optionSubCategory = {
    "Fixed Deposit": 75,
    Bonds: 79,
    Debentures: 80,
    Others: 87,
    Gratuity: 82,
    "Recurring Deposit": 120,
    // EPF: 117,
  };

  const Purchaseoptions = [
    { value: 1, label: "Cumulative" },
    { value: 2, label: "Non-Cumulative" },
  ];

  // const options_payout = [
  //   { value: "Cumulative", label: "Cumulative" },
  //   { value: "Non-Cumulative", label: "Non-Cumulative" },
  // ];

  // const handleClick = (index, value) => {
  //   setActiveIndex(index);
  //   setSliderValue(value);
  // };

  const handleClick2 = (index, value) => {
    setActiveIndex2(index);
    setSliderValue(value);
  };

  const onInputChange = async (e, isNumeric) => {
    const name = e.target.name;
    let value = e.target.value;

    if (isNumeric && !numericRegex.test(value) && value !== "") {
      return;
    }
    // let url = DMF_UPDATEBASICDETAILS_API_URL;
    // let data = ""
    // if (name == "retirementAge"){
    //   data = {
    //     user_id: getUserId(),
    //     retirement_age: value
    //   };
    // }
    // if (name == "lifeExpentancy"){
    //   data = {
    //     user_id: getUserId(),
    //     life_expectancy: value
    //   };
    // }
    // let datagatheringinsert = await fetchEncryptData({
    //   url,
    //   data,
    //   method: "post",
    // });
    // if (datagatheringinsert){
    //   checksession()
    // }

    setFormData({ ...formData, [name]: value });
  };

  const onPurchaseInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
    });
  };

  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    console.log(
      formData.typeOfAsset,
      "111",
      simpleValidator.current.allValid()
    );
    console.log("csdsd", formData.typeOfAsset);
    if (
      simpleValidator.current.allValid() &&
      (formData.typeOfAsset === "Fixed Deposit" ||
        formData.typeOfAsset === "Bonds" ||
        formData.typeOfAsset === "Debentures" ||
        formData.typeOfAsset === "Others" ||
        formData.typeOfAsset === "Gratuity" ||
        formData.typeOfAsset === "Recurring Deposit" ||
        formData.typeOfAsset === "EPF")
    ) {
      // addFdBond();
      addAssets();
      // setFormData({ ...initialState });
      // setInterestRate(0);
      // simpleValidator.current.hideMessages();
    } else {
      simpleValidator.current.showMessages();
      forceUpdate((v) => ++v);
    }
  };

  const updateFdBonds = () => {
    console.log("csdsd", formData.typeOfAsset);
    if (
      simpleValidator.current.allValid() &&
      (formData.typeOfAsset === "Fixed Deposit" ||
        formData.typeOfAsset === "Bonds" ||
        formData.typeOfAsset === "Debentures" ||
        formData.typeOfAsset === "Others" ||
        formData.typeOfAsset === "EPF" ||
        formData.typeOfAsset === "Gratuity" ||
        formData.typeOfAsset == "Recurring Deposit")
    ) {
      // addFdBond();
      updateFdBond();
      // setFormData({ ...initialState });
      // setInterestRate(0);
      // simpleValidator.current.hideMessages();
    } else {
      simpleValidator.current.showMessages();
      forceUpdate((v) => ++v);
    }
  };

  console.log(formData, "formdata");

  const addAssets = async () => {
    // e.preventDefault();

    try {
      let url = ADVISORY_ADD_ASSETS_API;
      let fdData = assetsDetails;

      fdData["user_id"] = getParentUserId();
      let member_id = await getFpUserDetailsId();
      fdData["asset_member_id"] = member_id;
      console.log("Fp member:", member_id);

      fdData["subcategorydetail"] = formData.typeOfAsset;

      if (
        formData.asset_sub_category_id === 120 ||
        formData.asset_sub_category_id === 117
      ) {
        fdData["asset_name"] = formData.typeOfAsset;
      } else {
        fdData["asset_name"] = formData.bankInstituteName;
      }

      if (formData.asset_sub_category_id == 82) {
        fdData["annual_growth_rate"] = yearsOfService;
        fdData["asset_current_unit_price"] = formData.monthlySalary;
        fdData["asset_maturity_amt"] = formData.gratuityAmount;
        fdData["asset_purchase_date"] = null;
        fdData["asset_maturity_date"] = null;
      } else if (formData.asset_sub_category_id === 120) {
        fdData["asset_current_unit_price"] = formData.accountBalanceAsOnToday;
        fdData["asset_maturity_amt"] = maturityCalculation;
        fdData["asset_purchase_date"] = null;
        fdData["asset_maturity_date"] = moment(formData.maturityDate).format(
          "DD/MM/YYYY"
        );
        fdData["asset_ror"] = rateOfReturn;
        fdData["asset_purchase_amount"] = formData.installmentAmount;
        fdData["asset_frequency"] = "" + activeIndex2;

        if (activeIndex2 === 4) {
          fdData["installment_ispaid"] = isInstallmentPaid;
        }

      } else if (formData.asset_sub_category_id === 117) {
        fdData["asset_current_unit_price"] = formData.currentEPFBalance;
        fdData["employee_contribution"] = formData.employeesMonthlyContribution;
        fdData["employer_contribution"] = formData.employerMonthlyContribution;
        fdData["asset_maturity_amt"] = maturityCalculation;
        fdData["asset_purchase_date"] = null;
        fdData["asset_maturity_date"] = moment(formData.maturityDate).format(
          "DD/MM/YYYY"
        );
        fdData["asset_ror"] = rateOfReturn;
        fdData["annual_growth_rate"] = growthRate;
      } else {
        fdData["asset_maturity_amt"] = maturityCalculation;
        fdData["asset_purchase_date"] = moment(formData.purchaseDate).format(
          "DD/MM/YYYY"
        );
        fdData["asset_maturity_date"] = moment(formData.maturityDate).format(
          "DD/MM/YYYY"
        );
        fdData["asset_ror"] = interestRate;
        fdData["asset_purchase_amount"] = formData.purchaseAmount;
        fdData["asset_payout_type"] = formData.payoutType;

        if (formData.payoutType == "2") {
          fdData["asset_payout_type"] = "2";
          fdData["asset_frequency"] = "" + activeIndex;
        }
      }

      fdData["categorydetail"] = "Debt";
      fdData["asset_sub_category_id"] = optionSubCategory[formData.typeOfAsset];

      let addassetData = await apiCall(url, fdData, true, false);
     
      if (addassetData["error_code"] == "100") {
        navigate(
          process.env.PUBLIC_URL +
          "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=3"
        );

        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `${formData.typeOfAsset} added Successfully!`,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `${formData.typeOfAsset} not added, Something went wrong!`,
            type: "error",
          },
        });
      }
    } catch (err) {
      // setIsLoading(false);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const updateFdBond = async () => {
    // e.preventDefault();

    try {
      let url = ADVISORY_UPDATE_ASSETS_API;
      // let payload = assetsDetails;
      let fdData = assetsDetails;
      // setAssetsDetails({
      //   ...defaultAssetDetails,
      //   asset_name: getGoldTypeData(options, formData.typeOfAsset),
      //   subcategorydetail: getGoldTypeData(options, formData.typeOfAsset),
      //   asset_maturity_amt: maturityCalculation,
      //   asset_maturity_date: formData.maturityDate,
      //   categorydetail: "Debt",
      //   asset_ror: interestRate
      // })
      fdData["id"] = assetEditId;
      fdData["user_id"] = getParentUserId();
      let member_id = await getFpUserDetailsId();
      fdData["asset_member_id"] = member_id;
      console.log("Fp member:", member_id);
      fdData["categorydetail"] = "Debt";
      fdData["subcategorydetail"] = formData.typeOfAsset;
      fdData["asset_sub_category_id"] = formData.asset_sub_category_id;

      if (
        fdData["asset_sub_category_id"] === 75 ||
        fdData["asset_sub_category_id"] === 79 ||
        fdData["asset_sub_category_id"] === 80 ||
        fdData["asset_sub_category_id"] === 87
      ) {
        fdData["asset_name"] = formData.bankInstituteName;
        fdData["asset_maturity_amt"] = maturityCalculation;
        fdData["asset_purchase_date"] = moment(formData.purchaseDate).format(
          "DD/MM/YYYY"
        );
        fdData["asset_maturity_date"] = moment(formData.maturityDate).format(
          "DD/MM/YYYY"
        );
        fdData["asset_ror"] = interestRate;
        fdData["asset_purchase_amount"] = formData.purchaseAmount;
        // fdData["asset_sub_category_id"] = optionSubCategory[formData.typeOfAsset]

        // fdData["asset_payout_type"] = "2"
        // fdData["asset_frequency"] = "" + activeIndex
        fdData["asset_payout_type"] = formData.payoutType;

        if (formData.payoutType == "2") {
          // fdData["asset_payout_type"] = "2";
          fdData["asset_frequency"] = "" + activeIndex2;
        }
      } else if (formData.asset_sub_category_id === 117) {
        fdData["asset_name"] = formData.typeOfAsset;
        fdData["asset_current_unit_price"] = formData.currentEPFBalance;
        fdData["employee_contribution"] = formData.employeesMonthlyContribution;
        fdData["employer_contribution"] = formData.employerMonthlyContribution;
        fdData["asset_maturity_amt"] = maturityCalculation;
        fdData["asset_purchase_date"] = null;
        fdData["asset_maturity_date"] = moment(formData.maturityDate).format(
          "DD/MM/YYYY"
        );
        fdData["asset_ror"] = rateOfReturn;
        fdData["annual_growth_rate"] = growthRate;
      } else if (fdData["asset_sub_category_id"] === 82) {
        fdData["annual_growth_rate"] = yearsOfService;
        fdData["asset_current_unit_price"] = formData.monthlySalary;
        fdData["asset_maturity_amt"] = formData.gratuityAmount;
        fdData["asset_purchase_date"] = null;
        fdData["asset_maturity_date"] = null;
        fdData["asset_name"] = formData.bankInstituteName;
      } else if (formData.asset_sub_category_id === 120) {
        fdData["asset_current_unit_price"] = formData.accountBalanceAsOnToday;
        fdData["asset_maturity_amt"] = maturityCalculation;
        fdData["asset_purchase_date"] = null;
        fdData["asset_maturity_date"] = moment(formData.maturityDate).format(
          "DD/MM/YYYY"
        );
        fdData["asset_ror"] = rateOfReturn;
        fdData["asset_purchase_amount"] = formData.installmentAmount;
        fdData["asset_frequency"] = "" + activeIndex2;
        fdData["installment_ispaid"] = isInstallmentPaid;
      }

      // if (formData.payoutType == "Cumulative"){
      //   fdData["asset_payout_type"] = "1"

      // }else{
      //   fdData["asset_payout_type"] = "2"
      //   fdData["asset_frequency"] = "" + activeIndex
      // }

      console.log("Payload fdData: ", fdData);

      let addassetData = await apiCall(url, fdData, true, false);
      if (addassetData["error_code"] == "100") {
        navigate(
          process.env.PUBLIC_URL +
          "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=3"
        );
        // if (formData.typeOfAsset === 'Fixed Deposit') {
        //   var apiMessage = 'Fixed Deposite'
        // } else if (formData.typeOfAsset === 'Bonds') {
        //   var apiMessage = 'Bonds'
        // } else if (formData.typeOfAsset === 'Debentures') {
        //   var apiMessage = 'Debentures'
        // } else {
        //   var apiMessage = 'Others'
        // }
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `${formData.typeOfAsset} updated Successfully!`,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `${formData.typeOfAsset} not updated, Something went wrong!`,
            type: "error",
          },
        });
      }
    } catch (err) {
      // setIsLoading(false);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const getGoldTypeData = (goldTypeData, label) => {
    return label ? goldTypeData.find((data) => data.label === label) : "";
  };

  const showEPFInputs = () => {
    if (formData.typeOfAsset != "Gratuity") {
      if (formData.typeOfAsset === "EPF") {
        return showEPFForm;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const showSubmit = () => {
    if (formData.typeOfAsset === "EPF") {
      return showEPFForm;
    } else {
      return true;
    }
  };

  useEffect(() => {
    getBanks();
    if (formData.typeOfAsset != "EPF") {
      setShowEPFForm(false);
      setShowUANModal(false);
      setShowOTPModal(false);
      setIsManual(false);
    }
  }, [formData.typeOfAsset]);
  const investDate = moment(formData.purchaseDate, "DD/MM/YYYY");
  const maturityDate = moment(formData.maturityDate, "DD/MM/YYYY");
  const tenure =
    maturityDate.diff(investDate, "years") < 1
      ? 1
      : maturityDate.diff(investDate, "years");

  console.log("ppdate: ", moment(formData?.maturityDate).format("DD/MM/YYYY"));

  useEffect(() => {
    console.log("sscat: ", formData.asset_sub_category_id);
    let calculatematurityamount = 0.0;

    if (
      formData.asset_sub_category_id === 75 ||
      formData.asset_sub_category_id === 79 ||
      formData.asset_sub_category_id === 80 ||
      formData.asset_sub_category_id === 87
    ) {
      if (formData?.maturityDate && formData?.purchaseDate) {
        const mf_yr_f = moment(formData?.maturityDate)
          .format("DD/MM/YYYY")
          .split("/");
        const c_yr_f = moment(formData?.purchaseDate)
          .format("DD/MM/YYYY")
          .split("/");
        const firstDate = new Date(
          parseInt(mf_yr_f[2]),
          parseInt(mf_yr_f[1]) - 1,
          parseInt(mf_yr_f[0])
        );
        const secondDate = new Date(
          parseInt(c_yr_f[2]),
          parseInt(c_yr_f[1]) - 1,
          parseInt(c_yr_f[0])
        );
        const timeDiff = Math.abs(firstDate.getTime() - secondDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const nper = parseFloat((diffDays / 365.2425).toFixed(2));
       if (formData.payoutType == "1"){
        let calculatematurityamount = fv(
          interestRate,
          nper,
          0,
          formData.purchaseAmount
        );

        calculatematurityamount =
          calculatematurityamount > 999999999
            ? Math.round(calculatematurityamount)
            : calculatematurityamount.toFixed(2);

        if (formData.purchaseAmount == "") {
          setMaturityCalculation(Number(0));
        } else if (
          calculatematurityamount !== 0 &&
          calculatematurityamount !== null &&
          calculatematurityamount !== undefined
        ) {
          setMaturityCalculation(Number(calculatematurityamount));
        } else {
          setMaturityCalculation(Number(calculatematurityamount));
        }
       }
       else{
        setMaturityCalculation(Number(formData.purchaseAmount));
       }
      
      }
    } else if (formData.asset_sub_category_id === 82) {
      if (formData.monthlySalary) {
        calculatematurityamount =
          (15 * formData.monthlySalary * yearsOfService) / 26;

        calculatematurityamount =
          calculatematurityamount > 999999999
            ? Math.round(calculatematurityamount)
            : calculatematurityamount.toFixed(2);
      }

      if (
        calculatematurityamount !== 0 &&
        calculatematurityamount !== null &&
        calculatematurityamount !== undefined
      ) {
        // setMaturityCalculation(Number(calculatematurityamount));
        setFormData({
          ...formData,
          gratuityAmount: calculatematurityamount,
        });
      } else {
        // setMaturityCalculation(Number(calculatematurityamount));
      }
    } else if (formData.asset_sub_category_id === 120) {
      if (formData?.maturityDate) {
        const assetMaturityDate = moment(formData?.maturityDate).format(
          "DD/MM/YYYY"
        );

        const c_yr = session?.["data"]?.["plan_date"] ? session?.["data"]?.["plan_date"] : new Date();
        const c_yr_date = new Date(c_yr);
        const mf_yr_f = assetMaturityDate.split("/");
        const adatetosplit = assetMaturityDate;
        const firstDate = new Date(
          parseInt(mf_yr_f[2]),
          parseInt(mf_yr_f[1]) - 1,
          parseInt(mf_yr_f[0])
        );
        const secondDate = c_yr_date;
        const timeDiff = Math.abs(firstDate.getTime() - secondDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const nper = parseFloat((diffDays / 365.2425).toFixed(2));
        const month_var = [0, 12, 4, 2, 1];

        if (formData.installmentAmount) {
          // if(formData.asset_sub_category_id === 75){var index1 = activeIndex}else{var index1 = activeIndex2 }

          const pmt =
            parseInt(formData.installmentAmount) * month_var[activeIndex2];

          calculatematurityamount = fv(
            rateOfReturn,
            nper,
            pmt,
            formData.accountBalanceAsOnToday
          );

          calculatematurityamount =
            calculatematurityamount > 999999999
              ? Math.round(calculatematurityamount)
              : calculatematurityamount.toFixed(2);

          if (parseInt(formData.installmentAmount) == 0) {
            calculatematurityamount = 0;
          }

          if (
            calculatematurityamount !== 0 &&
            calculatematurityamount !== null &&
            calculatematurityamount !== undefined
          ) {
            setMaturityCalculation(Number(calculatematurityamount));
          } else {
            setMaturityCalculation(Number(calculatematurityamount));
          }
          // function fv(rate, nper, pmt, pv) {
          //   let futureValue = pv * Math.pow((1 + rate), nper);
          //   return futureValue;
          // }
        }
      }
    } else if (formData.asset_sub_category_id === 117) {
      if (formData?.maturityDate) {
        const c_yr = session?.data?.plan_date ? session["data"]["plan_date"] : "" ;
        const c_yr_date = new Date(c_yr);

        if (typeof c_yr === "undefined") {
          c_yr_date = new Date("d/m/Y");
        }

        calculateMaturityAmount();
      }
    }
  }, [
    formData?.purchaseDate,
    formData?.purchaseAmount,
    interestRate,
    formData?.maturityDate,
    sliderValue,
    formData?.monthlySalary,
    yearsOfService,
    activeIndex2,
    rateOfReturn,
    growthRate,
    formData?.employeesMonthlyContribution,
    formData?.employerMonthlyContribution,
    formData?.currentEPFBalance,
    formData?.payoutType,
    formData?.accountBalanceAsOnToday,
    formData?.installmentAmount,
    interestRate,
  ]);

  const calculateMaturityAmount = async () => {
    try {
      const payload = {
        url: ADVISORY_CALCULATE_EPF_MATURITY_AMOUNT,
        data: {
          user_id: session["data"]["id"],
          fp_log_id: session["data"]["fp_log_id"],
          fp_user_id: await getFpUserDetailsId(),
          asset_current_unit_price: parseFloat(formData.currentEPFBalance).toFixed(2),
          asset_ror: rateOfReturn,
          employee_monthly_contrib:
            formData.employeesMonthlyContribution != ""
              ? parseFloat(formData.employeesMonthlyContribution)
              : 0,
          employer_monthly_contrib:
            formData.employerMonthlyContribution != ""
              ? parseFloat(formData.employerMonthlyContribution)
              : 0,
          annual_growth_rate: growthRate,
          asset_maturity_date: moment(formData?.maturityDate).format(
            "DD/MM/YYYY"
          ),
        },
        method: "post",
      };

      console.log("payy:", payload);
      const res = await fetchEncryptData(payload);
      if (res["error_code"] == "100") {
        var calculatematuarityamount = Math.round(JSON.parse(res["data"], 2));

        if (calculatematuarityamount != 0) {
          setMaturityCalculation(calculatematuarityamount);
          // setFormData((prev) => ({
          //   ...prev,
          //   asset_maturity_amt: calculatematuarityamount,
          //   totalmaturityamount: calculatematuarityamount,
          // }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBanks = async () => {
    try {
      const payload = {
        method: "POST",
        url: DMF_GETBANKLIST_API_URL,
        data: {},
      };
      const bankResp = await fetchEncryptData(payload);
      setAllBank(bankResp.data);
    } catch (e) {
      console.log(e);
    }
  };

  const addFdBond = async () => {
    try {
      const fdData = {
        user_id: getParentUserId(),
        bank_name: formData.bankInstituteName,
        investment_date: investDate.format("YYYY-MM-DD"),
        maturity_date: maturityDate.format("YYYY-MM-DD"),
        amount: formData.purchaseAmount * 1,
        maturity_value: maturityCalculation * 1,
        interest_compounding:
          formData.payoutType === "Cumulative" ? "Yearly" : sliderValue,
        interest_rate: interestRate.toString(),
        is_direct: IS_DIRECT,
        is_active: "1",
        status: "1",
        payout_type: formData.payoutType,
        asset_type: formData.typeOfAsset,
      };

      const payload = {
        method: "POST",
        url: DMF_ADDFDBOND_API_URL,
        data: fdData,
      };

      const response = await fetchEncryptData(payload);

      if (response.error_code == "100") {
        navigate(
          process.env.PUBLIC_URL +
          "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=3"
        );
        if (formData.typeOfAsset === "Fixed Deposit") {
          var apiMessage = "Fixed Deposite";
        } else if (formData.typeOfAsset === "Bonds") {
          var apiMessage = "Bonds";
        } else if (formData.typeOfAsset === "Debentures") {
          var apiMessage = "Debentures";
        } else {
          var apiMessage = "Others";
        }
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `${apiMessage} added Successfully!`,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `${apiMessage} not added, Something went wrong!`,
            type: "error",
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  simpleValidator.current.purgeFields();

  // Select Options Styles
  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "#ffff",
        color: isFocused ? "#042b62" : isSelected ? "#042b62" : "gray",
        cursor: "pointer",
      };
    },
    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      scrollBehavior: "smooth",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#042b62",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  console.log("otpinput",otpInput);
  return (
    <>
      <div className="px-0 px-md-4 assetForm">
        <div
          className="p-3"
          style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
        >
          <div className="d-flex">
            <Link
              to={
                process.env.PUBLIC_URL +
                "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=3"
              }
            >
              {" "}
              <img
                style={{
                  transform: "rotate(180deg)",
                }}
                width={20}
                height={20}
                src={process.env.PUBLIC_URL + "/static/media/icons/chevron.svg"}
              />
            </Link>

            {addForm && (
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Add Your FD & Bonds
              </h3>
            )}

            {updateForm && (
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Edit Your FD & Bonds
              </h3>
            )}
          </div>
          <hr style={{ color: "#afafaf" }} />
          <div className="row">
            <div className="col-12 col-md-11 col-lg-8 m-auto">
              {addForm && (
                <p className="text-center">
                  Enter Your Details To Add Existing Assets
                </p>
              )}

              {updateForm && (
                <p className="text-center">
                  Enter Your Details To Edit Existing Assets
                </p>
              )}

              <br />
              <br />
              <div>
                <div className="my-md-4">
                  <div className="">
                    <span className="lbl-newbond">Select Type Of Asset *</span>
                    <br />
                    <Select
                      className={`${updateForm === true ? "disabled" : ""
                        } fnto-dropdown-react`}
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={customStyles}
                      options={options}
                      name="typeOfAsset"
                      value={getGoldTypeData(options, formData.typeOfAsset)}
                      onChange={(e) => {
                        // onDateAndSelectInputChang    e("typeOfAsset", e.label);
                        console.log("option: ", optionSubCategory[e.label]);
                        setFormData({
                          ...formData,
                          typeOfAsset: e.label,
                          bankInstituteName: "",
                          purchaseDate: "",
                          purchaseAmount: "",
                          payoutType: "1",
                          maturityDate: "",
                          asset_sub_category_id: optionSubCategory[e.label],
                        });
                        setInterestRate(0);
                        setMaturityCalculation(0);
                        setActiveIndex(1);

                        let x = optionSubCategory[e.label];
                        console.log("printx:", x);
                        let ror = 0;
                        switch (x) {
                          // case 82:
                          //   growth_rate = "5";
                          //   break;
                          // case 81:
                          // case 85:
                          // case 86:
                          case 75:
                          case 79:
                          case 80:
                          case 87:
                            ror = 0;
                            break;
                          case 120:
                            ror = 6.8;
                            break;
                          case 117:
                            ror = 8.1;
                            break;
                          default:
                            ror = 0;
                            break;
                        }

                        if (x == 75 || x == 79 || x == 80 || x == 87) {
                          setInterestRate(ror);
                        }
                        if (x == 120 || x == 117) {
                          setRateOfReturn(ror);
                        }
                      }}
                    />
                    {simpleValidator.current.message(
                      "typeOfAsset",
                      formData.typeOfAsset,
                      "required"
                    )}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {formData.typeOfAsset === "EPF" && !assetEditId && (
                    <>
                      <div>
                        <br />
                        <div className="d-flex justify-content-center gap-3 ">
                          <div className="my-md-4">
                            <button
                              style={{ padding: "0.5rem 0.8rem" }}
                              className={`d-block m-auto btn fecthBtn ${isManual ? "btn-primary" : "btn-outline-primary"
                                }`}
                              onClick={() => {
                                setIsManual(true);
                                setShowEPFForm(true);
                              }}
                            >
                              Manual Entry
                            </button>
                          </div>
                          <div className="my-md-4">
                            <button
                              style={{ padding: "0.5rem 0.8rem" }}
                              className={`d-block m-auto btn fecthBtn ${isManual ? "btn-outline-primary" : "btn-primary"
                                }`}
                              onClick={() => {
                                setShowUANModal(true);
                                setModalType(0);
                                // console.log("setModalType", showUANModal)
                              }}
                            >
                              Fetch Details
                            </button>
                          </div>
                        </div>
                        <br />
                      </div>
                      {!showEPFForm ? (
                        <>
                          <div>
                            <h3
                              className="pb-0 mb-0"
                              style={{
                                flexGrow: 1,
                              }}
                            >
                              STEPS TO AUTO ACCESS AND GET YOUR EPF DETAILS
                            </h3>
                            <br />
                            <div>
                              <div
                                className="d-flex gap-2"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "1.2rem",
                                }}
                              >
                                <span>&#9642;</span>{" "}
                                <span>
                                  Please make sure you have your UAN [ Universal
                                  Account Number ] handy. You can find this
                                  number in your salary slip.
                                </span>
                              </div>
                              <div
                                className="d-flex gap-2"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "1.2rem",
                                }}
                              >
                                <span>&#9642;</span>{" "}
                                <span>
                                  Once yoy click Fetch Details button you will
                                  be shown popup where you have to enter you
                                  UAN.
                                </span>
                              </div>
                              <div
                                className="d-flex gap-2"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "1.2rem",
                                }}
                              >
                                <span>&#9642;</span>{" "}
                                <span>
                                  Once you submit, you will be asked to provide
                                  an OTP.
                                </span>
                              </div>
                              <div
                                className="d-flex gap-2"
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "1.2rem",
                                }}
                              >
                                <span>&#9642;</span>{" "}
                                <span>
                                  Once successful OTP verification, your EPF
                                  details will be fetched.
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {/* {showUANModal && ( */}
                        <>
                          <LinkEPF setShowOTPModal={setShowOTPModal}
                            customStyles={customStyles}
                            modalType={modalType}
                            setModalType={setModalType}
                            showUANModal={showUANModal}
                            setShowUANModal={setShowUANModal}
                            session={session} />
                          {/* <Modal
                            classNames={{
                              modal: "Modalpopup2",
                            }}
                            open={showUANModal}
                            showCloseIcon={false}
                            onClose={() => () => {}}
                            center
                            animationDuration={0}
                          >
                            <div
                              className=""
                              style={{ padding: "0 !important" }}
                            >
                              <div className="Modalpopup2_heading">
                                <div className="col-11 d-flex justify-content-center">
                                  {" "}
                                  <span>Link Your EPF Account</span>
                                </div>
                                <div
                                  className="col-1 text-light cursor-pointer"
                                  onClick={() => setShowUANModal(false)}
                                >
                                  <i class="fa-regular fa-circle-xmark"></i>
                                </div>
                              </div>

                              <div>
                                <br />

                                <div className="my-md-4">
                                  <div className="px-5">
                                    <span className="lbl-newbond">
                                      Enter UAN *
                                    </span>
                                    <br />
                                    <input
                                      className={`w-100 fntoo-textbox-react inputPlaceholder`}
                                      type="text"
                                      name="uan"
                                      value={formData.uan}
                                      onChange={(e) => onInputChange(e, true)}
                                    />
                                    {simpleValidator.current.message(
                                      "uan",
                                      formData.uan,
                                      "required"
                                    )}
                                  </div>
                                </div>
                                <br />
                                <div className="ButtonBx">
                                  <button
                                    className="ReNew"
                                    onClick={() => {
                                      simpleValidator.current.showMessages();
                                      forceUpdate();

                                      if (simpleValidator.current.allValid()) {
                                        setShowOTPModal(true);
                                        setShowUANModal(false);
                                        setIsManual(false);
                                      }
                                    }}
                                  >
                                    Send OTP
                                  </button>
                                </div>
                                <br />
                              </div>
                            </div>
                          </Modal> */}
                          {/* -------------------------------------------------------------------------------------- */}
                          {/* <Modal
                            classNames={{
                              modal: "Modalpopup",
                            }}
                            open={showOTPModal}
                            showCloseIcon={true}
                            onClose={() => setShowOTPModal(false)}
                            center
                            animationDuration={0}
                          >
                            <div
                              className=""
                              style={{ padding: "0 !important" }}
                            >
                              <h2 className="HeaderText text-center">
                                Please Enter OTP
                              </h2>

                              <div>
                                <br />
                                <p>
                                  We have sent you and OTP on your mobile number
                                  +91 916*****20
                                </p>
                                <div className="my-md-4">
                                  <div className="px-5">
                                    <OTPInput
                                      value={otpInput}
                                      onChange={setOtpInput}
                                      autoFocus
                                      className="link-holdings-otp w-100"
                                      style={{
                                        border: "none",
                                      }}
                                      OTPLength={6}
                                      otpType="number"
                                      disabled={false}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="ButtonBx">
                                  <button
                                    className="ReNew"
                                    onClick={() => {
                                      setShowEPFForm(true);
                                      setShowOTPModal(false);
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                                <br />
                              </div>
                            </div>
                          </Modal> */}
                        </>
                      {/* )} */}
                    </>
                  )}

                  {console.log(
                    "con: ",
                    formData.typeOfAsset === "EPF",
                    showEPFForm
                  )}

                  {formData.typeOfAsset === "EPF" && showEPFForm && (
                    // {showEPFForm && (
                    <>
                      <div className="col-md-12 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Current EPF balance *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Current EPF balance"
                              className={`w-100 fntoo-textbox-react inputPlaceholder Rupee-icon`}
                              type="Number"
                              maxLength={9}
                              name="currentEPFBalance"
                              value={formData.currentEPFBalance}
                              onChange={(e) => onInputChange(e, true)}
                              onInput={(e) => {
                                if (e.target.value.length > 10) {
                                  e.target.value = e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.');
                                }
                              }}
                            />
                            {simpleValidator.current.message(
                              "currentEPFBalance",
                              formData.currentEPFBalance,
                              "required|numeric"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Employees Monthly Contribution *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Employees Monthly Contribution"
                              className={`w-100 fntoo-textbox-react inputPlaceholder Rupee-icon`}
                              type="Number"
                              maxLength={9}
                              value={formData.employeesMonthlyContribution}
                              name="employeesMonthlyContribution"
                              onChange={(e) => onInputChange(e, true)}
                              onInput={(e) => {
                                if (e.target.value.length > 10) {
                                  e.target.value = e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.');
                                }
                              }}
                            />
                            {simpleValidator.current.message(
                              "employeesMonthlyContribution",
                              formData.employeesMonthlyContribution,
                              "required|numeric"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Employer Monthly Contribution *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Employer Monthly Contribution"
                              className={` w-100 fntoo-textbox-react inputPlaceholder Rupee-icon`}
                              type="Number"
                              maxLength={9}
                              value={formData.employerMonthlyContribution}
                              name="employerMonthlyContribution"
                              onChange={(e) => onInputChange(e, true)}
                              onInput={(e) => {
                                if (e.target.value.length > 10) {
                                  e.target.value = e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.');
                                }
                              }}
                            />
                            {simpleValidator.current.message(
                              "employerMonthlyContribution",
                              formData.employerMonthlyContribution,
                              "required|numeric"
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Retirement Age *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Retirement Age"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="Number"
                              maxLength={3}
                              value={formData.retirementAge}
                              name="retirementAge"
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message(
                              "retirementAge",
                              formData.retirementAge,
                              "required|numeric"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Life Expentancy *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Life Expentancy"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="Number"
                              maxLength={3}
                              value={formData.lifeExpentancy}
                              name="lifeExpentancy"
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message(
                              "lifeExpentancy",
                              formData.lifeExpentancy,
                              "required|numeric"
                            )}
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Rate Of Return (%) *
                            </span>
                            <br />
                            <FormRangeSlider
                              x={rateOfReturn}
                              min={0}
                              max={20}
                              step={0.05}
                              onChange={(x) => {
                                setRateOfReturn(
                                  Math.round(
                                    (parseFloat(x) + Number.EPSILON) * 100
                                  ) / 100
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Growth Rate In EPF Contribution (%) *
                            </span>
                            <br />
                            <FormRangeSlider
                              x={growthRate}
                              min={0}
                              max={50}
                              // step={0.05}
                              onChange={(x) => {
                                // setGrowthRate(
                                //   Math.round(
                                //     (parseFloat(x) + Number.EPSILON) * 100
                                //   ) / 100
                                // );
                                if (x != 0) {
                                  setGrowthRate(x);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                    // )}
                  )}

                  <>
                    {formData.typeOfAsset != "EPF" &&
                      formData.typeOfAsset != "Recurring Deposit" && (
                        <>
                          <div className="col-md-12 col-12">
                            <div className="my-md-4">
                              <div className="">
                                <span className="lbl-newbond">
                                  Select Bank/Institute Name *
                                </span>
                                <br />
                                <Select
                                  className="fnto-dropdown-react"
                                  styles={customStyles}
                                  classNamePrefix=" sortSelect"
                                  isSearchable={true}
                                  options={allBank.map((v) => ({
                                    label: v.bank_name,
                                    value: v.bank_name,
                                  }))}
                                  value={
                                    allBank
                                      .filter(
                                        (v) =>
                                          v.bank_name ==
                                          formData.bankInstituteName
                                      )
                                      .map((v) => ({
                                        label: v.bank_name,
                                        value: v.bank_name,
                                      }))[0] ?? null
                                  }
                                  // name={selectedOption}
                                  name="bankInstituteName"
                                  onChange={(e) =>
                                    onDateAndSelectInputChange(
                                      "bankInstituteName",
                                      e.label
                                    )
                                  }
                                />
                                {simpleValidator.current.message(
                                  "bankInstituteName",
                                  formData.bankInstituteName,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-12 col-12">
                                                    <div className="my-md-4">
                                                        <div className="">
                                                            <span className="lbl-newbond">
                                                                Bank/Institute Name *
                                                            </span>
                                                            <br />
                                                            <input
                                                                placeholder="Enter Bank/Institute Name"
                                                                className={` w-100 fntoo-textbox-react Rupee-icon`}
                                                                type="text"
                                                                value={formData.bankInstituteOtherName}
                                                                name='bankInstituteOtherName'
                                                                onChange={onInputChange}
                                                            />
                                                            {simpleValidator.current.message('bankInstituteOtherName', formData.bankInstituteOtherName, 'required')}
                                                        </div>
                                                    </div>
                                                </div> */}
                        </>
                      )}

                    {formData.typeOfAsset != "Gratuity" &&
                      formData.typeOfAsset != "EPF" && (
                        <>
                          {formData.typeOfAsset != "Recurring Deposit" ? (
                            <>
                              <div className="col-md-6 col-12">
                                <div className="my-md-4">
                                  <div className="">
                                    <span className="lbl-newbond">
                                      Purchase Date *
                                    </span>
                                    <br />
                                    <div className="bonds-datepicker">
                                      <FintooDatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                          formData.purchaseDate === ""
                                            ? ""
                                            : formData.purchaseDate
                                          // : formData.purchaseDate
                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        name="purchaseDate"
                                        customClass="datePickerDMF"
                                        maxDate={new Date()}
                                        onChange={(e) =>
                                          onDateAndSelectInputChange(
                                            "purchaseDate",
                                            formatDatefun(e)
                                          )
                                        }
                                        onKeyDown={(e) => {
                                          e.preventDefault();
                                        }}
                                      />
                                    </div>
                                    {simpleValidator.current.message(
                                      "purchaseDate",
                                      formData.purchaseDate,
                                      "required"
                                    )}
                                  </div>
                                </div>
                              </div>
                              {console.log(
                                "purchaseAmount",
                                formData.purchaseAmount
                              )}
                              <div className="col-md-6 col-12">
                                <div className="my-md-4">
                                  <div className="">
                                    <span className="lbl-newbond">
                                      Purchase Amount *
                                    </span>
                                    <br />
                                    <input
                                      placeholder="Purchase Amount"
                                      className={` w-100 fntoo-textbox-react inputPlaceholder Rupee-icon`}
                                      type="text"
                                      maxLength={9}
                                      value={formData.purchaseAmount}
                                      name="purchaseAmount"
                                      onChange={(e) => onInputChange(e, true)}
                                    />
                                    {simpleValidator.current.message(
                                      "purchaseAmount",
                                      formData.purchaseAmount,
                                      "required|numeric"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-md-6 col-12">
                                <div className="my-md-4">
                                  <div className="">
                                    <span className="lbl-newbond">
                                      Account Balance As On Today *
                                    </span>
                                    <br />
                                    <input
                                      placeholder="Enter Account Balance As On Today"
                                      className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                                      type="text"
                                      maxLength={9}
                                      value={formData.accountBalanceAsOnToday}
                                      name="accountBalanceAsOnToday"
                                      onChange={(e) => onInputChange(e, true)}
                                    />
                                    {simpleValidator.current.message(
                                      "accountBalanceAsOnToday",
                                      formData.accountBalanceAsOnToday,
                                      "required|numeric"
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="my-md-4">
                                  <div className="">
                                    <span className="lbl-newbond">
                                      Installment Amount *
                                    </span>
                                    <br />
                                    <input
                                      placeholder="Enter Installment Amount"
                                      className={` w-100 fntoo-textbox-react inputPlaceholder Rupee-icon`}
                                      type="text"
                                      maxLength={9}
                                      value={formData.installmentAmount}
                                      name="installmentAmount"
                                      onChange={(e) => onInputChange(e, true)}
                                    />
                                    {simpleValidator.current.message(
                                      "installmentAmount",
                                      formData.installmentAmount,
                                      "required|numeric"
                                    )}
                                    <>
                                      <div className="fnto-bonds-tags">
                                        <div
                                          className={
                                            activeIndex2 === 1 ? "active" : ""
                                          }
                                          onClick={() =>
                                            handleClick2(1, "Monthly")
                                          }
                                        >
                                          <p>Monthly</p>
                                        </div>
                                        <div
                                          className={
                                            activeIndex2 === 2 ? "active" : ""
                                          }
                                          onClick={() =>
                                            handleClick2(2, "Quarterly")
                                          }
                                        >
                                          <p>Quarterly</p>
                                        </div>
                                        <div
                                          className={
                                            activeIndex2 === 3 ? "active" : ""
                                          }
                                          onClick={() =>
                                            handleClick2(3, "Half-Yearly")
                                          }
                                        >
                                          <p>Half-Yearly</p>
                                        </div>
                                        <div
                                          className={
                                            activeIndex2 === 4 ? "active" : ""
                                          }
                                          onClick={() =>
                                            handleClick2(4, "Yearly")
                                          }
                                        >
                                          <p>Yearly</p>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>

                              {formData.asset_sub_category_id == 120 &&
                                activeIndex2 === 4 && (
                                  <>
                                    <div className="col-md-6 col-12">
                                      <div className="my-md-4">
                                        <div className="">
                                          <span className="lbl-newbond">
                                            Installment for the year is Paid or
                                            not*
                                          </span>
                                          <br />
                                          <div className="bonds-datepicker">
                                            <div className="insurance-switch-container">
                                              <span>No&nbsp;&nbsp;</span>{" "}
                                              <FormSwitch
                                                switchValue={isInstallmentPaid}
                                                onSwitchToggle={() =>
                                                  setIsInstallmentPaid(
                                                    (previous) => !previous
                                                  )
                                                }
                                              />{" "}
                                              <span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;Yes
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </>
                          )}

                          {(formData.asset_sub_category_id == 75 ||
                            formData.asset_sub_category_id == 79 ||
                            formData.asset_sub_category_id == 80 ||
                            formData.asset_sub_category_id == 87) && (
                              <>
                                <div className="col-md-6 col-12">
                                  <div className="my-md-4">
                                    <div className="">
                                      <span className="lbl-newbond">
                                        Interest Rate (%) *
                                      </span>
                                      <br />
                                      <br />
                                      <FormRangeSlider
                                        x={interestRate}
                                        min={0}
                                        max={20}
                                        step={0.05}
                                        onChange={(x) => {
                                          setInterestRate(
                                            Math.round(
                                              (parseFloat(x) + Number.EPSILON) *
                                              100
                                            ) / 100
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                          {formData.asset_sub_category_id == 120 && (
                            <>
                              <div className="col-md-6 col-12">
                                <div className="my-md-4">
                                  <div className="">
                                    <span className="lbl-newbond">
                                      Rate Of Return (%) *
                                    </span>
                                    <br />
                                    <br />
                                    <FormRangeSlider
                                      // x={interestRate}
                                      x={rateOfReturn == 0 ? 0 : rateOfReturn}
                                      min={0}
                                      max={20}
                                      step={0.05}
                                      onChange={(x) => {
                                        setRateOfReturn(
                                          Math.round(
                                            (parseFloat(x) + Number.EPSILON) *
                                            100
                                          ) / 100
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {formData.typeOfAsset != "Recurring Deposit" && (
                            <div className="col-md-6 col-12">
                              <div className="my-md-4">
                                <div className="">
                                  <span className="lbl-newbond">
                                    Payout Type *
                                  </span>
                                  <br />
                                  <Select
                                    className="fnto-dropdown-react"
                                    classNamePrefix=" sortSelect"
                                    isSearchable={false}
                                    styles={customStyles}
                                    options={Purchaseoptions}
                                    // value={getGoldTypeData(
                                    //   options_payout,
                                    //   formData.payoutType
                                    // )}
                                    value={Purchaseoptions.filter(
                                      (v) => v.value == formData.payoutType
                                    )}
                                    // value={formData.payoutType}
                                    name="payoutType"
                                    onChange={(e) => {
                                      onDateAndSelectInputChange(
                                        "payoutType",
                                        e.value
                                      );
                                      // setActiveIndex(1);
                                    }}
                                  />
                                  {simpleValidator.current.message(
                                    "payoutType",
                                    formData.payoutType,
                                    "required"
                                  )}

                                  {console.log(
                                    "payoutType",
                                    typeof formData.payoutType
                                  )}
                                  {formData.payoutType == "2" && (
                                    <>
                                      <div className="fnto-bonds-tags">
                                        <div
                                          className={
                                            activeIndex2 === 1 ? "active" : ""
                                          }
                                          onClick={() =>
                                            handleClick2(1, "Monthly")
                                          }
                                        >
                                          <p>Monthly</p>
                                        </div>
                                        <div
                                          className={
                                            activeIndex2 === 2 ? "active" : ""
                                          }
                                          onClick={() =>
                                            handleClick2(2, "Quarterly")
                                          }
                                        >
                                          <p>Quarterly</p>
                                        </div>
                                        <div
                                          className={
                                            activeIndex2 === 3 ? "active" : ""
                                          }
                                          onClick={() =>
                                            handleClick2(3, "Half-Yearly")
                                          }
                                        >
                                          <p>Half-Yearly</p>
                                        </div>
                                        <div
                                          className={
                                            activeIndex2 === 4 ? "active" : ""
                                          }
                                          onClick={() =>
                                            handleClick2(4, "Yearly")
                                          }
                                        >
                                          <p>Yearly</p>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                    {showEPFInputs() && (
                      <>
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div className="">
                              <span className="lbl-newbond">
                                Maturity Date *
                              </span>
                              <br />
                              <div className="bonds-datepicker">
                                <FintooDatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={
                                    formData.maturityDate === ""
                                      ? ""
                                      : formData.maturityDate
                                  }
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  name="maturityDate"
                                  customClass="datePickerDMF"
                                  // minDate={moment(formData.purchaseDate).toDate()}
                                  // minDate={new Date(new Date(formData.purchaseDate).getFullYear(),
                                  //   new Date(formData.purchaseDate).getMonth() + 1,
                                  //   1
                                  //   )}
                                  minDate={new Date()}
                                  // refDate.setMonth(refDate.getMonth() + 6)}
                                  disabled={
                                    formData.asset_sub_category_id != 120 &&
                                      formData.asset_sub_category_id != 117
                                      ? formData.purchaseDate === ""
                                      : null
                                  }
                                  onChange={(e) =>
                                    onDateAndSelectInputChange(
                                      "maturityDate",
                                      formatDatefun(e)
                                    )
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>
                              {simpleValidator.current.message(
                                "maturityDate",
                                formData.maturityDate,
                                "required"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div className="">
                              <span className="lbl-newbond">
                                Maturity Amount *
                              </span>
                              <br />
                              <input
                                placeholder="Maturity Amount"
                                className={` w-100 fntoo-textbox-react inputPlaceholder Rupee-icon disabled`}
                                type="text"
                                value={Math.ceil(
                                  maturityCalculation != ""
                                    ? maturityCalculation
                                    : ""
                                )}
                                name="maturityAmount"
                                onChange={(e) => onInputChange}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {formData.typeOfAsset == "Gratuity" &&
                      formData.typeOfAsset != "EPF" && (
                        <>
                          <div className="col-md-6 col-12">
                            <div className="my-md-4">
                              <div className="">
                                <span className="lbl-newbond">
                                  Monthly Salary (Basic + DA) *
                                </span>
                                <br />
                                <input
                                  placeholder="Monthly Salary"
                                  className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                                  type="text"
                                  maxLength={9}
                                  value={formData.monthlySalary}
                                  name="monthlySalary"
                                  onChange={(e) => onInputChange(e, true)}
                                />
                                {simpleValidator.current.message(
                                  "monthlySalary",
                                  formData.monthlySalary,
                                  "required|numeric"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="my-md-4">
                              <div className="">
                                <span className="lbl-newbond">
                                  No. Of Years Of Service (Min 5) *
                                </span>
                                <br />
                                <FormRangeSlider
                                  x={yearsOfService}
                                  min={5}
                                  max={50}
                                  step={1}
                                  onChange={(x) => {
                                    setYearsOfService(x);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="my-md-4">
                              <div className="">
                                <span className="lbl-newbond">
                                  Gratuity Amount *
                                </span>
                                <br />
                                <input
                                  placeholder="Gratuity Amount"
                                  className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                                  type="text"
                                  value={formData.gratuityAmount}
                                  name="gratuityAmount"
                                  onChange={(e) => onInputChange(e, true)}
                                />
                                {simpleValidator.current.message(
                                  "gratuityAmount",
                                  formData.gratuityAmount,
                                  "required|numeric"
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                </div>

                {/* {showSubmit() && (
                  <div className="my-md-4">
                    <button
                      type="submit"
                      className="d-block m-auto btn btn-primary"
                      onClick={() => validateForm()}
                    >
                      Save
                    </button>
                  </div>
                )} */}

                {showSubmit() && addForm && (
                  <div className="my-md-4">
                    <button
                      type="submit"
                      className="d-block m-auto btn btn-primary"
                      onClick={() => validateForm()}
                    >
                      Save
                    </button>
                  </div>
                )}

                {showSubmit() && updateForm && (
                  <div>
                    {/* <button
                          onClick={(e) => handleDebtCancel(e)}
                          className="default-btn gradient-btn save-btn"
                        >
                          Cancel
                      </button> */}
                    {/* <button
                          onClick={(e) => handleDebtUpdate(e)}
                          className="default-btn gradient-btn save-btn"
                                >
                                  Update
                      </button> */}
                    <button
                      type="submit"
                      className="d-block m-auto btn btn-primary"
                      onClick={() => updateFdBonds()}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewFdBondsFormView;
