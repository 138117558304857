import React, { useRef, useState } from "react";
import Styles from "./Style.module.css";
import "./style.css";
import { FaStar } from "react-icons/fa";
import Carousel from "react-slick";
import { useSelector } from "react-redux";
import {
  BASE_API_URL,
  CHECK_SESSION,
  CALENDLY_TOKEN,
  ADVISORY_EXPERTFLOW_CALLBACK,
  ADVISORY_CALLBACKSERVICES_API_URL,
  DMF_SENDMAIL_API_URL,
  DMF_SENDSMS_API_URL,
  ADVISORY_GET_RM_DETAILS_API_URL,
} from "../../../constants";
import {
  apiCall,
  getItemLocal,
  getParentUserId,
  fetchData,
  fetchEncryptData,
} from "../../../common_utilities";
import FintooLoader from "../../FintooLoader";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
function ExpertRmDetails() {
  const selectedRM = useSelector((state) => state.selectedRM);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [show, setShow] = useState(false);
  const [calendlydata, setCalendlyData] = useState({
    name: "",
    email: "",
    mobile: "",
    country_code: "+91",
  });
  const [formData, setFormData] = useState({
    fullname: "",
    mobile: "",
    mailid: "",
    servicename: "financial-plan",
    tags: "fin_expert_calendly",
    utm_source: 26,
    service: "100",
    rm_id: 0,
    incomeslab: "",
    comment: "",
  });
  const [callbackDatetime, setCallbackDatetime] = useState("");
  const rmDetailRef = useRef();
  const breakPoints = [
    { width: 1, itemsToShow: 8 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 2 },
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    // centerMode: true,
    slidesToScroll: 1,
  };

  React.useEffect(() => {
    getSessionData();
    function handleClickOutside(event) {
      if (rmDetailRef.current && !rmDetailRef.current.contains(event.target)) {
        closePopup();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closePopup = () => {
    document.querySelector(".outerRmBox-udim4").classList.remove("active");
    document.querySelector(".outerRmBox-udim4").classList.remove("mobile");
    document.body.classList.remove("overflow-hidden");
  };

  const getSessionData = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      setSessionData(session_data["data"]);
      if (session_data["data"]["user_details"]) {
        let calendly_detail = session_data["data"]["user_details"];
        setCalendlyData({
          name: calendly_detail["name"],
          email: calendly_detail["email"],
          mobile: calendly_detail["mobile"],
          country_code: calendly_detail["country_code"],
        });
      } else {
        let calendly_detail = session_data["data"];
        if (calendly_detail != "") {
          setCalendlyData({
            name: calendly_detail["first_name"],
            email: calendly_detail["email"],
            mobile: calendly_detail["mobile"],
            country_code: calendly_detail["country_code"],
          });
        } else {
          var calendly_data = localStorage.getItem("calendlydata");
          if (calendly_data) {
            setCalendlyData(JSON.parse(calendly_data));
          } else {
            setCalendlyData({
              name: "",
              email: "",
              mobile: "",
              country_code: "+91",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const isCalendlyEvent = function (e) {
    return (
      e.origin === "https://calendly.com" &&
      e.data.event &&
      e.data.event.indexOf("calendly.") === 0
    );
  };

  let callback_datetime = "";

  window.addEventListener("message", async function (e) {
    if (isCalendlyEvent(e)) {
      if (e.data.payload.event) {
        try {
          const [responseEvent, responseInvitee] = await Promise.all([
            fetchData({
              url: e.data.payload.event.uri,
              method: "GET",
              headers: {
                Authorization: CALENDLY_TOKEN,
              },
            }),
            fetchData({
              url: e.data.payload.invitee.uri,
              method: "GET",
              headers: {
                Authorization: CALENDLY_TOKEN,
              },
            }),
          ]);
          const date_time = new Date(responseEvent.resource.start_time);
          callback_datetime = date_time.toLocaleString([], {
            hour12: true,
          });
          setCallbackDatetime(callback_datetime);
          localStorage.removeItem("calendlydata");
          let mobile = "";
          let comment = "";
          let income_slab = 7;
          const answers = responseInvitee.resource.questions_and_answers;
          let form_data = { ...formData };
          form_data = {
            ...form_data,
            fullname: responseInvitee.resource.name,
            mailid: responseInvitee.resource.email,
            rm_id: selectedRM.id ? selectedRM.id : 0,
          };

          answers.map((answer) => {
            if (answer.position == 2) {
              mobile = answer.answer.split("+91 ")[1];
              form_data = {
                ...form_data,
                mobile: mobile,
              };
            }
            if (answer.position == 1) {
              income_slab = answer.answer === "More Than 1 Cr" ? 6 : 5;
              form_data = {
                ...form_data,
                incomeslab: income_slab,
              };
            }
            if (answer.position == 0) {
              comment = answer.answer;
              form_data = {
                ...form_data,
                comment: comment,
              };
            }
          });
          $("#calendly").css("display", "none");
          $("#calendly1").css("display", "none");
          $(".ThanksSection").css("display", "flex");
          setFormData({
            ...formData,
            ...form_data,
          });

          // Handle the rest of your logic
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  });

  const thanksContinue = async () => {
    
    setIsLoading(!isLoading);
    let email = formData.mailid;
    let data = {};
    data = {
      ...data,
      tagval: "minty_financial_planning",
      servicename: "financial-planning",
      plan_name: "Financial Plan",
      tags: "fin_web_FP_exp",
      rm_id: selectedRM.id ? selectedRM.id : 0,
    };
    let d = { user_id: getParentUserId(), sky: getItemLocal("sky") };
    let session_data = await apiCall(CHECK_SESSION, d, true, false);
    setSessionData(session_data["data"]);
    var session_Data = session_data["data"];
    if (session_Data.user_details != undefined) {
      let data = {
        rm_id: selectedRM.id,
        plan_id: "31",
        user_id: getParentUserId(),
      };
      let config = {
        method: "POST",
        url: ADVISORY_EXPERTFLOW_CALLBACK,
        data: data,
      };
      var response = await fetchData(config);
      if (response["error_code"] == "100") {
        let config2 = {
          method: "POST",
          url: BASE_API_URL + "restapi/callback/",
          data: formData,
        };
        let response2 = await fetchEncryptData(config2);
        let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
        let session_data = await apiCall(CHECK_SESSION, data, true, false);
        setSessionData(session_data["data"]);
        var updatedSessionData = session_data["data"];
        setIsLoading(!isLoading);
        if (updatedSessionData.fp_lifecycle_status == "0") {
          sessionStorage.setItem("ExpertPlanExistToast", "0");
          window.location.href = "/web/userflow/expert-nda/";
        } else {
          if (response2["data"] == "0") {
            sessionStorage.setItem("ExpertPlanExistToast", "1");
          }
          window.location.href = "/web/datagathering/about-you";
        }
      }
    } else {
      let financialFormData = formData;
      if (sessionData.user_details == undefined) {
        financialFormData["skip_mail"] = "1";
      }
      financialFormData["email"] = email;
      financialFormData = {
        ...financialFormData,
        ...data,
      };
      console.log(financialFormData);
      let config = {
        method: "POST",
        url: ADVISORY_CALLBACKSERVICES_API_URL,
        data: financialFormData,
      };
      let response = await fetchEncryptData(config);
      console.log(response);
      if (response.error_code == "0") {
        if (sessionData.user_details == undefined) {
          let configMailtoUser = {
            method: "POST",
            url: DMF_SENDMAIL_API_URL,
            data: {
              userdata: {
                to: financialFormData.email,
              },
              subject:
                "Thank You For Requesting For A Callback. We Will Call You Shortly!",
              template: "welcome_expert_services.html",
              contextvar: {
                rm_id: financialFormData.rm_id,
                plan_id: "31",
                fullname: financialFormData.fullname,
              },
            },
          };
          await fetchEncryptData(configMailtoUser);
          let configMailtoRM = {
            method: "POST",
            url: DMF_SENDMAIL_API_URL,
            data: {
              userdata: {
                to: selectedRM["username"],
              },
              subject: "Callback",
              template: "callback_mail_to_rm_expert.html",
              contextvar: {
                fullname: selectedRM["emp_name"],
                clientname: financialFormData.fullname,
                clientmobile: financialFormData.mobile,
                clientemail: financialFormData.email,
                callbackdatetime: callbackDatetime,
              },
            },
          };
          await fetchEncryptData(configMailtoRM);
          let content = encodeURIComponent(
            "Congratulations " +
              selectedRM["emp_name"] +
              "!,\r\nYou are selected as a Wealth Manager by our new client. Sharing the client's details for your reference.\r\nName: " +
              financialFormData.fullname +
              "\r\nContact No.: " +
              financialFormData.mobile +
              "\r\nTeam Fintoo"
          );
          let whatsapptext =
            "Congratulations " +
            selectedRM["emp_name"] +
            "! You are selected as a Wealth Manager by our new client. Sharing the client's details for your reference. Email: " +
            financialFormData.email +
            ". Contact No: " +
            financialFormData.mobile +
            ".";
          let smsdata = {
            msg: content,
            mobile: selectedRM["emp_mobile"],
            whatsapptext: whatsapptext,
            sms_api_id: "FintooWMselectionbyclient",
          };
          let smsConfig = {
            method: "POST",
            url: DMF_SENDSMS_API_URL,
            data: smsdata,
          };
          let smsResponse = await fetchEncryptData(smsConfig);
          if (smsResponse) {
            setIsLoading(!isLoading);
            toastr.options.positionClass = "toast-bottom-left";
            toastr.success("Callback request sent successfully");
            setTimeout(function () {
              window.location.href = "/";
            }, 1000);
          }
        } else {
          setIsLoading(!isLoading);
          setTimeout(function () {
            window.location.href = "/";
          }, 1000);
        }
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    }
  };
  console.log("rm---99999----", selectedRM);
  return (
    <div className={`${Styles.outerRmBox} outerRmBox-udim4`}>
      <FintooLoader isLoading={isLoading} />
      <div
        className={`${Styles.RMPersonalData} RM-Data-ildfX`}
        ref={rmDetailRef}
      >
        <div className={`d-block d-md-none ${Styles.imgclose}`}>
          <div
            onClick={() => {
              closePopup();
            }}
          >
            <img src={require("./images/cancel_1.png")} />
          </div>
        </div>
        {Object.keys(selectedRM).length > 0 && (
          <>
            {show ? (
              <div
                id="calendly"
                className={`${Styles.calendly}`}
                // style={{ display: "none" }}
              >
                <div className="text-center">
                  <div>
                    <p className={`${Styles.BooKLabel}`}>
                      Book Appointment with Expert
                    </p>
                  </div>
                  <div>
                    <p className={`${Styles.bookIntro}`}>
                      Book an introductory call with our financial Advisor to
                      know more about our offerings and advice.
                    </p>
                  </div>
                </div>
                <div
                  className="calendly-inline-widget"
                  data-url="https://calendly.com/fintoo/15-minutes-consultation-expert?embed_domain=minty.co.in&embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1"
                  style={{ position: "relative", height: 565 }}
                  data-processed="true"
                >
                  <div className="calendly-spinner">
                    <div className="calendly-bounce1" />
                    <div className="calendly-bounce2" />
                    <div className="calendly-bounce3" />
                  </div>
                  <iframe
                    src={`https://calendly.com/fintoo/15-minutes-consultation-expert?embed_domain=minty.co.in&embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1&name=${calendlydata.name}&email=${calendlydata.email}&a3=${calendlydata.country_code}${calendlydata.mobile}&a4=${selectedRM.emp_name}`}
                    width="100%"
                    height="100%"
                    frameBorder={0}
                  />
                </div>
              </div>
            ) : (
              <>
                <div id="RMDATA1" className={`${Styles.RmSpace} `}>
                  <div className="RMProfile text-center">
                    <div className={`${Styles.RmBox}`}>
                      {selectedRM.imagepath == "" ? (
                        <img
                          src={
                            "https://static.fintoo.in/static/userflow/img/profile-picture.svg"
                          }
                        />
                      ) : (
                        <img
                          src={
                            "https://static.fintoo.in/static/" +
                            selectedRM.imagepath
                          }
                        />
                      )}
                    </div>
                    <div className={`mt-2 ${Styles.RMName}`}>
                      {selectedRM.emp_name}
                    </div>
                    <div className={`${Styles.RmQualification}`}>
                      <span className="ng-binding">
                        {selectedRM.emp_position}
                      </span>{" "}
                      <span style={{ fontWeight: 100 }}>|</span>{" "}
                      <span className={`${Styles.Education}`}>
                        {selectedRM.emp_certification}
                      </span>
                    </div>
                  </div>
                  <div className={`d-flex mt-2 ${Styles.Rmcareer}`}>
                    <div className={`${Styles.BorderRight}`}>
                      <div className={`${Styles.textLabel}`}>Experience</div>
                      <div className={`${Styles.ValueLabel}`}>
                        {selectedRM.emp_experience}{" "}
                        <span style={{}}>Years</span>
                      </div>
                    </div>
                    <div className={`ps-4 pe-4 ${Styles.BorderRight}`}>
                      <div className={`${Styles.textLabel}`}>Location</div>
                      <div className={`${Styles.ValueLabel}`}>
                        {selectedRM.emp_location}
                      </div>
                    </div>
                    <div className="ps-3">
                      <div className={`${Styles.textLabel}`}>Rating</div>
                      <div className={`${Styles.rating}`}>
                        {/* ngIf: expert_details.rating == 5 */}
                        <div>
                          <span
                            className={`fa fa-star`}
                            style={{
                              color: `${
                                selectedRM.rating - 1 < 0 ? "gray" : "orange"
                              }`,
                            }}
                          />
                          <span
                            className={`fa fa-star`}
                            style={{
                              color: `${
                                selectedRM.rating - 2 < 0 ? "gray" : "orange"
                              }`,
                            }}
                          />
                          <span
                            className={`fa fa-star`}
                            style={{
                              color: `${
                                selectedRM.rating - 3 < 0 ? "gray" : "orange"
                              }`,
                            }}
                          />
                          <span
                            className={`fa fa-star`}
                            style={{
                              color: `${
                                selectedRM.rating - 4 < 0 ? "gray" : "orange"
                              }`,
                            }}
                          />
                          <span
                            className={`fa fa-star`}
                            style={{
                              color: `${
                                selectedRM.rating - 5 < 0 ? "gray" : "orange"
                              }`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`text-center ${Styles.aboutRm}`}
                    style={{ borderBottom: "0.3px solid rgb(227, 227, 227)" }}
                  >
                    <p className={`${Styles.labelText}`}>About Me</p>
                    <p className={`${Styles.Textinfo}`}>
                      {selectedRM.emp_description}
                    </p>
                  </div>

                  <div className={`${Styles.FeedBackCustomer}`}>
                    <p className={`text-center ${Styles.labelText}`}>
                      Customer Feedback
                    </p>
                    {selectedRM.reviews != null && (
                      <Carousel {...settings}>
                        {JSON.parse(selectedRM.reviews)
                          .slice(0, 3)
                          .map((x) => (
                            <div
                              key={`rv-${Math.random()}`}
                              className={`${Styles.item}`}
                            >
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <div>
                                    <img
                                      width={40}
                                      src="https://www.fintoo.in/static/userflow/img/profile-picture.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className={`ms-1 ${Styles.customerDetails}`}
                                  >
                                    <div className={`${Styles.customerName}`}>
                                      {x.user_name}
                                    </div>
                                    <div className={`${Styles.customerrating}`}>
                                      <div>
                                        {Array.from({ length: x.rating }).map(
                                          () => (
                                            <span
                                              className={`${Styles.checked}`}
                                            >
                                              <FaStar />
                                            </span>
                                          )
                                        )}
                                        {Array.from({
                                          length: 5 - x.rating,
                                        }).map(() => (
                                          <span
                                            className={`${Styles.Uchecked}`}
                                          >
                                            <FaStar />
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <p className={`mt-2 ${Styles.FeedbackMsg}`}>
                                  {x.review}
                                </p>
                              </div>
                            </div>
                          ))}
                      </Carousel>
                    )}
                    {selectedRM.reviews == null && (
                      <div className={` mt-3 ${Styles.reviewslider}`}>
                        <div>
                          <p className={`mt-2 ${Styles.Textinfo}`}>
                            No Review Available
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={`text-center ${Styles.bookAppointment}`}>
                    <button
                      onClick={() => setShow(!show)}
                      
                    >
                      Book Appointment
                    </button>
                  </div>
                </div>
              </>
            )}
            <div
              className={`${Styles.ThanksSection} ThanksSection`}
              style={{ display: "none" }}
            >
              <div className="text-center mt-4">
                <div>
                  <img
                    style={{ width: 40 }}
                    src="https://static.fintoo.in/static/userflow/img/icons/check.png"
                    alt=""
                  />
                </div>
                <div className={`${Styles.thankyoumsg}`}>
                  Thank You For Choosing Fintoo!
                </div>
                <div className={`mt-3 ${Styles.ThanksParagraphs}`}>
                  <div>
                    We have received your request, and we are extremely
                    delighted that out of all the available options, you trusted
                    us to assist you at every step on your journey towards
                    financial planning and achieving your goals.
                  </div>
                  <div className="mt-3">
                    Now, let’s head over to your inbox , find the mail with the
                    step-by-step instructions to complete your registration
                    process, set up your account, and ultimately, start your
                    journey towards financial freedom.
                  </div>
                  <div className="mt-3">
                    In case you have any questions or need more information,
                    contact us at online@fintoo.in or +91 96998 00600.
                  </div>
                </div>
                <div className={`${Styles.teamFintoo}`}>
                  <p className="pt-4">TEAM FINTOO</p>
                </div>
                <div className={`text-center ${Styles.thanksContinue}`}>
                  <button
                    className={`${Styles.Thankscontinue}`}
                    onClick={() => thanksContinue()}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ExpertRmDetails;
