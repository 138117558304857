import React, { useEffect, useRef, useState } from "react";
import Styles from "../../Pages/DMF/Portfolio/report.module.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import FintooLoader from "../FintooLoader";
import FintooInlineLoader from "../FintooInlineLoader";
import Modal from "react-responsive-modal";
// import ELSSReportView from "../../Pages/DMF/Portfolio/reports/AssetCategoriesDetailsViews/ELSSReportView";
import {
  apiCall,
  fetchEncryptData,
  getItemLocal,
  getParentUserId,
} from "../../common_utilities";
import {
  ADVISORY_DOWNLOAD_REPORT,
  ADVISORY_GET_SET_REPORT_STATUS,
  CHECK_SESSION,
  DMF_SENDMAIL_API_URL,
} from "../../constants";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import { SendMailFile } from "../../Pages/MFSnippet/Service/MessagingService";

const AdvisoryReportUI = ({ reportsData }) => {
  const repdata = {
    fileD: "",
    fileP: "",
    fileS: "",
  };
  const [file, setFile] = useState(repdata);
  const [showELSSPopup, setShowELSSPopup] = useState(false);
  const btnDownloadRef = useRef();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionData, setSessionData] = useState("");
  const [reportValue, setReportValue] = useState(1);
  const [reportType, setReportType] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [spinneremail, setSpinneremail] = useState(0);

  useEffect(() => {
    checksession();
  }, []);

  const userid = getParentUserId();
  const checksession = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      setSessionData(session_data["data"]);
      console.log("object", session_data["data"]);
    } catch (e) {
      setError(true);
    }
  };

  const sendReportMail = async (type, reportLink) => {
    const emailPayloadData = {
      email: sessionData["email"],
      subject: type == 'MF' ? 'Fintoo: Your Personalized Mutual Fund Screening Report is Here!' : 'Fintoo: Your Personalized Portfolio Analysis Report Awaits!',
      templateName: type == 'MF' ? 'mf_screening_report.html' : 'par_portfolio_analysis_report.html',
      attachment: reportLink,
      contextvar: { client_name: sessionData["user_details"]["first_name"] + " " + sessionData["user_details"]["last_name"], attachment_name: `${type == 'MF' ? 'MF screening' : 'PAR'} Report` }
    };

    const mailRes = await SendMailFile(emailPayloadData);

    if (mailRes) {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Email sent successfully",
          type: "success",
        },
      });
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Email not sent, Something went wrong...",
          type: "error",
        },
      });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (sessionData) {
        const { id, fp_user_id, fp_log_id, fp_lifecycle_status } = sessionData;

        if (fp_lifecycle_status == 2) {
          try {
            const downloadReport = async (summaryreport) => {
              const apiUrl = `${ADVISORY_DOWNLOAD_REPORT}?filename=all&action=downloadreport&merge=all&user_id=${id}&fp_user_id=${fp_user_id}&fp_log_id=${fp_log_id}&summaryreport=${summaryreport}`;

              const response = await apiCall(apiUrl, "", false, false);

              if (response.error_code === "100") {
                if (summaryreport == 0) {
                  // toastr.options.positionClass = "toast-bottom-left";
                  // toastr.success("Your report is ready to download.");
                  dispatch({
                    type: "RENDER_TOAST",
                    payload: {
                      message: "Your report is ready to email or download.",
                      type: "success",
                    },
                  });
                }
                setSpinner(0);
                setSpinneremail(0);
              }
            };

            const api_data = {
              fp_log_id: fp_log_id,
              user_id: id,
            };

            api_data.doc_type = "148";
            const config1 = {
              method: "POST",
              url: ADVISORY_GET_SET_REPORT_STATUS,
              data: api_data,
            };

            const response1 = await fetchEncryptData(config1);

            if (response1.error_code === "100") {
              await downloadReport(1);
            }

            api_data.doc_type = "77";
            const config2 = {
              method: "POST",
              url: ADVISORY_GET_SET_REPORT_STATUS,
              data: api_data,
            };

            const response2 = await fetchEncryptData(config2);

            if (response2.error_code === "100") {
              await downloadReport(0);
            }
          } catch (error) {
            console.error("Error:", error);
          }
        }
      }
      // else {
      //   dispatch({
      //     type: "RENDER_TOAST",
      //     payload: {
      //       message: "Please complete your profile.",
      //       type: "error",
      //     },
      //   });
      // }
    };

    fetchData();
  }, [sessionData]);

  const summaryReport = async () => {
    // console.log("report summary");
    const newReportValue = 1;
    setReportValue(newReportValue);
    setReportType("pdf");
    downloadReport(newReportValue);
  };

  const summaryEmail = async () => {
    // console.log("email summary");
    const newReportValue = 1;
    setReportValue(newReportValue);
    setReportType("email");
    emailReport(newReportValue);
  };

  const detailedReport = async () => {
    // console.log("report detailed");
    const newReportValue = 2;
    setReportValue(newReportValue);
    setReportType("pdf");
    downloadReport(newReportValue);
  };

  const detailedEmail = async () => {
    // console.log("email detailed");
    const newReportValue = 2;
    setReportValue(newReportValue);
    setReportType("email");
    emailReport(newReportValue);
  };

  const downloadReport = async (reportValue) => {
    // console.log("value", reportValue, sessionData)
    if (sessionData.fp_lifecycle_status === 2) {
      try {
        var d_url = "";
        var filename = "";
        var docType = "77";
        if (reportValue == 1) {
          docType = "148";
        }
        var config = {
          method: "POST",
          url: ADVISORY_GET_SET_REPORT_STATUS,
          data: {
            fp_log_id: sessionData["fp_log_id"],
            user_id: sessionData["id"],
            doc_type: docType,
          },
        };
        let response = await fetchEncryptData(config);

        if (response["error_code"] == "100") {
          if (response["data"]["islatestreport"] == "1") {
            d_url = response["data"]["s3_url"];
            filename = response["data"]["docdetails"][0]["fp_doc_unique_name"];
            setTimeout(async () => {
              window.open(d_url, "_blank");
              // setLoading(false);
              setSpinner(0);
            }, 1000);
          } else {
            // setLoading(false);
            // toastr.options.positionClass = "toast-bottom-left";
            // toastr.success(
            //   "Sit back and relax, we are creating your Financial Planning report."
            // );
            dispatch({
              type: "RENDER_TOAST",
              payload: {
                message:
                  "Sit back and relax, we are creating your Financial Planning report.",
                type: "success",
              },
            });
            setReportValue(1);
          }
        }
      } catch (error) {
        console.error("Error in downloadReport:", error);
        // setLoading(false);
        // toastr.options.positionClass = "toast-bottom-left";
        // toastr.error(
        //   "We apologize, but we're currently experiencing technical difficulties preventing the download or email of the report. Please try again later or contact our support team for assistance."
        // );
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message:
              "We apologize, but we're currently experiencing technical difficulties preventing the download or email of the report. Please try again later or contact our support team for assistance.",
            type: "error",
          },
        });
      }
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Please complete your profile.",
          type: "error",
        },
      });
    }
  };

  const emailReport = async (reportValue) => {
    // console.log("value", reportValue)
    if (sessionData.fp_lifecycle_status === 2) {
      try {
        var d_url = "";
        var filename = "";
        var docType = "77";

        if (reportValue == 1) {
          docType = "148";
        }

        console.log("value", reportValue, docType);

        var config = {
          method: "POST",
          url: ADVISORY_GET_SET_REPORT_STATUS,
          data: {
            fp_log_id: sessionData["fp_log_id"],
            user_id: sessionData["id"],
            doc_type: docType,
          },
        };

        let response = await fetchEncryptData(config);

        if (response["error_code"] == "100") {
          if (response["data"]["islatestreport"] == "1") {
            d_url = response["data"]["s3_url"];
            filename = response["data"]["docdetails"][0]["fp_doc_unique_name"];

            setTimeout(async () => {
              if (docType == "77") {
                var subject =
                  " Download your customised detailed report from Fintoo!";
              } else if (docType == "148") {
                var subject =
                  " Download your customised summary report from Fintoo!";
              }
              var emaildata = {
                userdata: { to: sessionData["email"] },
                subject: subject,
                template: "reportpdf.html",
                contextvar: {
                  encodeduserid: btoa("00" + sessionData["id"]),
                  filename: filename,
                  fullname:
                    sessionData["user_details"]["first_name"] +
                    " " +
                    sessionData["user_details"]["last_name"],
                  id: sessionData["id"],
                  fp_log_id: sessionData["fp_log_id"],
                },
              };

              let resp = await apiCall(
                DMF_SENDMAIL_API_URL,
                emaildata,
                true,
                false
              );
              setSpinneremail(0);
              // setLoading(false);
              // toastr.options.positionClass = "toast-bottom-left";
              // toastr.success("Report pdf mailed successfully.");
              dispatch({
                type: "RENDER_TOAST",
                payload: {
                  message: "Report pdf mailed successfully.",
                  type: "success",
                },
              });
            }, 1000);

            setReportValue(1);
          } else {
            // setSpinneremail(0);
            // setLoading(false);
            // toastr.options.positionClass = "toast-bottom-left";
            // toastr.success(
            //   "Sit back and relax, we are creating your Financial Planning report."
            // );
            dispatch({
              type: "RENDER_TOAST",
              payload: {
                message:
                  "Sit back and relax, we are creating your Financial Planning report.",
                type: "success",
              },
            });
            setReportValue(1);
          }
        }
      } catch (error) {
        console.error("Error in downloadReport:", error);
        // setLoading(false);
        // toastr.options.positionClass = "toast-bottom-left";
        // toastr.error(
        //   "We apologize, but we're currently experiencing technical difficulties preventing the download or email of the report. Please try again later or contact our support team for assistance."
        // );
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message:
              "We apologize, but we're currently experiencing technical difficulties preventing the download or email of the report. Please try again later or contact our support team for assistance.",
            type: "error",
          },
        });
      }
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Please complete your profile.",
          type: "error",
        },
      });
    }
  };

  return (
    <>
      {/* <FintooLoader isLoading={loading} /> */}
      <div className="row">
        {/* <div className="col-12 pt-2">
          <FintooInlineLoader
            isLoading={!Boolean(mainData?.error_code)}
            hideText={false}
          />
        </div> */}
        <div className="col-12">
          <div className={`${Styles.DataSection}`}>
            <div className={`${Styles.ReportCard} `}>
              <div className="d-flex  align-items-center justify-content-between">
                <div className="d-flex  align-items-center">
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_URL +
                        "media/DMF/Report/02_holding_report.svg"
                      }
                      width={40}
                    />
                  </div>
                  <div className={`${Styles.ReportName}`}>Summary Report</div>
                  <div className={`${Styles.Reportsshareoptions}`}>
                    {spinneremail == 1 ? (
                      <div className={`ms-2 ${Styles.downloadSpinner}`}></div>
                    ) : (
                      <img
                        width={20}
                        height={20}
                        title="Share via Mail"
                        onClick={() => {
                          summaryEmail();
                          setSpinneremail(1);
                        }}
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/DMF/Report/email.svg"
                        }
                      />
                    )}

                    {spinner == 2 ? (
                      <div className={`ms-2 ${Styles.downloadSpinner}`}></div>
                    ) : (
                      <img
                        width={18}
                        height={18}
                        title="Summary Report"
                        className="ms-3"
                        ref={btnDownloadRef}
                        onClick={() => {
                          summaryReport();
                          setSpinner(2);
                        }}
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/DMF/Report/download.svg"
                        }
                      />
                    )}

                    <div style={{ display: "none" }}>
                      <a
                        id="pa-download"
                        href={file.fileP}
                        style={{
                          textDecoration: "none",
                        }}
                        //   download={getItemLocal("family") ? "Performance_Report_" + getUserId() + "_Family" : "Performance_Report_" + getUserId()}
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${Styles.ReportCard} `}>
              <div className="d-flex  align-items-center justify-content-between">
                <div className="d-flex  align-items-center">
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_URL +
                        "media/DMF/Report/03_performance_report.svg"
                      }
                      width={40}
                    />
                  </div>
                  <div className={`${Styles.ReportName}`}>Detailed Report</div>
                  <div className={`${Styles.Reportsshareoptions}`}>
                    {spinneremail == 3 ? (
                      <div className={`ms-2 ${Styles.downloadSpinner}`}></div>
                    ) : (
                      <img
                        width={20}
                        height={20}
                        title="Share via Mail"
                        onClick={() => {
                          detailedEmail();
                          setSpinneremail(3);
                        }}
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/DMF/Report/email.svg"
                        }
                      />
                    )}
                    {spinner == 3 ? (
                      <div className={`ms-2 ${Styles.downloadSpinner}`}></div>
                    ) : (
                      <img
                        width={18}
                        height={18}
                        title="Download Detail Report"
                        className="ms-3"
                        ref={btnDownloadRef}
                        onClick={() => {
                          detailedReport();
                          setSpinner(3);
                        }}
                        src={
                          process.env.PUBLIC_URL +
                          "/static/media/DMF/Report/download.svg"
                        }
                      />
                    )}

                    <div style={{ display: "none" }}>
                      <a
                        id="pa-download"
                        href={file.fileP}
                        style={{
                          textDecoration: "none",
                        }}
                        //   download={getItemLocal("family") ? "Performance_Report_" + getUserId() + "_Family" : "Performance_Report_" + getUserId()}
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              reportsData.PAR &&
              <div className={`${Styles.ReportCard}`}>
                <div className="d-flex  align-items-center">
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_URL +
                        "media/DMF/Report/4_Valuation_Report.svg"
                      }
                      width={40}
                    />
                  </div>
                  <div className={`${Styles.ReportName}`}>Consolidated Portfolio Report</div>
                  <div className={`${Styles.Reportsshareoptions}`}>
                    <>
                      {
                        reportsData.PAR ? <>
                          <img
                            width={20}
                            height={20}
                            title="Share via Mail"
                            onClick={() => sendReportMail('PAR', reportsData.PAR)}
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/DMF/Report/email.svg"
                            }
                          />
                          <a href={reportsData.PAR} download>
                            <img
                              width={18}
                              height={18}
                              title="Download Consolidated Portfolio Report"
                              className="ms-3"
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/DMF/Report/download.svg"
                              }
                            />
                          </a>
                        </> : null
                      }
                    </>
                  </div>
                </div>
              </div>
            }

            {
              reportsData.MF &&
              <div className={`${Styles.ReportCard}`}>
                <div className="d-flex  align-items-center">
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_URL +
                        "media/DMF/Report/4_Valuation_Report.svg"
                      }
                      width={40}
                    />
                  </div>
                  <div className={`${Styles.ReportName}`}>MF Screening Report</div>
                  <div className={`${Styles.Reportsshareoptions}`}>

                    <>
                      {
                        reportsData.MF ? <>
                          <img
                            width={20}
                            height={20}
                            title="Share via Mail"
                            onClick={() => sendReportMail('MF', reportsData.MF)}
                            src={
                              process.env.PUBLIC_URL +
                              "/static/media/DMF/Report/email.svg"
                            }
                          />
                          <a href={reportsData.MF} download>
                            <img
                              width={18}
                              height={18}
                              title="Download MF Screening Report"
                              className="ms-3"
                              src={
                                process.env.PUBLIC_URL +
                                "/static/media/DMF/Report/download.svg"
                              }
                            />
                          </a>
                        </> : null
                      }
                    </>

                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <Modal
        classNames={{
          modal: "Modalpopup",
        }}
        style={{ width: "100%" }}
        open={showELSSPopup}
        showCloseIcon={false}
        // onClose={() => setIsRegulatoryUodateModalActive(false)}
        center
        animationDuration={0}
        closeOnOverlayClick={false}
      >
        {/* <ELSSReportView onClose={() => setShowELSSPopup(false)} /> */}
      </Modal>
    </>
  );
};

export default AdvisoryReportUI;
