import styles from "./style.module.css";
const LeftSection = (props) => {
  return (
    <>
      <h3 className="header-section-bold-title">
        Maximize Capital Appreciation for Long-Term Gains
      </h3>
      {/* <p className={`${styles.sectionsubText}`}>
      Personal stock advisory and expert assistance for investors across all 
investment budgets, experience and requirements.

      </p> */}
    </>
  );
};
export default LeftSection;
