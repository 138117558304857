import styles from "./style.module.css";
const LeftSection = (props) => {
  return (
    <>
      <h3 className="header-section-bold-title">
        Retire? No way! <br />
        It’s time to Recharge, Reinvent, and Relive!
      </h3>
    </>
  );
};
export default LeftSection;
