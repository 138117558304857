import { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Trade from "../../Assets/Images/CommonDashboard/Trade.png";
// import CalendarIcon from "../../Assets/Images/CommonDashboard/calendar-323.png";
import Calendar from "react-calendar";
import Medicalinsurance from "../../Assets/Images/CommonDashboard/03_Medical_insurance.svg";
import moment from "moment";
import axios from "axios";
import { imagePath } from "../../constants";
import CalendarIcon from "../../Assets/Images/CommonDashboard/calender.svg"
import Slider from "react-slick";
import {
  addSuffix,
  apiCall,
  restApiCall,
  fetchEncryptData,
  getFpUserDetailsId,
  getItemLocal,
  getParentUserId,
  getUserId
} from "../../common_utilities";
import {
  ADVISORY_GET_DOCUMENTS_API,
  ADVISORY_GET_INSURANCE_COVER_API_URL,
  ADVISORY_GET_NETWORTHLIABILITES_API_URL,
  ADVISORY_GETEQUIFAX_MEMBER_DATA_API,
  BASE_API_URL,
} from "../../constants";
import commonEncode from "../../commonEncode";
import { ADVISORY_GET_SCORECARD_API_URL, CHECK_SESSION } from "../../constants";
import Scorecard from "./Scorecard";
import MeterBox from "./Scorecard";
import RenewPopup from "./RenewPopup";
import Modal from "react-responsive-modal";
import * as BootModal from "react-bootstrap";
import BootStrapModal from "react-bootstrap/Modal";
import DownloadModal from "./DownloadModal";
import KYCPopup from "./KYCPopup";
import Graph from "../../Assets/Images/CommonDashboard/Graph.svg";
import RenewPopupTextbox from "./RenewPopupTextbox";
import KYCTextbox from "./KYCTextbox";
import { Link } from "react-router-dom";
import CreditReport from "./CreditScore/CreditReport";
import Fetchloan from "../../Pages/datagathering/AssetsLibDG/CIIBIL_Report/Fetchloan";
import Reportmodal from "./Report/Reportmodal";
import MFReportModal from "../../Pages/datagathering/MFReport/MFReportModal";
import MFReport from "./MFReport/MFReport";
import { getReports } from "../../Services/ReportService";
import TaxFiling from "./TaxFiling";
import InvestMutualFund from "./InvestMutualFund";
import SavingAccountSection from "../../Pages/MoneyManagement/views/CommonDashboard/SavingAccountSection";
import GlobalInvestment from "./GlobalInvestment";
function CardBoxGoals(props) {
  const renewpopup = props.renewpopup;
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDates, setSelecteDates] = useState([]);
  const [error, setError] = useState(false);
  const [scorecardvalue, setScorecardValue] = useState(0);
  const [ssessiondata, setSessionData] = useState({});
  const [kycDone, setKycDone] = useState(false);
  const [fpDone, setFPDone] = useState(false);
  const date = new Date().getUTCDate();
  const [allMembers, setAllMembers] = useState([]);
  const year = new Date().toLocaleDateString("en", { year: "2-digit" });
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = new Date();

  const [reportsData, setReportData] = useState({
    PAR: {
      Link: "",
      last_generated_Date: "",
    },
    MF: {
      Link: "",
      last_generated_Date: "",
    },
  });

  useEffect(() => {
    fetchReportsData();
  }, []);

  const fetchReportsData = async () => {
    const userId = parseInt(getUserId());

    const ReportData = await getReports([userId], [182, 163]);

    const PARReportLink =
      ReportData.data?.length > 0
        ? ReportData.data.filter(
          (data) => data.user_id == userId && data.report_type == "PAR"
        )[0]
        : "";
    const MFReportLink =
      ReportData.data?.length > 0
        ? ReportData.data.filter(
          (data) => data.user_id == userId && data.report_type == "MF"
        )[0]
        : "";

    console.log("PARReportLinkMFReportLink", PARReportLink, MFReportLink);

    setReportData((prev) => ({
      ...prev,
      PAR: {
        Link: PARReportLink?.download_url,
        last_generated_Date: PARReportLink?.last_generated_on,
      },
      MF: {
        Link: MFReportLink?.download_url,
        last_generated_Date: MFReportLink?.last_generated_on,
      },
    }));
  };

  const [childData, setChildData] = useState("");
  const [memberID, setMemberID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMemberSelected, setMember] = useState(false);
  const [isDataLoaded, setDataLoadFlag] = useState(false);
  const [lifeinsuranceData, setLifeInsuranceCoverData] = useState("");
  const [networtliabilitesdata, setNetwortLiabilitesData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalByName, setOpenModalByName] = useState("");
  const [kycModal, setKycModal] = useState(false);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [GraphUrl, setGraphUrl] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [defaultSelectedMember, setDefaultSelectedMember] = useState([]);
  // console.log(Graph);
  // const GraphUrl =
  // console.log("Grph url: ", GraphUrl);
  const sliderRef = useRef();
  const sliderRef1 = useRef();
  useEffect(() => {
    getSummary();
    getLifeInsuranceCover();
    getNetwortLiabilites();
    getDocument();
    getEquifaxData();

    var graph_img = imagePath + "https://stg.minty.co.in" + Graph;
    setGraphUrl(graph_img);

    // document.getElementById('GraphImage').style.background = 'url(' + imagePath + "https://stg.minty.co.in" + Graph + ") no-repeat right top";
  }, [props?.member_id, props?.member_selected]);


  const userid = getParentUserId();

  const getEquifaxData = async () => {
    // var session_data = props.sessiondata
    let url = CHECK_SESSION;
    let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
    let session_data = await apiCall(url, data, true, false);

    try {
      let reqdata = {
        user_id: session_data["data"].id,
        fp_log_id: session_data["data"].fp_log_id,
      };
      let respData = await restApiCall(
        ADVISORY_GETEQUIFAX_MEMBER_DATA_API,
        reqdata,
        true,
        false
      );

      if (respData.error_code == "100") {
        // setEquifaxData(respData.data);
        let member_selected = "";

        if (getItemLocal("family")) {
          member_selected = "all";
        } else {
          member_selected = "member_id";
        }
        let member_id = getFpUserDetailsId();

        if (respData.valid_members.length > 0) {
          var valid_members = respData.valid_members;

          const all = respData.valid_members.map((v) =>
            v.relation_id == 1
              ? {
                name: v.first_name + " " + v.last_name,
                id: v.id,
                dob: v.dob,
                pan: v.PAN,
                mobile: v.alternate_mobile,
                label: v.first_name + " " + v.last_name + " (Self)",
                value: v.id,
              }
              : {
                name: v.first_name + " " + v.last_name,
                id: v.id,
                dob: v.dob,
                pan: v.PAN,
                mobile: v.alternate_mobile,
                label: v.first_name + " " + v.last_name,
                value: v.id,
              }
          );
          setAllMembers([...all]);

          for (let i = 0; i < valid_members.length; i++) {
            // check for Family option, show for self
            if (
              (member_selected == "all" && valid_members[i].relation_id == 1) ||
              (member_selected == "member_id" &&
                valid_members[i].id == member_id)
            ) {
              // let credit_score = valid_members[i].cibil_score;
              // setIsFetched(true);
              let mem = valid_members[i];

              if (mem.relation_id == 1) {
                setDefaultSelectedMember({
                  name: mem.first_name + " " + mem.last_name,
                  id: mem.id,
                  dob: mem.dob,
                  pan: mem.PAN,
                  mobile: mem.alternate_mobile,
                  label: mem.first_name + " " + mem.last_name + " (Self)",
                  value: mem.id,
                });
              } else {
                setDefaultSelectedMember({
                  name: mem.first_name + " " + mem.last_name,
                  id: mem.id,
                  dob: mem.dob,
                  pan: mem.PAN,
                  mobile: mem.alternate_mobile,
                  label: mem.first_name + " " + mem.last_name,
                  value: mem.id,
                });
              }

              break;
            }
          }
        } else {
          setAllMembers([]);
        }

        if (respData.data.length > 0) {
          let equifax_data = respData.data;

          for (let i = 0; i < equifax_data.length; i++) {
            // let member_selected = props.member_selected ? "member_id" : "all";

            // check for Family option, show for self
            if (
              (member_selected == "all" && equifax_data[i].relation_id == 1) ||
              (member_selected == "member_id" &&
                equifax_data[i].member_id == member_id)
            ) {
              let credit_score = equifax_data[i].cibil_score;
              setIsFetched(true);

              break;
            }
          }
        }
      } else {
        setAllMembers([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSummary = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      setSessionData(session_data["data"]);
      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: session_data["data"]["fp_user_id"],
      };

      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var scorecard_data = await apiCall(
        ADVISORY_GET_SCORECARD_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(scorecard_data));
      if (res.error_code == "100" && res.data != "") {
        setScorecardValue(res.data.total_score);
      } else {
        setScorecardValue(0);
      }
    } catch (e) {
      setError(true);
    }
  };
  const CloseLoanModal = () => {
    setOpenModalByName(null);
    sliderRef.current.slickPlay();
  };
  const getNetwortLiabilites = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      let member_id = props.member_id;
      member_id = getFpUserDetailsId();

      let member_selected = props.member_selected ? "member_id" : "all";

      if (getItemLocal("family")) {
        member_selected = "all";
      } else {
        member_selected = "member_id";
      }

      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: member_id,
        filter_type: member_selected,
      };

      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var networtliabilites_data = await apiCall(
        ADVISORY_GET_NETWORTHLIABILITES_API_URL,
        payload_data,
        false,
        false
      );
      var res = JSON.parse(commonEncode.decrypt(networtliabilites_data));
      if ((res.error_code = "100" && res.data != "")) {
        setNetwortLiabilitesData(res.data);
      } else {
        setNetwortLiabilitesData({
          asset_data: 0,
          asset_sum_formatted: "0",
          liability_sum: 0,
          liability_sum_formatted: "0",
          networth_sum: 0,
          networth_sum_formatted: "0",
        });
      }
    } catch (e) {
      setError(true);
    }
  };
  const getLifeInsuranceCover = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: userid, sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);

      let member_id = props.member_id;
      member_id = getFpUserDetailsId();

      let member_selected = props.member_selected ? "member_id" : "all";

      if (getItemLocal("family")) {
        member_selected = "all";
      } else {
        member_selected = "member_id";
      }

      let api_data = {
        fp_log_id: session_data["data"]["fp_log_id"],
        user_id: session_data["data"]["id"],
        fp_user_id: member_id,
        filter_type: member_selected,
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));

      var res = await apiCall(
        ADVISORY_GET_INSURANCE_COVER_API_URL,
        payload_data,
        false,
        false
      );

      let decoded_res = JSON.parse(commonEncode.decrypt(res));
      if (decoded_res["error_code"] == "100") {
        setDataLoadFlag(true);
        setIsLoading(false);
        setLifeInsuranceCoverData(decoded_res["data"]);
      } else {
        setDataLoadFlag(true);
        setIsLoading(false);
        setLifeInsuranceCoverData({
          life_insurance_sum_assured: 0,
          life_insurance_sum_assured_formatted: "0",
          medical_insurance_sum_assured: 0,
          medical_insurance_sum_assured_formatted: "0",
        });
      }
    } catch (e) {
      setError(true);
    }
  };

  const getDocument = async () => {
    try {
      var payload = {
        method: "POST",
        url: CHECK_SESSION,
        data: { user_id: getParentUserId(), sky: getItemLocal("sky") },
      };
      let session_data = await fetchEncryptData(payload);
      if (session_data["error_code"] == "100") {
        setSessionData(session_data.data);
        if (session_data.data.plan_payment_status == "1") {
          setPaymentDone(true);
        }

        const staticDate = new Date("10/01/2023"); // this date added for handle old user (1st Oct 2023)
        const plan_date = new Date(session_data.data.plan_date);
        if (plan_date > staticDate) {
          var payload = {
            method: "POST",
            url: ADVISORY_GET_DOCUMENTS_API,
            data: {
              user_id: session_data["data"]["id"],
              fp_log_id: session_data["data"]["fp_log_id"],
            },
          };
          let get_document = await fetchEncryptData(payload);
          let showPopup = 0;
          if (get_document["error_code"] == "100") {
            if (
              session_data["data"]["plan_id"] == "31" &&
              session_data["data"]["plan_payment_status"] == "1"
            ) {
              setFPDone(false);
              showPopup = 1;
            }
            const currentDate = new Date();
            for (const doc of get_document.data) {
              if (doc.doc_type === 167 || doc.doc_type === 168) {
                setKycDone(true);
              }
              const docAddedDate = new Date(doc.doc_added);
              if (docAddedDate < staticDate) {
                if (doc.doc_type === 154 || doc.doc_type === 134) {
                  setFPDone(true);
                  showPopup = 1;
                }
              } else {
                if (doc.doc_type === 164) {
                  docAddedDate.setFullYear(docAddedDate.getFullYear() + 1);
                  if (docAddedDate > currentDate) {
                    setFPDone(true);
                    showPopup = 1;
                  }
                }
              }
            }
            if (showPopup == 0) {
              setFPDone(true);
            }
          } else {
            setFPDone(false);
            setKycDone(false);
          }
          setKycDone(true); // remove this line after digi locker working
        } else {
          setFPDone(true);
          setKycDone(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CloseparModal = () => {
    setOpenModalByName(null);
    sliderRef.current.slickPlay();
    sliderRef1.current.slickPlay();
    fetchReportsData();
  };

  const CloseMfModal = () => {
    sliderRef.current.slickPlay();
    sliderRef1.current.slickPlay();
    fetchReportsData();
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 8000,
    autoplay: true,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    margin: 20,
    centerPadding: "20",
    dotsClass: "slick-dots categories-slick-dots dashboard-slick-dots",
  };
  return (
    <>
      <div className="d-md-flex justify-content-md-between justify-content-md-center mb-4">
        {renewpopup == 2 ? (
          <div className="RenewMsgbox">
            <RenewPopupTextbox showpopup={true} />
          </div>
        ) : (
          ""
        )}
        {renewpopup != 2 && <div className="RenewMsgbox">{<KYCTextbox />}</div>}
      </div>
      {/* <div className="ms-3 mb-4">
        <p className="m-0" style={{ fontSize: "1.9rem", fontWeight: "bold" }}>Welcome to Fintoo!</p>
        <p className="m-0" style={{ fontSize: "1.2rem", fontWeight: "400", color: "gray" }}>
          A wealth management solution powered by expert guidance and strategic advisory services.
        </p>
      </div> */}

      <div className="card-box GoalBox ml-auto" style={{ margin: "0 1rem" }}>
        <div className="row d-flex align-items-center">
          <div className="col-md-4 col-lg-4 col-12">
            <Slider ref={sliderRef1} {...settings}>
              <div
                className="cardBox GraphImg autoAdvisory"
                id="GraphImage"
                style={{ backgroundImage: `url(${GraphUrl})` }}
              // style={{backgroundImage: 'url(${imagePath} + "https://stg.minty.co.in" + ${Graph})'}}
              >

                {renewpopup == 2 ? (
                  <>

                    <div className="me-4 mt-4 AssettotalValue">
                      <div className="TextLabel">
                        {/* {!props.member_selected
                        ? "Family Net Worth"
                        : "Net Worth"} */}
                        Assets Value
                      </div>
                      <div className={`d-flex align-items-center`}>
                        <div
                          className={`valueLabel ${networtliabilitesdata &&
                            (networtliabilitesdata.asset_sum_formatted ||
                              networtliabilitesdata.asset_sum_formatted === 0)
                            ? null
                            : "shine"
                            }`}
                        >
                          ₹{" "}
                          <span>
                            <span className="bigBalue">
                              {networtliabilitesdata &&
                                networtliabilitesdata.asset_sum_formatted}
                            </span>
                          </span>
                        </div>
                        <div className="ps-4">
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              "/direct-mutual-fund/portfolio/dashboard/"
                            }
                          >
                            <img
                              width={20}
                              height={20}
                              className="pointer"
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/DG/NextImg.svg"
                              }
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="autoAdvisoryLabel mt-3 d-flex">
                      <div>
                        <a
                          className="text-decoration-none text-black"
                          onClick={onOpenModal}
                        >
                          <div className="">
                            <div className="TextLabel">
                              {!props.member_selected
                                ? "Family Net Worth"
                                : "Net Worth"}
                            </div>
                            <div
                              className={`d-flex align-items-center justify-content-between ${networtliabilitesdata &&
                                (networtliabilitesdata.networth_sum_formatted ||
                                  networtliabilitesdata.networth_sum_formatted ===
                                  0)
                                ? null
                                : "shine"
                                }`}
                            >
                              <div className="valueLabel">
                                ₹{" "}
                                <span className="bigBalue">
                                  {networtliabilitesdata &&
                                    networtliabilitesdata.networth_sum_formatted}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div
                        className="borderRight"
                        style={{ margin: "0 2rem" }}
                      ></div>
                      <div className="">
                        <a
                          className="text-decoration-none text-black"
                          onClick={onOpenModal}
                        >
                          <div className="me-4">
                            <div className="TextLabel">
                              {!props.member_selected
                                ? "Overall Liabilities"
                                : "Liabilities"}
                            </div>
                            <div
                              className={`d-flex align-items-center justify-content-between ${networtliabilitesdata &&
                                (networtliabilitesdata.liability_sum_formatted ||
                                  networtliabilitesdata.liability_sum_formatted ===
                                  0)
                                ? null
                                : "shine"
                                }`}
                            >
                              <div className="valueLabel">
                                ₹{" "}
                                <span>
                                  {networtliabilitesdata &&
                                    networtliabilitesdata.liability_sum_formatted}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="me-4 mt-4 AssettotalValue">
                      <div className="TextLabel">
                        {/* {!props.member_selected
                        ? "Family Net Worth"
                        : "Net Worth"} */}
                        Assets Value
                      </div>
                      <div className={`d-flex align-items-center`}>
                        <div
                          className={`valueLabel ${networtliabilitesdata &&
                            (networtliabilitesdata.asset_sum_formatted ||
                              networtliabilitesdata.asset_sum_formatted === 0)
                            ? null
                            : "shine"
                            }`}
                        >
                          ₹{" "}
                          <span>
                            <span className="bigBalue">
                              {networtliabilitesdata &&
                                networtliabilitesdata.asset_sum_formatted}
                            </span>
                          </span>
                        </div>
                        <div className="ps-4">
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              "/direct-mutual-fund/portfolio/dashboard/"
                            }
                          >
                            <img
                              width={20}
                              height={20}
                              className="pointer"
                              src={
                                process.env.REACT_APP_STATIC_URL +
                                "media/DG/NextImg.svg"
                              }
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="autoAdvisoryLabel mt-3 d-flex">
                      <div>
                        <a
                          className="text-decoration-none text-black"
                          href={
                            ssessiondata.plan_id === 29
                              ? process.env.PUBLIC_URL +
                              "/report/assets-liabilities?tab=networth"
                              : process.env.PUBLIC_URL +
                              "/datagathering/assets-liabilities"
                          }
                        >
                          <div className="">
                            <div className="TextLabel">
                              {!props.member_selected
                                ? "Family Net Worth"
                                : "Net Worth"}
                            </div>
                            <div
                              className={`d-flex align-items-center justify-content-between ${networtliabilitesdata &&
                                (networtliabilitesdata.networth_sum_formatted ||
                                  networtliabilitesdata.networth_sum_formatted ===
                                  0)
                                ? null
                                : "shine"
                                }`}
                            >
                              <div className="valueLabel">
                                ₹{" "}
                                <span className="bigBalue">
                                  {networtliabilitesdata &&
                                    networtliabilitesdata.networth_sum_formatted}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div
                        style={{ margin: "0 2rem" }}
                        className="borderRight"
                      ></div>
                      <div>
                        <a
                          className="text-decoration-none text-black"
                          href={
                            ssessiondata.plan_id === 29
                              ? process.env.PUBLIC_URL +
                              "/report/assets-liabilities?tab=liabilities"
                              : process.env.PUBLIC_URL +
                              "/datagathering/assets-liabilities"
                          }
                        >
                          <div className="me-4">
                            <div className="TextLabel">
                              {!props.member_selected
                                ? "Overall Liabilities"
                                : "Liabilities"}
                            </div>
                            <div
                              className={`d-flex align-items-center justify-content-between ${networtliabilitesdata &&
                                (networtliabilitesdata.liability_sum_formatted ||
                                  networtliabilitesdata.liability_sum_formatted ===
                                  0)
                                ? null
                                : "shine"
                                }`}
                            >
                              <div className="valueLabel">
                                ₹{" "}
                                <span>
                                  {networtliabilitesdata &&
                                    networtliabilitesdata.liability_sum_formatted}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="cardBox  autoAdvisory lifeInsurance">
                {renewpopup == 2 ? (
                  <div className="autoAdvisoryLabel">
                    <div className="">
                      <a
                        className="text-decoration-none text-black"
                        onClick={onOpenModal}
                      >
                        <div className="me-2">
                          <diwwwv className="TextLabel">
                            {!props.member_selected
                              ? "Total Life Insurance"
                              : "Life Insurance"}
                          </diwwwv>
                          <div
                            className={`d-flex align-items-center justify-content-between ${lifeinsuranceData &&
                              (lifeinsuranceData.life_insurance_sum_assured_formatted ||
                                lifeinsuranceData.life_insurance_sum_assured_formatted ===
                                0)
                              ? null
                              : "shine"
                              }`}
                          >
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span className="bigBalue">
                                {
                                  lifeinsuranceData.life_insurance_sum_assured_formatted
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      className="borderRight"
                      style={{ margin: "0 2rem" }}
                    ></div>
                    <div className="">
                      <div>
                        <a
                          className="text-decoration-none text-black"
                          onClick={onOpenModal}
                        >
                          <div className="TextLabel">Medical Cover</div>
                          <div
                            style={{ width: "50%" }}
                            className={`d-flex align-items-center justify-content-between ${lifeinsuranceData &&
                              (lifeinsuranceData.medical_insurance_sum_assured_formatted ||
                                lifeinsuranceData.medical_insurance_sum_assured_formatted ===
                                0)
                              ? null
                              : "shine"
                              }`}
                          >
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span>
                                {
                                  lifeinsuranceData.medical_insurance_sum_assured_formatted
                                }
                              </span>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div style={{
                        float: "right",
                        marginTop: "-3.1rem"

                      }}>
                        <img
                          width={200}
                          src={
                            process.env.REACT_APP_STATIC_URL +
                            "media/Mediclaim_Insurance.svg"
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4 autoAdvisoryLabel">
                    <div className="">
                      <a
                        className="text-decoration-none text-black"
                        href={
                          ssessiondata.plan_id === 29
                            ? process.env.PUBLIC_URL +
                            "/report/risk-management?tab=lifeinsurance"
                            : process.env.PUBLIC_URL +
                            "/datagathering/insurance"
                        }
                      >
                        <div className="me-4">
                          <div className="TextLabel">
                            {!props.member_selected
                              ? "Total Life Insurance"
                              : "Life Insurance"}
                          </div>
                          <div
                            style={{ width: "50%" }}
                            className={`d-flex align-items-center justify-content-between ${lifeinsuranceData &&
                              (lifeinsuranceData.life_insurance_sum_assured_formatted ||
                                lifeinsuranceData.life_insurance_sum_assured_formatted ===
                                0)
                              ? null
                              : "shine"
                              }`}
                          >
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span className="bigBalue">
                                {
                                  lifeinsuranceData.life_insurance_sum_assured_formatted
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="">
                      <div>
                        <a
                          className="text-decoration-none text-black"
                          href={
                            ssessiondata.plan_id === 29
                              ? process.env.PUBLIC_URL +
                              "/report/risk-management?tab=medicalinsurance"
                              : process.env.PUBLIC_URL +
                              "/datagathering/insurance"
                          }
                        >
                          <div className="TextLabel">Medical Cover</div>
                          <div
                            style={{ width: "50%" }}
                            className={`d-flex align-items-center justify-content-between ${lifeinsuranceData &&
                              (lifeinsuranceData.medical_insurance_sum_assured_formatted ||
                                lifeinsuranceData.medical_insurance_sum_assured_formatted ===
                                0)
                              ? null
                              : "shine"
                              }`}
                          >
                            <div className="valueLabel">
                              &#8377;{" "}
                              <span>
                                {
                                  lifeinsuranceData.medical_insurance_sum_assured_formatted
                                }
                              </span>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* <div className="Imgbox">
                <img className="" src={Medicalinsurance} width={130} />
              </div> */}
                    </div>
                    <div
                      style={{
                        float: "right",
                        marginTop: "-4.1rem",
                      }}
                    >
                      <img
                        width={200}
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "media/Mediclaim_Insurance.svg"
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </Slider>
          </div>

          <div className="col-md-4 col-lg-4 col-12">
            <Slider ref={sliderRef} {...settings}>
              <div
                className="cardBox col-md-4 col-lg-4 col-12 ScoreCardBox mt-md-0 mt-5"
                id="scorecard"
              >
                <div
                  style={{
                    fontSize: "1.6rem",
                  }}
                  className="text-bold"
                >
                  Your Scorecard
                </div>
                <div>
                  <Scorecard value={scorecardvalue} width={170} />
                </div>
                <div className="d-flex justify-content-start">
                  {(ssessiondata.plan_id === 29 ||
                    (ssessiondata.plan_id === 31 &&
                      ssessiondata.plan_payment_status == "1")) && (
                      <>
                        {renewpopup === 2 ? (
                          <button
                            onClick={onOpenModal}
                            className="viewReport pointer custom-background-color custom-border-color"
                          >
                            View Report
                          </button>
                        ) : !kycDone || !fpDone ? (
                          <button
                            onClick={() => setKycModal(true)}
                            className="viewReport pointer custom-background-color custom-border-color"
                          >
                            View Report
                          </button>
                        ) : (
                          <button
                            onClick={() => setOpenModal(true)}
                            className="viewReport pointer custom-background-color custom-border-color"
                          >
                            View Report
                          </button>
                        )}
                      </>
                    )}

                  <button
                    style={{
                      background: "none !important",
                    }}
                    className="pointer custom-background-color custom-border-color EditData"
                  >
                    {renewpopup === 2 ? (
                      <a
                        onClick={() => {
                          onOpenModal();
                        }}
                        className="pointer"
                      >
                        Edit Data
                      </a>
                    ) : (
                      <a
                        href={process.env.PUBLIC_URL + "/datagathering/about-you"}
                        className="pointer"
                      >
                        Edit Data
                      </a>
                    )}
                  </button>
                </div>
              </div>
              <div
                className="cardBox p-0 autoAdvisory CreditReportbox"
                style={{
                  background: isFetched == true ? "none !important" : "",
                }}
              >
                <div
                  style={{
                    borderRadius: isFetched ? "15px" : "15px 137px 137px 15px",
                    background: "#fff",
                    width: isFetched ? "100%" : "60%",
                    height: "100%",
                    padding: "0 1rem",
                  }}
                >
                  <CreditReport
                    sessiondata={ssessiondata}
                    userid={getParentUserId()}
                    //  member_id={}
                    isFetched={isFetched}
                    setOpenModalByName={(v) => {
                      sliderRef.current.slickPause();
                      sliderRef1.current.slickPause();
                      setOpenModalByName(v);
                    }}
                  />
                </div>
              </div>
            </Slider>
          </div>



          <DownloadModal
            show={openModal}
            onHide={() => setOpenModal(false)}
            ssessiondata={ssessiondata}
          />


          <div className="col-md-4 col-lg-4 col-12">
            <Slider ref={sliderRef} {...settings}>
              <div
                className="cardBox p-0 autoAdvisory mfReportbox"
                style={{ backgroundColor: "#EEF9FF" }}
              >
                <div
                  style={{
                    borderRadius: "15px 137px 137px 15px",
                    background: "#fff",
                    width: "60%",
                    height: "100%",
                    padding: "1rem",
                  }}
                >
                  <MFReport
                    setOpenModalByName={(v) => {
                      sliderRef1.current.slickPause();
                      sliderRef.current.slickPause();
                      setOpenModalByName(v);
                    }}
                    popup={"mfreport"}
                    title={"MF Screening"}
                    reportLink={reportsData.MF}
                  />
                </div>
                {/* */}
              </div>

              <div
                className="cardBox p-0 autoAdvisory ParReportbox"
                style={{ backgroundColor: "#EEF9FF" }}
              >
                <div
                  style={{
                    borderRadius: "15px 137px 137px 15px",
                    background: "#fff",
                    width: "60%",
                    height: "100%",
                    padding: "1rem",
                  }}
                >
                  <MFReport
                    setOpenModalByName={(v) => {
                      sliderRef1.current.slickPause();
                      sliderRef.current.slickPause();
                      setOpenModalByName(v);
                    }}
                    popup={"parreport"}
                    title={"Consolidated Portfolio"}
                    reportLink={reportsData.PAR}
                  />
                </div>
                {/* */}
              </div>
            </Slider>
          </div>

        </div>
      </div>

      <SavingAccountSection />

      <div className="mt-5" style={{ margin: "0 1rem" }}>
        <div className="row">
          <div className="col-md-4 col-lg-4 col-12">
            <Slider ref={sliderRef1} {...settings}>
              <div
                className="cardBox p-0 autoAdvisory mfReportbox"
                style={{ backgroundColor: "#EEF9FF" }}
              >
                <div
                  style={{
                    borderRadius: "15px 137px 137px 15px",
                    background: "#fff",
                    width: "60%",
                    height: "100%",
                    padding: "1rem",
                  }}
                >
                  <TaxFiling
                    setOpenModalByName={(v) => {
                      sliderRef1.current.slickPause();
                      sliderRef.current.slickPause();
                      setOpenModalByName(v);
                    }}
                    popup={"mfreport"}
                    title={"Tax Filing"}
                    reportLink={reportsData.MF}
                  />
                </div>
                {/* */}
              </div>
            </Slider>
          </div>
          <div className="col-md-4 col-lg-4 col-12">
            <Slider ref={sliderRef1} {...settings}>
              <div
                className="cardBox p-0 autoAdvisory mfReportbox mfInvestExplore"
                style={{ backgroundColor: "#EEF9FF" }}
              >
                <div
                  style={{
                    borderRadius: "15px 137px 137px 15px",
                    background: "#fff",
                    width: "60%",
                    height: "100%",
                    padding: "1rem",
                  }}
                >
                  <InvestMutualFund
                    setOpenModalByName={(v) => {
                      sliderRef1.current.slickPause();
                      sliderRef.current.slickPause();
                      setOpenModalByName(v);
                    }}
                    popup={"mfreport"}
                    title={"Tax Filing"}
                    reportLink={reportsData.MF}
                  />
                </div>
                {/* */}
              </div>
            </Slider>
          </div>
          <div className="col-md-4 col-lg-4 col-12">
            <Slider ref={sliderRef1} {...settings}>
              <div
                className="cardBox p-0 autoAdvisory goalInvestment"
                style={{ backgroundColor: "#EEF9FF" }}
              >
                <div
                  style={{
                    borderRadius: "15px 137px 137px 15px",
                    background: "#fff",
                    width: "60%",
                    height: "100%",
                    padding: "1rem",
                  }}
                >
                  <GlobalInvestment />
                </div>
                {/* */}
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <KYCPopup
        kycDone={kycDone}
        fpDone={fpDone}
        show={kycModal}
        sessiondata={ssessiondata}
        onHide={() => setKycModal(false)}
      />
      <Modal
        className="Modalpopup"
        open={open}
        showCloseIcon={false}
        onClose={onCloseModal}
        center
      >
        <div className="text-center">
          <h2 className="HeaderText">Attention !!</h2>
          <RenewPopup
            open={open}
            onClose={onCloseModal}
            subscriptionenddate={props.subscriptionenddate}
          />
        </div>
      </Modal>

      <BootModal.Modal
        dialogClassName="Nsdlcsdl-modal-width"
        className="Modalpopup"
        show={openModalByName == "Fecth_your_Loan"}
        centered
        animationDuration={0}
      >
        <Fetchloan
          Closemodal={CloseLoanModal}
          session={ssessiondata}
          allMembers={allMembers}
          isCardBox={true}
          getEquifaxData={getEquifaxData}
          defaultSelectedMember={defaultSelectedMember}
          is_plan={true}
        />
      </BootModal.Modal>

      <Reportmodal
        open={openModalByName == "PAR_Report"}
        Closemodal={CloseparModal}
        forpar={true}
        fetchReportsData={fetchReportsData}
      />

      <MFReportModal
        open={openModalByName == "MF_Screening"}
        CloseMfModal={CloseMfModal}
        setOpenModalByName={setOpenModalByName}
        fetchReportsData={fetchReportsData}
      />
    </>
  );
}

export default CardBoxGoals;
