import { useState, useEffect, useRef } from "react";
import "react-responsive-modal/styles.css";
import Link from "../../../MainComponents/Link";
import LocationImg from "../../../Assets/03_Location_search.svg";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Container, Row, Col, Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import Back from "../../../Assets/left-arrow.png";
import FintooButton from "../../../HTML/FintooButton";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import useForm from "./useForm";
import * as constClass from "../../../../constants";
import {
  fetchEncryptData,
  getUserId,
  memberId,
  user_id,
} from "../../../../common_utilities";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import commonEncode from "../../../../commonEncode";
import {
  apiCall,
  successAlert,
  errorAlert,
  CheckSession,
} from "../../../../common_utilities";
import { format } from "date-fns";
import WhiteOverlay from "../../../HTML/WhiteOverlay";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import FintooLoader from "../../../../components/FintooLoader";

function AadharAddress(props) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const locationRef = useRef(null);
  const dataExistRef = useRef(false);
  const [validated, setValidated] = useState(false);
  const [selectVisibleCountry, setSelectVisibleCountry] = useState(true);
  const [selectVisibleState, setSelectVisibleState] = useState(true);
  const [selectVisibleCity, setSelectVisibleCity] = useState(true);
  const dispatch = useDispatch();
  const { AadharAddress } = useSearchParams();
  const showBack = useSelector((state) => state.isBackVisible);

  // for populating list
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [reloadPincode, setReloadPincode] = useState(false);
  const [addressDisable, setAddressDisable] = useState(false);
  const [userzip, setzip] = useState(false);
  const [usercity, setcity] = useState(false);
  const [userstate, setstate] = useState(false);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [reloadCountry, setReloadCountry] = useState(false);
  const [statesList, setStatesList] = useState("");
  const [stateId, setStateId] = useState("");
  const [reloadState, setReloadState] = useState(false);
  const [cityId, setCityId] = useState("");
  const [reloadCity, setReloadCity] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const [loadFromAadhar, setLoadFromAadhar] = useState(false);
  const [user_district, setUserDistrict] = useState("");
  const [user_country, setUserCountry] = useState("");
  const [user_locality, setUserLocality] = useState("");
  const [user_state, setUserSatate] = useState("");
  const userid = memberId();
  const [, forceUpdate] = useState();
  const apiCountryRef = useRef();
  const apiStateRef = useRef();
  const apiCityRef = useRef();


  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    populateCountry();
  }, []);

  const onLoadInIt = async () => {
    try {
      var form_data = { user_id: getUserId() };
      var req_data = commonEncode.encrypt(JSON.stringify(form_data));
      var config_list = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: req_data,
      };
      var res = await axios(config_list);
      var response = commonEncode.decrypt(res.data);
      var responseobj = JSON.parse(response);
      const req_req = localStorage.getItem("req");

      setAddress(responseobj.data["address"]);

      if (responseobj.data["aadhar_verified"] == "Yes" && responseobj.data["address"]) {
        setAddressDisable(true);
        setzip(true);
        setstate(true);
        setcity(true);
      }
      if ("pincode" in responseobj.data && responseobj.data["pincode"]) {
        dataExistRef.current = true;
        if ("state" in responseobj.data && responseobj.data["state"])
          setStateId(responseobj.data["state"]);
        if ("city" in responseobj.data && responseobj.data["city"])
          setCityId(responseobj.data["city"]);
        setPincode(responseobj.data["pincode"]);
      }
      if (req_req != null) {
        const req_data = JSON.parse(commonEncode.decrypt(req_req));
        const response = req_data.data.address;
        const responsezip = req_data.data.zip;
        setAddress(
          response.house +
            " " +
            response.loc +
            " " +
            response.landmark +
            " " +
            response.vtc +
            " " +
            response.street
        );
        if (responsezip != null) {
          setPincode(responsezip);
          setReloadPincode();
          setzip(true);
          setstate(true);
          setcity(true);
        }
        setAddressDisable(true);
      }
      var error = responseobj["error_code"];
      var api_message = responseobj["message"];

      if (error == "103") {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: api_message, type: "error" },
        });
      }
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something wents wrong", type: "error" },
      });
    }
  };

  useEffect(() => {
    if (pincode) {
      fetchAutoCountry();
    }
  }, [pincode]);

  useEffect(() => {
    if (countryId) {
      fetchAutoState();
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId) {
      fetchAutoCity();
    }
  }, [stateId]);

  useEffect(() => {
    if (countries.length > 0) {
      onLoadInIt();
    }
  }, [countries]);

  
  const populateCountry = async () => {
    try {
      var res = await axios.post(constClass.DMF_GETCOUNTRIES_API_URL);
      var a = res.data.data;
      setCountries(a);
      setCountryId(
        a.filter((v) => v.country_name.toLowerCase() === "india")[0][
          "country_id"
        ]
      );
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went wrong...", type: "error" },
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    var formValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    forceUpdate(1);

    if (formValid === false) {
    } else {
      var url = constClass.DMF_UPDATEBASICDETAILS_API_URL;
      var data = {
        user_id: "" + getUserId(),
        city: "" + cityId,
        state: "" + stateId,
        country: "" + countryId,
        address: "" + address,
        fintoo_address: "" + address,
        pin_code: "" + pincode,
        is_direct: "" + 1,
      };

      try {
        var res = await fetchEncryptData({ url, data, method: "post" });

        dispatch({
          type: "RENDER_TOAST",
          payload: { message: res.message, type: "success" },
        });
        props.onNext();
      } catch (e) {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: e.toString(), type: "error" },
        });
      }
    }
  };

  const fetchAutoCountry = async () => {
    try {
      setShowLoad(true);
      var cId;
      if(apiCountryRef.current) {
        apiCountryRef.current.abort();
      }
      apiCountryRef.current = new AbortController();
      
      var res = await axios.get(process.env.REACT_APP_PINCODE_CHECK + pincode, {
        signal: apiCountryRef.current.signal
      });
      var data = res.data;
      locationRef.current = { ...data };
      if(locationRef.current.State) {
        setstate(true);
      } else {
        setstate(false);
      }
      fetchAutoState();

      var index = countries.findIndex(
        (v) => v.country_name == locationRef.current.Country
      );
      if (index > -1) {
        setCountryId(countries[index].country_id);
      }
      setShowLoad(false);
    } catch (e) {
      setShowLoad(false);
    }
  };

  const fetchAutoState = async () => {
    if (Boolean(countryId) == false) return;
    try {
      setShowLoad(true);
      if (dataExistRef.current === false) {
        setStateId("");
        setCityId("");
      }
      if(apiStateRef.current) {
        apiStateRef.current.abort();
      }
      apiStateRef.current = new AbortController();
      var res = await axios.post(
        process.env.REACT_APP_PYTHON_URL + "/direct-mutual-fund/api/common/getstates",
        { country_id: "" + countryId },
        {signal: apiStateRef.current.signal}
      );
      var data = res.data.data;
      setStatesList(data);
    

      var index = data.findIndex(
        (v) => v.state_name == locationRef.current.State
      );
      if (index > -1) {
        setStateId(data[index].state_id);
      }
      setShowLoad(false);
    } catch (e) {
      setShowLoad(false);
    }
  };

  const fetchAutoCity = async () => {
    if (Boolean(countryId) == false) return;
    try {
      setShowLoad(true);
      if (dataExistRef.current === false) {
        setCityId("");
      }
      if(apiCityRef.current) {
        apiCityRef.current.abort();
      }
      apiCityRef.current = new AbortController();
      var res = await axios.post(
        process.env.REACT_APP_PYTHON_URL + "/direct-mutual-fund/api/common/getcities",
        { state_id: "" + stateId },
        {signal: apiCityRef.current.signal}
      );
      var data = res.data.data;
      console.log(data,"city123");
      setCities(data);
      
      var index = data.findIndex(
        (v) => v.city_name == locationRef.current.District
      );
      if(locationRef.current.District)
      {
        setcity(true); 
      }
      else{
        setcity(false);
      }
      if (index > -1) {
        setCityId("" + data[index].city_id);
        
      }
      setShowLoad(false);
    } catch (e) {
      setShowLoad(false);
    }
  };

  return (
    <Row className="reverse">
      <Col className="col-12 col-md-6 ProfileImg">
        <div>
          <img src={LocationImg} alt="Location" />
        </div>
      </Col>
      <Col className="col-12 col-md-6 RightPanel">
        <FintooLoader show={showLoad} />
        <div className="rhl-inner">
          {showBack == true && (
            <FintooProfileBack
              title="Confirm your Address"
              onClick={() => props.onPrevious()}
            />
          )}
          <div>
            <p></p>
            Your address should be as mentioned in your legal document.
          </div>
          <div className="VerifyDetails" style={{ marginTop: "0rem" }}>
            <div className="">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="Nominee_Identity" style={{ marginTop: "0rem" }}>
                  <div className="profile-space-1">
                    <Form.Label className="LabelName" htmlFor="inputText">
                      Address:
                    </Form.Label>
                    <textarea
                      disabled={addressDisable}
                      placeholder="Enter address here"
                      className="shadow-none form-control"
                      style={{ height: "140px", borderRadius: "10px" }}
                      name="address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value.trimStart());
                      }}
                      onBlur={() => {
                        simpleValidator.current.showMessageFor("address");
                      }}
                      autoComplete="off"
                    ></textarea>
                    {simpleValidator.current.message(
                      "address",
                      address.replaceAll(" ", ""),
                      "required|min:6|max:250",
                      {
                        messages: {
                          required: "Please enter Address.",
                          max: "The address should not be greater than 250 characters.",
                        },
                      }
                    )}
                  </div>
                  <div className="row">
                    <div className=" col-12 col-md-6 profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        Pin Code:
                      </Form.Label>
                      <input
                        disabled={userzip}
                        maxLength="6"
                        aria-label="Default select example"
                        className="shadow-none form-control"
                        name="pincode"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            /^\d+$/.test(e.target.value)
                          ) {
                            setPincode(e.target.value);
                          }
                        }}
                        value={pincode}
                        onBlur={() => {
                          simpleValidator.current.showMessageFor("pincode");
                          if (simpleValidator.current.fieldValid("pincode")) {
                            dataExistRef.current = false;
                            fetchAutoCountry();
                          }
                        }}
                        autoComplete="off"
                      />
                      {simpleValidator.current.message(
                        "pincode",
                        pincode,
                        "required|max:6|min:6",
                        { messages: { required: "Please enter Pin Code." } }
                      )}
                    </div>
                    <div className="col-12 col-md-6 profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        Country:
                      </Form.Label>
                      <select
                        disabled
                        required
                        controlid="validationCustom02"
                        className="shadow-none form-select"
                        name="country"
                        aria-label="Default select example"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        onChange={(e) => {
                          setPincode("");
                          setCityId("");
                          setCities([]);
                          setCountryId(e.target.value);
                        }}
                        value={countryId}
                      >
                        <option key="selectCountry" value="">
                          Select
                        </option>

                        {countries &&
                          countries.map((v) => (
                            <option
                              key={v.country_id}
                              value={"" + v.country_id}
                            >
                              {v.country_name}
                            </option>
                          ))}
                      </select>
                      {simpleValidator.current.message(
                        "country",
                        countryId,
                        "required"
                      )}
                    </div>
                    <div className="col-12 col-md-6 profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        State:
                      </Form.Label>
                      <select
                        disabled={userstate}
                        required
                        controlid="validationCustom02"
                        className="shadow-none form-select"
                        name="state"
                        aria-label="Default select example"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        onChange={(e) => {
                          setStateId(e.target.value);
                        }}
                        value={"" + stateId}
                      >
                        <option key="selectState" value="">
                          Select
                        </option>
                        {statesList &&
                          statesList.map((state) => (
                            <option
                              key={state.state_id}
                              value={"" + state.state_id}
                            >
                              {state.state_name}
                            </option>
                          ))}
                      </select>
                      {simpleValidator.current.message(
                        "state",
                        stateId,
                        "required",
                        { messages: { required: "Please select a state." } }
                      )}
                    </div>
                    <div className=" col-12 col-md-6 profile-space-1">
                      <div className=" ">
                        <Form.Label className="LabelName" htmlFor="inputText">
                          City:
                        </Form.Label>
                        <select
                          disabled={usercity}
                          required
                          controlid="validationCustom02"
                          className="shadow-none form-select"
                          name="city"
                          aria-label="Default select example"
                          style={{
                            borderRadius: "10px",
                            height: "2.5rem",
                            outline: "none",
                          }}
                          value={"" + cityId}
                          onChange={(e) => {
                            setCityId("" + e.target.value);
                          }}
                        >
                          <option key="selectCity" value="">
                            Select
                          </option>
                          {cities &&
                            cities.map((city) => (
                              <option key={city.city_id} value={city.city_id}>
                                {city.city_name}
                              </option>
                            ))}
                        </select>
                        {simpleValidator.current.message(
                          "city",
                          cityId,
                          "required",
                          { messages: { required: "Please select a city." } }
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="ProfileHr" />
                <div className="pt-2">
                  <FintooButton
                    type="submit"
                    className="d-block me-0 ms-auto"
                    title="Next"
                    onSubmit={handleSubmit}
                  />
                </div>
              </Form>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </Col>
    </Row>
  );
}

export default AadharAddress;
