import React, { useEffect, useRef } from "react";
import Fullpage from "../../components/Layout/Fullpage";
import ClientTestimonial from "../../components/HTML/ClientTestimonial";
import OurAchievements from "../../components/HTML/OurAchievements";
import VideosSection from "../../components/HTML/VideosSection";
import BannerSection from "./BannerSection";
import FeatureScroll from "./FeatureScroll";
import PortfolioReviewSection from "./PortfolioReviewSection";
import UAEFooter from "../../components/HTML/Footer/UAEFooter";
import HideFooter from "../../components/HideFooter";
import HideHeader from "../../components/HideHeader";
import MFSnippetFooter from "../../components/HTML/Footer/MFSnippetFooter";

const MFSnippet = () => {

    const targetRef = useRef(null);
    const targetRef2 = useRef(null);

    const scrollToForm = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const scrollToNextSection = () => {
        if (targetRef2.current) {
            targetRef2.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        const loadGtagScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11313015202";
                script.async = true;
                script.onload = () => resolve();

                script.onerror = (err) => reject(err);
                document.head.appendChild(script);
            });
        }

        loadGtagScript().then(() => {
            console.log("gtag script loaded successfully.");
        }).catch((error) => { console.error("Error loading gtag script:", error) });;

    }, [])

    return (
        <Fullpage>
            <HideFooter />
            <HideHeader />
            <BannerSection scrollToForm={scrollToForm} scrollToNextSection={scrollToNextSection} />
            <section ref={targetRef2}>
                <FeatureScroll scrollToForm={scrollToForm} />
            </section>
            <section ref={targetRef} >
                <PortfolioReviewSection />
            </section>
            {/* <OurAchievements isMFSnippet={true} /> */}
            <VideosSection />
            <ClientTestimonial />
            <MFSnippetFooter />
        </Fullpage>
    );
}

export default MFSnippet;
