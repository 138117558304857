import styles from "./style.module.css";
const LeftSection = (props) => {
  return (
    <>
      <h3 className="header-section-bold-title">
        Why Just Save? <br />
        Let Your Wealth Flourish!
        {/* Guiding You To Invest In A Financially Independent & Stress Free Future. */}
      </h3>
    </>
  );
};
export default LeftSection;
