import React, { useState, useRef } from "react";
import { useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import QuizeRadio from "../../components/HTML/QuizRadio";
import { GrEdit } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import moment from "moment";
import ReactDatePicker from "../../components/HTML/ReactDatePicker/ReactDatePicker";
import Switch from "react-switch";
// import Slider from "react-input-slider";
// import Slider from "rc-slider";
import FintooRadio2 from "../../components/FintooRadio2";
import { BsPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import DatagatherLayout from "../../components/Layout/Datagather";
import Select from "react-select";
import { Row, Modal } from "react-bootstrap";
import Slider from "../../components/HTML/Slider";
import { Link, useLocation } from "react-router-dom";
import FintooCheckbox from "../../components/FintooCheckbox/FintooSubCheckbox";
import axios from "axios";
import { BASE_API_URL, CHECK_SESSION, GET_MEMBER_LIST, ADVISORY_UPDATE_RETIREMENT_DATE_API_URL, imagePath, ADVISORY_CHECK_PROFILE_STATUS_API_URL } from "../../constants";
import {
  getParentUserId,
  getItemLocal,
  apiCall,
  loginRedirectGuest,
  indianRupeeFormat,
  formatPrice, setBackgroundDivImage, getParentFpLogId
} from "../../common_utilities";
import commonEncode from "../../commonEncode";
import SimpleReactValidator from "simple-react-validator";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import DGstyles from "./DG.module.css";
import { useDispatch } from "react-redux";
import FormRangeSlider from "../DMF/CommonDashboard/CommonDashboardComponents/FormRangeSlider";
import FintooLoader from '../../components/FintooLoader';
import customStyles from "../../components/CustomStyles";
import { ScrollToTop } from "./ScrollToTop"
const IncomeExpense = () => {
  const dispatch = useDispatch();
  const numbers = [1, 2, 3];
  const expense_options = [
    {
      id: 17,
      title: "Club Membership and Subscriptions",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_club_membership.svg",
    },
    {
      id: 104,
      title: "Vacation",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_vacation.svg",
    },
    {
      id: 18,
      title: "Education Expense",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_school_fees.svg",
    },
    {
      id: 12,
      title: "Gifts",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_gifts.svg",
    },
    {
      id: 19,
      title: "Hotel, Leisure and Entertainment",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_hotel_entertainment.svg",
    },
    {
      id: 14,
      title: "Household Expenses",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_house_expense.svg",
    },
    {
      id: 15,
      title: "House Rent",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_house_rent.svg",
    },
    {
      id: 20,
      title: "Medical",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_medical.svg",
    },
    {
      id: 121,
      title: "Post Retirement Expense",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_post_retirement.svg",
    },
    {
      id: 7,
      title: "Other",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_other.svg",
    },
  ];

  const income_options = [
    {
      id: 10,
      title: "Business",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/income_business.svg",
    },
    {
      id: 12,
      title: "Gifts",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_gifts.svg",
    },
    {
      id: 9,
      title: "Interest Income",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/income_post_office_MIS.svg",
    },
    {
      id: 13,
      title: "Pension",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/income_pension.svg",
    },
    {
      id: 11,
      title: "Rental",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/income_rental.svg",
    },
    {
      id: 8,

      title: "Salary & Bonus",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/income_salary_bonus.svg",
    },
    {
      id: 7,

      title: "Other",
      image: imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expenses_other.svg",
    },
  ];

  const [tab, setTab] = useState("tab1");
  const [dependencyStatus, setDependencyStatus] = useState("");
  const [dob, setDob] = useState(null);
  const [isFixed, setIsFixed] = useState(false);
  const [addForm, setAddForm] = useState(true);
  const [updateForm, setUpdateForm] = useState(false);
  const [isWish, setIsWish] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [incomeAnnualIncrease, setIncomeAnnualIncrease] = useState(10);
  const [selectedOption, setSelectedOption] = useState("Business");
  const [selectedOption1, setSelectedOption1] = useState(
    "Club Membership and Subscriptions"
  );
  const [incomeFrequencyChange, setIncomeFrequencyChange] = useState(0);
  const [expenseFrequencyChange, setExpenseFrequencyChange] = useState(0);
  const cntRef = useRef(null);
  const [isRecurring, setIsRecurring] = useState(true);
  const [selectedEndAge, setselectedEndAge] = useState(0);
  const [sliderValue, setSliderValue] = useState(10);
  const [incomeData, setIncomeData] = useState([]);
  const fplogid = getParentFpLogId();
  const [incomeType, setIncomeType] = useState("1");
  const [incomeFrequency, setIncomeFrequency] = useState("1");
  const [incomeCategoryId, setIncomeCategoryId] = useState(10);
  const [incomeIsFixed, setIncomeIsFixed] = useState(false);
  const [incomeForRetirement, setIncomeForRetirement] = useState(false);
  const [incomeAfterRetirement, setIncomeAfterRetirement] = useState(null);
  const [incomeStartDate, setIncomeStartDate] = useState(new Date());
  const [incomeConditionDate, setIncomeConditionDate] = useState(new Date());
  const [expenseConditionDate, setExpenseConditionDate] = useState(new Date());
  const [incomeEndDate, setIncomeEndDate] = useState(null);
  const [incFrequency, setIncFrequency] = useState(1);
  const [expFrequency, setExpFrequency] = useState(1);
  const [incomeName, setIncomeName] = useState("Business");

  const [familyData, setFamilyData] = useState([]);
  const [incomeForMember, setIncomeForMember] = useState("");
  const [retirementDate, setRetirementDate] = useState("");
  const [lifeExpectancyDate, setLifeExpectancyDate] = useState("");
  const [incomeAmount, setIncomeAmount] = useState("");
  const [incomeFootnote, setIncomeFootnote] = useState("");
  const [incomeAmountError, setIncomeAmountError] = useState("");
  const [selectedIncomeId, setSelectedIncomeId] = useState("");
  const [deleteIncomeName, setDeleteIncomeName] = useState("");
  const [incomeId, setIncomeId] = useState("");
  const [totalIncome, setTotalIncome] = useState(0);
  const [incomeNameError, setIncomeNameError] = useState("");
  const [lifecycleStatus, setLifecycleStatus] = useState("");
  const [recurringTotal, setRecurringTotal] = useState("");
  const [incomeTypeError, setIncomeTypeError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [planDate, setPlanDate] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [show, setShow] = useState(false);
  const [recurFlag, setRecurFlag] = useState("");

  const [expenseFrequency, setExpenseFrequency] = useState("1");
  const [isExpenseRecurring, setExpenseRecurring] = useState(true);
  const [expenseData, setExpenseData] = useState("");
  const [expenseCategoryId, setExpenseCategoryId] = useState(17);
  const [expenseIsFixed, setExpenseFixed] = useState(false);
  const [expenseForRetirement, setExpenseForRetirement] = useState(false);
  const [expenseAfterRetirement, setExpenseAfterRetirement] = useState(null);
  const [expenseStartDate, setExpenseStartDate] = useState(new Date());
  const [expenseEndDate, setExpenseEndDate] = useState(null);
  const [expenseName, setExpenseName] = useState(
    "Club Membership and Subscriptions"
  );
  const [expenseForMember, setExpenseForMember] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseFootnote, setExpenseFootnote] = useState("");
  const [expenseAmountError, setExpenseAmountError] = useState("");
  const [selectedExpenseId, setSelectedExpenseId] = useState("");
  const [deleteExpenseName, setDeleteExpenseName] = useState("");
  const [expenseId, setExpenseId] = useState("");
  const [totalExpense, setTotalExpense] = useState(0);
  const [expenseNameError, setExpenseNameError] = useState("");
  const [expenseEndDateError, setExpenseEndDateError] = useState("");
  const [expenseShow, setExpenseShow] = useState(false);
  const [checkOnlyPostRetirement, setOnlyPostRetirement] = useState(false);
  const [expenseRecurringTotal, setExpenseRecurringTotal] = useState("");
  const [expenseInflationRate, setExpenseInflationRate] = useState(7);
  const [incomeRetirementPercent, setIncomeRetirementPercent] = useState(0);
  const [incomeFrequencyError, setIncomeFrequencyError] = useState("");
  const [expenseFrequencyError, setExpenseFrequencyError] = useState("");
  const [showIncomeView, setShowIncomeView] = useState(true);
  const [showExpenseView, setShowExpenseView] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState([]);
  const [deletetoggle, setDeleteToggle] = useState(false);
  const [deletetoggleexpense, setDeleteToggleexpense] = useState(false);
  const [recurringCheckboxCount, setRecurringCheckboxCount] = useState(false);
  const [recurringExpesneCheckboxCount, setRecurringExpenseCheckboxCount] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [selfData, setSelfData] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const removefixheader = document.querySelector('.d-block .removefixheader');
      const FixdgsubHeader = document.querySelector('.FixdgHeader');
      const FixdgmainHeader = document.querySelector('.DGheaderFix');
      const scrollPosition = window.scrollY;

      if (removefixheader && FixdgsubHeader && FixdgmainHeader) {
        const removefixheaderRect = removefixheader.getBoundingClientRect(); { }
        const threshold = 70;
        if (scrollPosition > 50) {
          setScroll(true)
          FixdgsubHeader.classList.add("DGsubheaderFix");
          if (removefixheaderRect.top <= threshold) {
            FixdgmainHeader.classList.remove("DGmainHeaderFix");
          }
          else {
            FixdgmainHeader.classList.add("DGmainHeaderFix");
          }
        } else {
          setScroll(false);
          FixdgsubHeader.classList.remove("DGsubheaderFix");
          FixdgmainHeader.classList.remove("DGmainHeaderFix");
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

    };
  }, []);

  const handleClose = (type, form_type) => {
    if (form_type == "income") {
      if (type == "yes") {
        deleteIncome(selectedIncomeId, deleteIncomeName);
        setShow(false);
      } else {
        setShow(false);
      }
    } else {
      if (type == "yes") {
        deleteExpense(selectedExpenseId, deleteExpenseName);
        setShow(false);
      } else {
        setExpenseShow(false);
        setShow(false);
      }
    }
  };
  const handleShow = () => setShow(true);
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    document.body.classList.add("dg-layout");
    document.getElementById('bg-incomeexpense').style.background = 'url(' + imagePath +
      'https://static.fintoo.in/static/assets/img/bg/income-expenses.svg)' + " no-repeat right top";

    setBackgroundDivImage();

    if (sessionStorage.getItem('showIncomeToast') == "1") {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error('In Income & Expenses section in "Income" Do add Self/Spouse\'s Recurring Income');
      sessionStorage.removeItem("showIncomeToast");
    }

    return () => {
      document.body.classList.remove("dg-layout");
      // document.body.classList.remove("rp-layout");
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setCurrentUrl(location.pathname);
    }, 100);
  }, [location]);
  const handleExpenseDeletePopup = () => setExpenseShow(true);
  useEffect(() => {
    document.body.classList.add("dg-layout");
    return () => {
      document.body.classList.remove("rp-layout");
    };
  }, []);

  useEffect(() => {
    getMemberList();
  }, []);

  // useEffect(() => {
  //   setCurrentUrl(location.pathname);
  // }, [location]);

  // useEffect(() => {
  //   const addMonths = (date, months) => {
  //     date.setMonth(date.getMonth() + months);
  //   };
  //   const addYears = (date, years) => {
  //     date.setFullYear(date.getFullYear() + years);
  //   };
  //   const newDate = new Date(incomeStartDate);
  //   switch (Number(incomeFrequency)) {
  //     case 2:
  //       addMonths(newDate, 3);
  //       break;
  //     case 3:
  //       addMonths(newDate, 6);
  //       break;
  //     case 4:
  //       addYears(newDate, 1);
  //       break;
  //     case 1:
  //     default:
  //       addMonths(newDate, 1);
  //   }
  //   newDate.setDate(newDate.getDate() + 1);
  //   setIncomeConditionDate(newDate);


  // }, [incomeEndDate]);

  // useEffect(() => {
  //   const addMonths = (date, months) => {
  //     date.setMonth(date.getMonth() + months);
  //   };
  //   const addYears = (date, years) => {
  //     date.setFullYear(date.getFullYear() + years);
  //   };
  //   const newDate = new Date(incomeStartDate);
  //   switch (Number(incomeFrequency)) {
  //     case 2:
  //       addMonths(newDate, 3);
  //       break;
  //     case 3:
  //       addMonths(newDate, 6);
  //       break;
  //     case 4:
  //       addYears(newDate, 1);
  //       break;
  //     case 1:
  //     default:
  //       addMonths(newDate, 1);
  //   }
  //   newDate.setDate(newDate.getDate() + 1);
  //   setIncomeEndDate(newDate);
  // }, [incomeStartDate]);

  // useEffect(() => {
  //   const addMonths = (date, months) => {
  //     date.setMonth(date.getMonth() + months);
  //   };
  //   const addYears = (date, years) => {
  //     date.setFullYear(date.getFullYear() + years);
  //   };
  //   const newDate = new Date(expenseStartDate);
  //   switch (Number(expenseFrequency)) {
  //     case 2:
  //       addMonths(newDate, 3);
  //       break;
  //     case 3:
  //       addMonths(newDate, 6);
  //       break;
  //     case 4:
  //       addYears(newDate, 1);
  //       break;
  //     case 5:
  //       addYears(newDate, 2);
  //       break;
  //     case 6:
  //       addYears(newDate, 3);
  //       break;
  //     case 7:
  //       addYears(newDate, 4);
  //       break;
  //     case 8:
  //       addYears(newDate, 5);
  //       break;
  //     case 1:
  //     default:
  //       addMonths(newDate, 1);
  //   }
  //   newDate.setDate(newDate.getDate() + 1);
  //   setExpenseConditionDate(newDate);
  // }, [expenseEndDate]);

  // useEffect(() => {
  //   const addMonths = (date, months) => {
  //     date.setMonth(date.getMonth() + months);
  //   };
  //   const addYears = (date, years) => {
  //     date.setFullYear(date.getFullYear() + years);
  //   };
  //   const newDate = new Date(expenseStartDate);
  //   switch (Number(expenseFrequency)) {
  //     case 2:
  //       addMonths(newDate, 3);
  //       break;
  //     case 3:
  //       addMonths(newDate, 6);
  //       break;
  //     case 4:
  //       addYears(newDate, 1);
  //       break;
  //     case 5:
  //       addYears(newDate, 2);
  //       break;
  //     case 6:
  //       addYears(newDate, 3);
  //       break;
  //     case 7:
  //       addYears(newDate, 4);
  //       break;
  //     case 8:
  //       addYears(newDate, 5);
  //       break;
  //     case 1:
  //     default:
  //       addMonths(newDate, 1);
  //   }
  //   newDate.setDate(newDate.getDate() + 1);
  //   setExpenseEndDate(newDate);
  // }, [expenseStartDate]);

  const handleCategoryClick = (current_value, type) => {
    if (type == "income") {
      setIncomeName(current_value.title);
      setIncomeCategoryId(current_value.id);
      setUpdateForm(false);
      setAddForm(true);
      setselectedEndAge(null);
      if (
        current_value.id === 8 ||
        current_value.id === 9 ||
        current_value.id === 10 ||
        current_value.id === 11 ||
        current_value.id === 13
      ) {
        setIsRecurring(true);
        setIncomeAmount("");
        setIncomeAnnualIncrease(10);
        setIncomeEndDate(null);
        setIncomeFrequency("1");
        setIncomeIsFixed(false);
        setIncomeStartDate(new Date());
        setIncomeType("1");
        setIncomeAmountError("");
        setIncomeTypeError("");
        setIncomeNameError("");
        setEndDateError("");
        setIncomeFrequencyError("");
      } else {
        setIsRecurring(false);
        setIncomeAmount("");
        setIncomeAnnualIncrease(10);
        setIncomeEndDate(null);
        setIncomeFrequency("1");
        setIncomeIsFixed(false);
        setIncomeStartDate(new Date());
        setIncomeType("1");
        setIncomeAmountError("");
        setIncomeAmountError("");
        setIncomeTypeError("");
        setIncomeNameError("");
        setEndDateError("");
        setIncomeFrequencyError("");
      }
    } else {
      setExpenseName(current_value.title);
      setExpenseCategoryId(current_value.id);
      setselectedEndAge(null);
      setUpdateForm(false);
      setAddForm(true);

      if (
        current_value.id === 17 ||
        current_value.id === 104 ||
        current_value.id === 18 ||
        current_value.id === 14 ||
        current_value.id === 15 ||
        current_value.id === 20
      ) {
        setExpenseRecurring(true);
        setExpenseAmount("");
        setExpenseInflationRate(7);
        setExpenseEndDate(null);
        setExpenseFrequency("1");
        setExpenseFixed(false);
        setExpenseStartDate(new Date());
        setExpenseAmountError("");
        setExpenseEndDateError("");
        setExpenseNameError("");
        setExpenseFrequencyError("");
      } else {
        setExpenseRecurring(false);
        setExpenseAmount("");
        setExpenseInflationRate(7);
        setExpenseEndDate(null);
        setExpenseFrequency("1");
        setExpenseFixed(false);
        setExpenseStartDate(new Date());
        setExpenseAmountError("");
        setExpenseEndDateError("");
        setExpenseNameError("");
        setExpenseFrequencyError("");

        if (current_value.id == 121) {
          setselectedEndAge(2);
          setExpenseEndDate(lifeExpectancyDate);
          setExpenseStartDate(retirementDate)
        }
      }
    }
  };

  const setDate = (date, dateType, form_type) => {
    if (form_type == "income") {
      if (dateType == "startDate") {
        setIncomeStartDate(date);
      } else {
        setIncomeEndDate(date);
        setselectedEndAge(null);
        let date1 = moment(incomeStartDate)
          .format("DD/MM/YYYY")
          .split("/")
          .reverse()
          .join("/");
        let date2 = moment(date)
          .format("DD/MM/YYYY")
          .split("/")
          .reverse()
          .join("/");
        // if (date1 > date2) {
        //   setEndDateError("End date should be greater than start date");
        // }
        var date3 = moment(
          moment(date1).set("month", moment(date1).month() + 1)
        ).format("YYYY/MM/DD");
        var startDate = moment(date1);
        var endDate = moment(date2);
        var monthLaterDate = moment(date3);

        // if (endDate.isBetween(startDate, monthLaterDate)) {
        //   setEndDateError(
        //     "End date should be atleast one month greater than start date"
        //   );
        // } else {
        //   setEndDateError("");
        // }
      }
    } else {
      if (dateType == "startDate") {
        setExpenseStartDate(date);
      } else {
        setExpenseEndDate(date);
        setselectedEndAge(null);
        let date1 = moment(expenseStartDate)
          .format("DD/MM/YYYY")
          .split("/")
          .reverse()
          .join("/");
        let date2 = moment(date)
          .format("DD/MM/YYYY")
          .split("/")
          .reverse()
          .join("/");
        // if (date1 > date2) {
        //   setExpenseEndDateError("End date should be greater than start date");
        // }
        var date3 = moment(
          moment(date1).set("month", moment(date1).month() + 1)
        ).format("YYYY/MM/DD");
        var startDate = moment(date1);
        var endDate = moment(date2);
        var monthLaterDate = moment(date3);

        // if (endDate.isBetween(startDate, monthLaterDate)) {
        //   setExpenseEndDateError(
        //     "End date should be atleast one month greater than start date"
        //   );
        // } else {
        //   setExpenseEndDateError("");
        // }
      }
    }
  };

  const resetIncomeForm = () => {
    setIncomeAmount("");
    setIncomeAnnualIncrease(10);
    setIncomeCategoryId(10);
    setIncomeEndDate("");
    setIncomeFrequency("1");
    setIncomeIsFixed(false);
    setIsRecurring(true);
    setIncomeName("Business");
    setSelectedOption("Business");
    setIncomeStartDate(new Date());
    setIncomeType("1");
    setIncomeAmountError("");
    setIncomeTypeError("");
    setIncomeNameError("");
    setEndDateError("");
    setselectedEndAge(null);
    setIncomeFrequencyError("");
  };

  const resetExpenseForm = () => {
    setExpenseAmount("");
    setExpenseInflationRate(7);
    setExpenseCategoryId(17);
    setExpenseEndDate("");
    setExpenseFrequency("1");
    setExpenseFixed(false);
    setExpenseRecurring(true);
    setExpenseName("Club Membership and Subscriptions");
    setSelectedOption1("Club Membership and Subscriptions");
    setExpenseStartDate(new Date());
    setExpenseAmountError("");
    setExpenseEndDateError("");
    setExpenseNameError("");
    setselectedEndAge(null);
    setExpenseFrequencyError("");
  };

  const cancelFormData = (e, form_type) => {
    e.preventDefault();
    setUpdateForm(false);
    setAddForm(true);
    ScrollToTop();
    if (form_type == "income") {
      setIncomeAmount("");
      setIncomeAnnualIncrease(10);
      setIncomeCategoryId(10);
      setIncomeEndDate("");
      setIncomeFrequency("1");
      setIncomeIsFixed(false);
      setIsRecurring(true);
      setIncomeName("Business");
      setSelectedOption("Business");
      setIncomeStartDate(new Date());
      setIncomeType("1");
      setIncomeAmountError("");
      setIncomeTypeError("");
      setIncomeNameError("");
      setEndDateError("");
      setselectedEndAge(null);
      setIncomeFrequencyError("");
    } else {
      setExpenseAmount("");
      setExpenseInflationRate(7);
      setExpenseCategoryId(17);
      setExpenseEndDate("");
      setExpenseFrequency("1");
      setExpenseFixed(false);
      setExpenseRecurring(true);
      setExpenseName("Club Membership and Subscriptions");
      setSelectedOption1("Club Membership and Subscriptions");
      setExpenseStartDate(new Date());
      setExpenseAmountError("");
      setExpenseEndDateError("");
      setExpenseNameError("");
      setselectedEndAge(null);
      setExpenseFrequencyError("");
    }
  };

  const handleIncomeMemberEnddate = (member_data) => {
    let retirement_date = ""
    let life_expectancy_date = ""
    if (member_data['isdependent'] == "0") {
      retirement_date = moment(member_data['dob'])
        .add(member_data['retirement_age'], "y")
        .format("MM/DD/YYYY");
      life_expectancy_date = moment(member_data['dob'])
        .add(member_data['life_expectancy'], "y")
        .format("MM/DD/YYYY");
    } else {
      retirement_date = selfData["retirement_date"];
      life_expectancy_date = selfData["life_expectancy_date"]
    }
    setRetirementDate(retirement_date);
    setLifeExpectancyDate(life_expectancy_date);
    if (selectedEndAge == 1) {
      // handleIncomeEndDate(1);
      setIncomeAfterRetirement("0");
      setIncomeEndDate(retirement_date);
      let date1 = "";
      let date2 = new Date(retirement_date.split("/").reverse().join("/"));
      try {
        date1 = new Date(incomeStartDate.split("/").reverse().join("/"));
      } catch (err) {
        date1 = incomeStartDate;
      }
      if (date1 > date2) {
        setEndDateError("End date should be greater than start date");
      }
      var date3 = date2.setMonth(date2.getMonth() + 1);
      if (date1 > date3) {
        setEndDateError(
          "End date should be atleast one month greater than start date"
        );
      }
    } else if (selectedEndAge == 2) {
      // handleIncomeEndDate(2);
      setIncomeAfterRetirement("1");
      setIncomeEndDate(life_expectancy_date);
      let date1 = "";
      let date2 = new Date(life_expectancy_date.split("/").reverse().join("/"));
      try {
        date1 = new Date(incomeStartDate.split("/").reverse().join("/"));
      } catch (err) {
        date1 = incomeStartDate;
      }
      if (date1 > date2) {
        setEndDateError("End date should be greater than start date");
      }
      var date3 = date2.setMonth(date2.getMonth() + 1);
      if (date1 > date3) {
        setEndDateError(
          "End date should be atleast one month greater than start date"
        );
      }
    }
  };

  const handleIncomeEndDate = (incomeEndDateType) => {
    setIncomeTypeError("");
    setEndDateError("");
    if (incomeEndDateType == 1) {
      setIncomeAfterRetirement("0");
      setIncomeEndDate(retirementDate);
      let date1 = "";
      let date2 = new Date(retirementDate.split("/").reverse().join("/"));
      try {
        date1 = new Date(incomeStartDate.split("/").reverse().join("/"));
      } catch (err) {
        date1 = incomeStartDate;
      }
      if (date1 > date2) {
        setEndDateError("End date should be greater than start date");
      }
      var date3 = date2.setMonth(date2.getMonth() + 1);
      if (date1 > date3) {
        setEndDateError(
          "End date should be atleast one month greater than start date"
        );
      }
    }
    if (incomeEndDateType == 2) {
      setIncomeAfterRetirement("1");
      setIncomeEndDate(lifeExpectancyDate);
      let date1 = "";
      let date2 = new Date(retirementDate.split("/").reverse().join("/"));
      try {
        date1 = new Date(incomeStartDate.split("/").reverse().join("/"));
      } catch (err) {
        date1 = incomeStartDate;
      }
      if (date1 > date2) {
        setEndDateError("End date should be greater than start date");
      }
      var date3 = date2.setMonth(date2.getMonth() + 1);
      if (date1 > date3) {
        setEndDateError(
          "End date should be atleast one month greater than start date"
        );
      }
    }
  };

  const handleExpenseMemberEnddate = (member_data) => {
    let retirement_date = ""
    let life_expectancy_date = ""
    if (member_data['isdependent'] == "0") {
      retirement_date = moment(member_data['dob'])
        .add(member_data['retirement_age'], "y")
        .format("MM/DD/YYYY");
      life_expectancy_date = moment(member_data['dob'])
        .add(member_data['life_expectancy'], "y")
        .format("MM/DD/YYYY");
    } else {
      // setRetirementDate(selfData["retirement_date"]);
      // setLifeExpectancyDate(selfData["life_expectancy_date"]);
      retirement_date = selfData["retirement_date"];
      life_expectancy_date = selfData["life_expectancy_date"]
    }
    setRetirementDate(retirement_date);
    setLifeExpectancyDate(life_expectancy_date);
    if (selectedEndAge == 1) {
      setExpenseAfterRetirement("0");
      setExpenseEndDate(retirement_date);
      let date1 = "";
      let date2 = new Date(retirement_date.split("/").reverse().join("/"));
      try {
        date1 = new Date(expenseStartDate.split("/").reverse().join("/"));
      } catch (err) {
        date1 = expenseStartDate;
      }
      if (date1 > date2) {
        setEndDateError("End date should be greater than start date");
      }
      var date3 = date2.setMonth(date2.getMonth() + 1);
      if (date1 > date3) {
        setEndDateError(
          "End date should be atleast one month greater than start date"
        );
      }
    } else if (selectedEndAge == 2) {
      setExpenseAfterRetirement("1");
      setExpenseEndDate(life_expectancy_date);
      let date1 = "";
      let date2 = new Date(life_expectancy_date.split("/").reverse().join("/"));
      try {
        date1 = new Date(expenseStartDate.split("/").reverse().join("/"));
      } catch (err) {
        date1 = expenseStartDate;
      }
      if (date1 > date2) {
        setEndDateError("End date should be greater than start date");
      }
      var date3 = date2.setMonth(date2.getMonth() + 1);
      if (date1 > date3) {
        setEndDateError(
          "End date should be atleast one month greater than start date"
        );
      }
    }
  };

  const handleExpenseEndDate = (expenseEndDateType) => {
    setExpenseEndDateError("");

    if (expenseEndDateType == 1) {
      setExpenseAfterRetirement("0");
      setExpenseEndDate(retirementDate);
      let date1 = "";
      let date2 = new Date(retirementDate.split("/").reverse().join("/"));
      try {
        date1 = new Date(expenseStartDate.split("/").reverse().join("/"));
      } catch (err) {
        date1 = expenseStartDate;
      }
      if (date1 > date2) {
        setEndDateError("End date should be greater than start date");
      }
      var date3 = date2.setMonth(date2.getMonth() + 1);
      if (date1 > date3) {
        setEndDateError(
          "End date should be atleast one month greater than start date"
        );
      }
    }
    if (expenseEndDateType == 2) {
      setExpenseAfterRetirement("1");
      setExpenseEndDate(lifeExpectancyDate);
      let date1 = "";
      let date2 = new Date(lifeExpectancyDate.split("/").reverse().join("/"));
      try {
        date1 = new Date(expenseStartDate.split("/").reverse().join("/"));
      } catch (err) {
        date1 = expenseStartDate;
      }
      if (date1 > date2) {
        setEndDateError("End date should be greater than start date");
      }
      var date3 = date2.setMonth(date2.getMonth() + 1);
      if (date1 > date3) {
        setEndDateError(
          "End date should be atleast one month greater than start date"
        );
      }
    }
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  // const getDefaultROR=async()=>{
  //   try{
  //   var config={
  //     url:BASE_API_URL+"restapi/getdefultrorApi/?web=1",
  //     method:"GET",
  //   }
  //   var res=await axios(config)
  //   if(res){
  //     var response=JSON.parse(commonEncode.decrypt(res.data));
  //     if(response.error_code=="100"){
  //       setIncomeDefaultROR(response.data.ror_data)
  //     }

  //   }
  //   }
  //   catch(e){
  //     console.log('e',e);
  //   }
  // }
  const handleIncomeType = (incometype) => {
    if (incometype == "2") {
      setIncomeType("2");
      setIncomeFrequency("4");
    } else {
      setIncomeType("1");

      setIncomeFrequency("1");
    }
  };

  useEffect(() => {
    if (incomeData) {
      let count = 0;
      incomeData.forEach(entry => {
        if (selectedCategories.includes(entry.id)) {
          if (entry.income_isRecurring === true) {
            count++;
          }
        }
      });
      setRecurringCheckboxCount(count)
    }
  }, [selectedCategories]);

  const deleteIncome = async (id, name) => {
    // if (id != "") {
    var payload = {
      income_id: selectedCategories,
      user_id: getParentUserId(),
      fp_log_id: fplogid,
      income_forRetirement: "0",
    };
    var payload = commonEncode.encrypt(JSON.stringify(payload));
    var res = await apiCall(
      BASE_API_URL + "/restapi/removeincomedata/",
      payload,
      false,
      false
    );
    let decoded_res = JSON.parse(commonEncode.decrypt(res));
    if (decoded_res) {
      if (decoded_res.error_code == "100") {
        toastr.options.positionClass = "toast-bottom-left";
        // toastr.success(name + " Data Deleted Successfully");
        toastr.success(" Data Deleted Successfully");
        dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        getIncomeData("", 0);
        resetIncomeForm();
        setSelectedCategories([]);
        setShow(false);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(decoded_res.data);
        setShow(false);
      }
    }
    // }
  };

  useEffect(() => {
    if (isRecurring == false) {
      const currentDate = new Date();
      setIncomeStartDate(currentDate);
    }
  }, [isRecurring]);

  useEffect(() => {
    if (isExpenseRecurring == false) {
      const currentDate = new Date();
      if (expenseCategoryId == 121) {
        setExpenseStartDate(retirementDate);
      } else {
        setExpenseStartDate(currentDate);
      }
    }
  }, [isExpenseRecurring]);

  const checkprofile = async (sessionData) => {
    try {
      let api_data = {
        user_id: sessionData['data']["id"],
        fp_log_id: sessionData['data']['user_details']["fp_log_id"],
        web: 1,
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var res = await apiCall(
        ADVISORY_CHECK_PROFILE_STATUS_API_URL,
        payload_data,
        false,
        false
      );
      let decoded_res = JSON.parse(commonEncode.decrypt(res));
      if (decoded_res['error_code'] == '100') {
        dispatch({ type: "UPDATE_PROFILE", payload: decoded_res['data'] });
        const profile_completed_mapping = {
          17: 117.496,
          50: 70.4973,
          67: 46.9982,
          83: 23.4991,
          100: 0
        };

        const profile_completed = decoded_res['data'][13]['profile_completed'] === 66 ? 67 : decoded_res['data'][13]['profile_completed'];
        const sectionIdsToCheck = [1, 3, 5, 6, 7, 8];
        const allConditionsMet = sectionIdsToCheck.every((sectionId) => {
          const matchingEntry = decoded_res["data"].find(
            (entry) => entry.section_id === sectionId
          );
          return matchingEntry && matchingEntry.total > 0;
        });

        const sectionIdsToCheckk = [1, 3];
        const allConditionsMett = sectionIdsToCheckk.every((sectionId) => {
          const matchingEntryy = decoded_res["data"].find(
            (entry) => entry.section_id === sectionId
          );
          return matchingEntryy && matchingEntryy.total > 0;
        });

        let newNumber;
        if (allConditionsMet) {
          newNumber = "1";
        } else {
          newNumber = "0";
        }

        let newNumberr;
        if (allConditionsMett) {
          newNumberr = "1";
        } else {
          newNumberr = "0";
        }
        const sectionTextMap = {
          1: "About You",
          3: "About You",
          5: "Goals",
          6: "Income & Expenses",
          7: "Income & Expenses",
          8: "Assets & Liabilities",
        };

        const filteredData = decoded_res["data"].filter((item) =>
          [1, 3, 5, 6, 7, 8].includes(item.section_id)
        );

        const sectionsWithTotalZeroTextArray = filteredData
          .filter((item) => item.total === 0)
          .map((item) => sectionTextMap[item.section_id]);

        const uniqueSectionsWithTotalZeroTextArray = [
          ...new Set(sectionsWithTotalZeroTextArray),
        ];
        const sectionsWithTotalZeroText =
          uniqueSectionsWithTotalZeroTextArray.join(", ");

        if (
          uniqueSectionsWithTotalZeroTextArray.includes("About You") &&
          uniqueSectionsWithTotalZeroTextArray.includes("Income & Expenses") &&
          uniqueSectionsWithTotalZeroTextArray.includes("Assets & Liabilities")
        ) {
          sessionStorage.setItem('showAboutYouToast', '1')
          window.location.href = process.env.PUBLIC_URL + "/datagathering/about-you";

        } else if (uniqueSectionsWithTotalZeroTextArray.includes("About You")) {
          sessionStorage.setItem('showAboutYouToast', '1')
          window.location.href = process.env.PUBLIC_URL + "/datagathering/about-you";

        }
      }
    } catch (e) {
      console.error("error ----> ", e);
    }
  };

  useEffect(() => {
    if (expenseData) {
      let count = 0;
      expenseData.forEach(entry => {
        if (selectedExpenseCategories.includes(entry.id)) {
          if (entry.expenses_category_id !== "121" && entry.expenses_isRecurring === true) {
            count++;
          }
        }
      });
      setRecurringExpenseCheckboxCount(count)
    }
  }, [selectedExpenseCategories]);

  const deleteExpense = async (id, name) => {
    // if (id != "") {
    var payload = {
      expense_id: selectedExpenseCategories,
      user_id: getParentUserId(),
      fp_log_id: fplogid,
      expenses_forretirement: false,
    };
    var payload = commonEncode.encrypt(JSON.stringify(payload));
    var res = await apiCall(
      BASE_API_URL + "/restapi/removeexpensesdata/",
      payload,
      false,
      false
    );
    let decoded_res = JSON.parse(commonEncode.decrypt(res));
    if (decoded_res) {
      if (decoded_res.error_code == "100") {
        toastr.options.positionClass = "toast-bottom-left";
        // toastr.success(name + " Data Deleted Successfully");
        toastr.success(" Data Deleted Successfully");
        dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        getExpenseData("", 0);
        resetExpenseForm();
        setSelectedExpenseCategories([]);
        setExpenseShow(false);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error(decoded_res.data);
        setExpenseShow(false);
      }
    }
    // }
  };
  const getMemberList = async () => {

    try {
      // setIsLoading(true)

      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };

      let session_data = await apiCall(url, data, true, false);
      try {
        var retire_data = {
          fp_log_id: getParentFpLogId(),
          fp_user_id: getParentUserId(),
        }
        // setFpLogId(session_data["data"]["user_details"]["fp_log_id"])

        // setFpLogId(session_data["data"]["user_details"]["fp_log_id"])
        // var payload_retire_data = commonEncode.encrypt(JSON.stringify(retire_data));
        // var config_ret = await apiCall(
        //   ADVISORY_UPDATE_RETIREMENT_DATE_API_URL,
        //   payload_retire_data,
        //   false,
        //   false
        // );
        // var res_ret = JSON.parse(commonEncode.decrypt(config_ret));
        // if (res_ret.error_code == "100") {
        if (session_data) {
          var retirement_date = moment(session_data["data"]["user_details"]['dob'])
            .add(session_data["data"]["user_details"]['retirement_age'], "y")
            .format("MM/DD/YYYY");
          var life_expectancy_date = moment(session_data["data"]["user_details"]['dob'])
            .add(session_data["data"]["user_details"]['life_expectancy'], "y")
            .format("MM/DD/YYYY");

          setRetirementDate(retirement_date);
          setLifeExpectancyDate(life_expectancy_date);
          setSelfData({ 'retirement_date': retirement_date, 'life_expectancy_date': life_expectancy_date })
        }
        // }
      }
      catch (e) {
        console.log(e);
      }
      if (session_data.error_code == "100") {
        // setIsLoading(false)
        checkprofile(session_data);
        // setFpLogId(session_data.data.fp_log_id);
        // var retirement_date = moment(session_data.data.user_details.dob)
        //   .add(session_data.data.user_details.retirement_age, "y")
        //   .format("MM/DD/YYYY");
        // var life_expectancy_date = moment(session_data.data.user_details.dob)
        //   .add(session_data.data.user_details.life_expectancy, "y")
        //   .format("MM/DD/YYYY");
        // setRetirementDate(retirement_date);
        // setLifeExpectancyDate(life_expectancy_date);
        setPlanDate(session_data.data.plan_date);
        if (fplogid) {
          getFamilyMembers();
          var payload = {
            user_id: getParentUserId(),
            fp_log_id: fplogid,
            income_forretirement: "0",
          };
          setIsDataLoading(true);
          var payload = commonEncode.encrypt(JSON.stringify(payload));
          var res = await apiCall(
            BASE_API_URL + "/restapi/getincomedata/",
            payload,
            false,
            false
          );
          // setIsLoading(false);
          await new Promise((resolve, reject) => setTimeout(resolve, 2000));
          setIsDataLoading(false);
          let decoded_res = JSON.parse(commonEncode.decrypt(res));
          if (decoded_res.data.length == 1) {
            setRecurFlag(decoded_res.data[0]["income_isRecurring"]);
          }
          if (decoded_res["error_code"] == "100") {
            setIncomeData(decoded_res.data);
            var totalIncome = decoded_res.yearly_income.income;
            if (decoded_res.data.length > 0) {
              setTotalIncome(decoded_res.yearly_income.income);
            }
            setLifecycleStatus(decoded_res.lifecycle_status);
            if (decoded_res.lifecycle_status == 2) {
              const recurringCount = decoded_res.data.filter(
                (item) => item.income_isRecurring
              ).length;
              setRecurringTotal(recurringCount);
            }
          }
          var payload2 = {
            user_id: getParentUserId(),
            fp_log_id: getParentFpLogId(),
            expenses_forretirement: "0",
          };
          var payload2 = commonEncode.encrypt(JSON.stringify(payload2));

          var res2 = await apiCall(
            BASE_API_URL + "/restapi/getexpensesdata/",
            payload2,
            false,
            false
          );
          let decoded_res2 = JSON.parse(commonEncode.decrypt(res2));
          if (decoded_res2["error_code"] == 100) {
            // setIsLoading(false);
            setExpenseData(decoded_res2.data);
            if (decoded_res2.data.length > 0) {
              setTotalExpense(decoded_res2.yearly_expense.expense);
            } else {
              setTotalExpense(0);
            }
            var totalExpense = decoded_res2.yearly_expense.expense;
            let incomeRetirementPercent =
              ((totalIncome - totalExpense) / totalIncome) * 100;
            setIncomeRetirementPercent(incomeRetirementPercent);
            setLifecycleStatus(decoded_res2.lifecycle_status);
            if (decoded_res2.lifecycle_status == 2) {
              const expense_recurringCount = decoded_res2.data.filter(
                (item) =>
                  item.expenses_isRecurring &&
                  item.expenses_category_id !== "121"
              ).length;
              setExpenseRecurringTotal(expense_recurringCount);
            }

            var expenses = decoded_res2.data;
            if (expenses.length == 1 && expenses[0]['expenses_category_id'] == "121") {
              setOnlyPostRetirement(true);
            }
          }
        }
      } else {
        // setIsLoading(false)

        loginRedirectGuest();
      }
    } catch (e) {
      console.log("err", e);
    }
  };
  const getFamilyMembers = async () => {
    try {
      let data = {
        user_id: getParentUserId(),
        is_direct: "1",
      };

      let member_data = await apiCall(GET_MEMBER_LIST, data, true, false);
      if (member_data.error_code == "100") {
        var member_array = [];
        var members = member_data["data"];
        members.map((member) => {
          if (member.parent_user_id == 0) {
            member_array.push({ value: member.fp_user_id, label: "Self", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            setIncomeForMember(member.fp_user_id);
            setExpenseForMember(member.fp_user_id);
            member_array.push({ value: 0, label: "Family", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
          } else {
            member_array.push({ value: member.fp_user_id, label: member.NAME, retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
          }
        });
        setFamilyData(member_array);
      } else {
        setFamilyData([]);
      }
    } catch { }
  };

  const frequencyChange = () => {
    if (selectedEndAge !== 1 && selectedEndAge !== 2) {
      // const newDate = new Date(incomeStartDate);
      var d1 = moment(incomeStartDate, "DD/MM/YYYY");
      var d2 = moment(incomeEndDate, "DD/MM/YYYY");
      if (incomeEndDate) {
        switch (Number(incomeFrequency)) {
          case 2:
            if (moment(d2).diff(d1, "months") < 3) {
              setIncomeEndDate(moment(incomeStartDate, "DD/MM/YYYY").add(3, 'month'));
              setIncFrequency(3)
            }
            else {
              setIncFrequency(3)
            }
            break;
          case 3:
            if (moment(d2).diff(d1, "months") < 6) {
              setIncomeEndDate(moment(incomeStartDate, "DD/MM/YYYY").add(6, 'month'));
              setIncFrequency(6)
            }
            else {
              setIncFrequency(6)
            }
            break;
          case 4:
            if (moment(d2).diff(d1, "year") < 1) {
              setIncomeEndDate(moment(incomeStartDate, "DD/MM/YYYY").add(1, 'year'));
              setIncFrequency(12)
            }
            else {
              setIncFrequency(12)
            }
            break;
          case 1:
          default:
            if (moment(d2).diff(d1, "months") < 1) {
              setIncomeEndDate(moment(incomeStartDate, "DD/MM/YYYY").add(1, 'month'));
              setIncFrequency(1)
            }
            else {
              setIncFrequency(1)
            }
            break;
        }
      } else {
        setIncomeEndDate(moment(incomeStartDate, "DD/MM/YYYY").add(1, 'month'));
      }
      // newDate.setDate(newDate.getDate() + 1);
      // setIncomeEndDate(newDate);
    }
  };

  const expFrequencyChange = () => {
    if (selectedEndAge !== 1 && selectedEndAge !== 2) {
      // const newDate = new Date(incomeStartDate);
      var d1 = moment(expenseStartDate, "DD/MM/YYYY");
      var d2 = moment(expenseEndDate, "DD/MM/YYYY");
      if (incomeEndDate) {
        switch (Number(expenseFrequency)) {
          case 2:
            if (moment(d2).diff(d1, "months") < 3) {
              setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(3, 'month'));
              setExpFrequency(3)
            }
            else {
              setExpFrequency(3)
            }
            break;
          case 3:
            if (moment(d2).diff(d1, "months") < 6) {
              setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(6, 'month'));
              setExpFrequency(6)
            }
            else {
              setExpFrequency(6)
            }
            break;
          case 4:
            if (moment(d2).diff(d1, "year") < 1) {
              setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(1, 'year'));
              setExpFrequency(12)
            }
            else {
              setExpFrequency(12)
            }
            break;
          case 5:
            if (moment(d2).diff(d1, "year") < 2) {
              setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(2, 'year'));
              setExpFrequency(24)
            }
            else {
              setExpFrequency(24)
            }
            break;
          case 6:
            if (moment(d2).diff(d1, "year") < 3) {
              setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(3, 'year'));
              setExpFrequency(36)
            }
            else {
              setExpFrequency(36)
            }
            break;
          case 7:
            if (moment(d2).diff(d1, "year") < 4) {
              setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(4, 'year'));
              setExpFrequency(48)
            }
            else {
              setExpFrequency(48)
            }
            break;
          case 8:
            if (moment(d2).diff(d1, "year") < 5) {
              setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(5, 'year'));
              setExpFrequency(60)
            }
            else {
              setExpFrequency(60)
            }
            break;
          case 1:
          default:
            if (moment(d2).diff(d1, "months") < 1) {
              setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(1, 'month'));
              setExpFrequency(1)
            }
            else {
              setExpFrequency(1)
            }
            break;
        }
      } else {
        setExpenseEndDate(moment(expenseStartDate, "DD/MM/YYYY").add(1, 'month'));
      }
      // newDate.setDate(newDate.getDate() + 1);
      // setIncomeEndDate(newDate);
    }
  };

  const addMonths = (date, months) => {
    date.setMonth(date.getMonth() + months);
  };

  const addYears = (date, years) => {
    date.setFullYear(date.getFullYear() + years);
  };

  useEffect(() => {
    frequencyChange();
  }, [incomeFrequencyChange, incomeEndDate]);

  useEffect(() => {
    expFrequencyChange();
  }, [expenseFrequencyChange, expenseEndDate]);

  const checkIncomeAmount = (incomeInput) => {
    const value = incomeInput.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.');
    setIncomeAmount(value);

    if (value) {
      if (parseInt(value) < 1) {
        setIncomeAmountError("Please enter net income value greater than 1.");
      } else {
        if (/^(\d+|\d*\.\d{1,2})$/.test(value)) {
          setIncomeAmountError("");
        } else {
          setIncomeAmountError("Please enter valid net income value");
        }
      }
    } else {
      setIncomeAmountError("Please enter net income value");
    }
  };
  const checkExpenseAmount = (expenseInput) => {
    const value = expenseInput.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.');
    setExpenseAmount(value);

    if (value) {
      if (parseInt(value) < 1) {
        setExpenseAmountError(
          "Please enter a value greater than or equal to 1."
        );
      } else {
        if (/^(\d+|\d*\.\d{1,2})$/.test(value)) {
          setExpenseAmountError("");
        } else {
          setExpenseAmountError("Please enter valid expense value");
        }
      }
    } else {
      setExpenseAmountError("Please enter expense value");
    }
  };

  const getIncomeData = async (id, check_first_save = 0) => {
    if (id != "") {
      if (fplogid) {
        var payload = {
          id: id,
        };

        var payload = commonEncode.encrypt(JSON.stringify(payload));
        var res = await apiCall(
          BASE_API_URL + "/restapi/getincomedata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res.data.length == 1) {
          setRecurFlag(decoded_res.data[0]["income_isRecurring"]);
        }
        if (decoded_res) {
          var income = decoded_res.data[0];
          setIsLoading(false);

          var category_name = income_options.filter((inc) => {
            return inc.id === parseInt(income.income_category_id);
          });
          toastr.options.positionClass = "toast-bottom-left";
          toastr.success(
            "You can now edit details for " +
            category_name[0].title +
            " - " +
            income.income_name
          );

          var currentDate = new Date();
          var incomeStartDateString = income.income_start_date;
          var [day, month, year] = incomeStartDateString.split('/');
          var incomeStartDateObj = new Date(year, month - 1, day);
          if (currentDate > incomeStartDateObj) {
            var currentDateString = currentDate.getDate().toString().padStart(2, '0') + '/' +
              (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
              currentDate.getFullYear().toString();
            income.income_start_date = currentDateString;
          }

          var incomeStartDateChanged =
            income.income_start_date.substr(3, 2) +
            "/" +
            income.income_start_date.substr(0, 2) +
            "/" +
            income.income_start_date.substr(6, 4);

          var incomeEndDateChanged = income.income_end_date
            ? income.income_end_date.substr(3, 2) +
            "/" +
            income.income_end_date.substr(0, 2) +
            "/" +
            income.income_end_date.substr(6, 4)
            : incomeStartDateChanged;

          setIncomeName(income.income_name);
          setIncomeAmount(income.income_amount);
          setIncomeAnnualIncrease(income.income_annual_increase);
          setIncomeCategoryId(parseInt(income.income_category_id));
          setSelectedOption(income.income_category_type);
          setIncomeStartDate(incomeStartDateChanged);
          setIncomeEndDate(incomeEndDateChanged);
          setIncomeForMember(income.income_for_member);
          setIncomeFrequency(income.income_frequency);
          setIncomeIsFixed(income.income_isFixed);
          setIsRecurring(income.income_isRecurring);
          setIncomeType(income.income_type);
          setIncomeAmountError("")
          if (income.income_isRecurring) {
            if (!income.income_isAfterRetirement) {
              setselectedEndAge(null);
            }
            var retirementDateChanged =
              moment(retirementDate).format("DD/MM/YYYY");
            var lifeExpectancyDateChanged =
              moment(lifeExpectancyDate).format("DD/MM/YYYY");
            if (income.income_isAfterRetirement == "1") {
              if (income.income_end_date == lifeExpectancyDateChanged) {
                setselectedEndAge(2);
              } else {
                setselectedEndAge(null);
              }
            }
            if (income.income_isAfterRetirement == "0") {
              if (income.income_end_date == retirementDateChanged) {
                setselectedEndAge(1);
              } else {
                setselectedEndAge(null);
              }
            }
            setIncomeEndDate(incomeEndDateChanged);

            setIncomeFrequency(
              income.income_frequency != "0" ? income.income_frequency : "1"
            );
          } else {
            setIncomeEndDate(null);
            setselectedEndAge(null);
            setIncomeFrequency("1");
          }
          // setIncomeData(decoded_res.data)
        }
      }
    } else {
      if (fplogid) {
        var payload = {
          user_id: getParentUserId(),
          fp_log_id: fplogid,
          income_forretirement: "0",
        };
        var payload = commonEncode.encrypt(JSON.stringify(payload));
        var res = await apiCall(
          BASE_API_URL + "/restapi/getincomedata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res.data.length == 1) {
          setRecurFlag(decoded_res.data[0]["income_isRecurring"]);
        }
        if (decoded_res) {
          setIncomeData(decoded_res.data);
          setIsLoading(false);
          let incomeLength = decoded_res.data.length;
          if (decoded_res.data.length > 0) {
            setTotalIncome(decoded_res.yearly_income.income);
          } else {
            setTotalIncome(0);
          }
          setLifecycleStatus(decoded_res.lifecycle_status);
          if (decoded_res.lifecycle_status == 2) {
            const recurringCount = decoded_res.data.filter(
              (item) => item.income_isRecurring
            ).length;
            setRecurringTotal(recurringCount);
          }
          if (
            check_first_save == 1 &&
            incomeLength == 1 &&
            expenseData.length >= 1
          ) {
            localStorage.setItem("incomeExpenseCookie", 1);
            dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          } else {
            localStorage.removeItem("incomeExpenseCookie");
            dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          }
        }
      } else {
      }
    }
  };
  const getExpenseData = async (id, check_first_save = 0) => {

    if (id != "") {
      if (fplogid) {
        var payload = {
          id: id,
        };

        var payload = commonEncode.encrypt(JSON.stringify(payload));
        var res = await apiCall(
          BASE_API_URL + "/restapi/getexpensesdata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res) {
          setIsLoading(false);
          var expense = decoded_res.data[0];

          var category_name = expense_options.filter((exp) => {
            return exp.id === parseInt(expense.expenses_category_id);
          });
          toastr.options.positionClass = "toast-bottom-left";
          toastr.success(
            "You can now edit details for " +
            category_name[0].title +
            " - " +
            expense.expenses_name
          );

          var currentDate = new Date();
          var expenseStartDateString = expense.expenses_start_date;
          var [day, month, year] = expenseStartDateString.split('/');
          var expenseStartDateObj = new Date(year, month - 1, day);
          if (currentDate > expenseStartDateObj) {
            var currentDateString = currentDate.getDate().toString().padStart(2, '0') + '/' +
              (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
              currentDate.getFullYear().toString();
            expense.expenses_start_date = currentDateString;
          }

          var expenseStartDateChanged =
            expense.expenses_start_date.substr(3, 2) +
            "/" +
            expense.expenses_start_date.substr(0, 2) +
            "/" +
            expense.expenses_start_date.substr(6, 4);

          var expenseEndDateChanged = expense.expenses_end_date
            ? expense.expenses_end_date.substr(3, 2) +
            "/" +
            expense.expenses_end_date.substr(0, 2) +
            "/" +
            expense.expenses_end_date.substr(6, 4)
            : expenseStartDateChanged;
          setExpenseName(expense.expenses_name);
          setExpenseAmount(expense.expenses_amount);
          setExpenseInflationRate(expense.expenses_rate_of_increase);
          setExpenseCategoryId(parseInt(expense.expenses_category_id));
          if (expense.expenses_categoryId == "Mediclaim") {
            setSelectedOption1("Medical");
          }
          else {
            setSelectedOption1(expense.expenses_categoryId);
          }
          setExpenseStartDate(expenseStartDateChanged);
          if (expense.expenses_isRecurring) {
            if (!expense.expenses_isAfterRetirement) {
              setselectedEndAge(null);
            }
            var retirementDateChanged =
              moment(retirementDate).format("DD/MM/YYYY");
            var lifeExpectancyDateChanged =
              moment(lifeExpectancyDate).format("DD/MM/YYYY");
            if (expense.expenses_isAfterRetirement == "1") {
              if (expense.expenses_end_date == lifeExpectancyDateChanged) {
                setselectedEndAge(2);
              } else {
                setselectedEndAge(null);
              }
            }
            if (expense.expenses_isAfterRetirement == "0") {
              if (expense.expenses_end_date == retirementDateChanged) {
                setselectedEndAge(1);
              } else {
                setselectedEndAge(null);
              }
            }
            setExpenseEndDate(expenseEndDateChanged);

            setExpenseFrequency(
              expense.expenses_frequency != "0"
                ? expense.expenses_frequency
                : "1"
            );
          } else {
            setExpenseEndDate(null);
            setselectedEndAge(null);
            setExpenseFrequency("1");
          }
          setExpenseForMember(expense.expenses_for_member);
          // setExpenseFrequency(expense.expenses_frequency);
          setExpenseFixed(expense.expenses_isfixed);
          // setUpdateForm(true)
          // setAddForm(false)

          setExpenseRecurring(expense.expenses_isRecurring);
        }
      }
    } else {

      if (fplogid) {

        var payload = {
          user_id: getParentUserId(),
          fp_log_id: fplogid,
          expenses_forretirement: "0",
        };
        var payload = commonEncode.encrypt(JSON.stringify(payload));
        var res = await apiCall(
          BASE_API_URL + "/restapi/getexpensesdata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res) {
          setIsLoading(false);
          setExpenseData(decoded_res.data);

          if (decoded_res.data.length > 0) {
            setTotalExpense(decoded_res.yearly_expense.expense);
          } else {
            setTotalExpense(0);
          }
          var expenseLength = decoded_res.data.length;
          setLifecycleStatus(decoded_res.lifecycle_status);
          if (decoded_res.lifecycle_status == 2) {
            const expense_recurringCount = decoded_res.data.filter(
              (item) =>
                item.expenses_isRecurring && item.expenses_category_id !== "121"
            ).length;
            setExpenseRecurringTotal(expense_recurringCount);
          }

          var expenses = decoded_res.data;

          if (expenses.length == 1 && expenses[0]['expenses_category_id'] == "121") {
            setOnlyPostRetirement(true);
          }
          else {
            setOnlyPostRetirement(false);
          }
          if (
            check_first_save == 1 &&
            expenseLength == 1 &&
            incomeData.length >= 1
          ) {
            localStorage.setItem("incomeExpenseCookie", 1);
            dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          } else {
            localStorage.removeItem("incomeExpenseCookie");
            dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          }

        }
      } else {
      }
    }
  };

  const saveIncomeData = async (e) => {
    setUpdateForm(false);
    e.preventDefault();
    if (incomeName == "") {
      setIncomeNameError("Please enter income name");
    }
    if (incomeAmount == "") {
      setIncomeAmountError("Please enter net income value");
    }
    if (isRecurring == true) {
      if (incomeEndDate == "" || !incomeEndDate) {
        setEndDateError("Please select end date");
      }
      if (incomeFrequency == "" || !incomeFrequency) {
        setIncomeFrequencyError("Please select frequency");
      }
    }
    if (incomeCategoryId == 8) {
      if (incomeType == "") {
        setIncomeTypeError("Please select income type");
      }
    }
    if (incomeCategoryId == 8) {
      if (
        incomeName != "" &&
        incomeAmount != "" &&
        incomeType != "" &&
        endDateError == "" &&
        incomeNameError == "" &&
        incomeAmountError == "" &&
        incomeTypeError == ""
      ) {
        // var incomeStartDateChanged=incomeStartDate.substr(3, 2)+"/"+incomeStartDate.substr(0, 2)+"/"+incomeStartDate.substr(6, 4)
        // var incomeEndDateChanged=incomeEndDate?incomeEndDate.substr(3, 2)+"/"+incomeEndDate.substr(0, 2)+"/"+incomeEndDate.substr(6, 4):incomeStartDateChanged
        var payload = {
          income_name: incomeName,
          user_id: getParentUserId(),
          fp_log_id: fplogid,
          income_isRecurring: isRecurring,
          income_type: incomeType,
          income_isFixed: incomeIsFixed,
          income_frequency: isRecurring
            ? incomeFrequency != "0"
              ? incomeFrequency
              : "1"
            : "0",
          income_annual_increase: incomeAnnualIncrease,
          income_category_id: incomeCategoryId,
          income_forretirement: incomeForRetirement,
          income_isAfterRetirement: incomeAfterRetirement,
          income_end_date: moment(incomeEndDate).format("DD/MM/YYYY"),
          income_start_date: moment(incomeStartDate).format("DD/MM/YYYY"),
          // income_start_date: new Date(incomeStartDate).toLocaleDateString(),
          // income_end_date: new Date(incomeStartDate)
          //   ? new Date(incomeEndDate).toLocaleDateString()
          //   : new Date(incomeStartDate).toLocaleDateString(),
          income_for_member: incomeForMember,
          income_amount: parseInt(incomeAmount),
          income_footnote: incomeFootnote,
        };
        if (payload.income_frequency === "0") {
          payload.income_end_date = payload.income_start_date;
        }
        if (payload.income_frequency === "0") {
          payload.income_end_date = payload.income_start_date;
        }
        var payload = commonEncode.encrypt(JSON.stringify(payload));
        setIsLoading(true);

        var res = await apiCall(
          BASE_API_URL + "restapi/saveupdateincomedata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res.error_code == "100") {
          var category_name = income_options.filter((income) => {
            return income.id == incomeCategoryId;
          });
          toastr.options.positionClass = "toast-bottom-left";

          setIsLoading(false);
          toastr.success(
            "Data saved successfully for " +
            category_name[0].title +
            " - " +
            incomeName
          );
          // dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          scrollToIncomeList();
          var checkFirstSave = 1;

          setIncomeName(category_name[0].title);
          setIncomeAmount("");
          setIncomeAnnualIncrease(10);
          // setIncomeCategoryId("");
          setIncomeEndDate("");
          setIncomeFrequency("1");
          setIncomeIsFixed(false);
          setIncomeStartDate(new Date());
          setIncomeType("1");
          await getIncomeData("", checkFirstSave);
          setselectedEndAge(null);
        } else {
          setIsLoading(false);

          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(decoded_res.message);
        }
      }
    } else {
      if (
        incomeName != "" &&
        incomeAmount != "" &&
        endDateError == "" &&
        incomeNameError == "" &&
        incomeAmountError == ""
      ) {
        // var incomeStartDateChanged=incomeStartDate.substr(3, 2)+"/"+incomeStartDate.substr(0, 2)+"/"+incomeStartDate.substr(6, 4)
        // var incomeEndDateChanged=incomeEndDate?incomeEndDate.substr(3, 2)+"/"+incomeEndDate.substr(0, 2)+"/"+incomeEndDate.substr(6, 4):incomeStartDateChanged

        var payload = {
          income_name: incomeName,
          user_id: getParentUserId(),
          fp_log_id: fplogid,
          income_isRecurring: isRecurring,
          income_type: incomeType,
          income_isFixed: incomeIsFixed,
          income_frequency: isRecurring
            ? incomeFrequency != "0"
              ? incomeFrequency
              : "1"
            : "0",
          income_annual_increase: incomeAnnualIncrease,
          income_category_id: incomeCategoryId,
          income_forretirement: incomeForRetirement,
          income_isAfterRetirement: incomeAfterRetirement,
          // income_start_date: new Date(incomeStartDate).toLocaleDateString(),
          // income_end_date: new Date(incomeStartDate)
          //   ? new Date(incomeEndDate).toLocaleDateString()
          //   : new Date(incomeStartDate).toLocaleDateString(),
          income_end_date: moment(incomeEndDate).format("DD/MM/YYYY"),
          income_start_date: moment(incomeStartDate).format("DD/MM/YYYY"),
          income_for_member: incomeForMember,
          income_amount: parseInt(incomeAmount),
          income_footnote: incomeFootnote,
        };
        if (payload.income_frequency === "0") {
          payload.income_end_date = payload.income_start_date;
        }
        var payload = commonEncode.encrypt(JSON.stringify(payload));
        setIsLoading(true);

        var res = await apiCall(
          BASE_API_URL + "restapi/saveupdateincomedata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res.error_code == "100") {
          var category_name = income_options.filter((income) => {
            return income.id == incomeCategoryId;
          });
          setIsLoading(false)

          toastr.options.positionClass = "toast-bottom-left";

          toastr.success(
            "Data saved successfully for " +
            category_name[0].title +
            " - " +
            incomeName
          );
          // dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          scrollToIncomeList();
          var checkFirstSave = 1;

          setIncomeName(category_name[0].title);
          setIncomeAmount("");
          setIncomeAnnualIncrease(10);
          // setIncomeCategoryId("");
          setIncomeEndDate("");
          setIncomeFrequency("1");
          setIncomeIsFixed(false);
          setIncomeStartDate(new Date());
          setIncomeType("1");
          setselectedEndAge(null);
          await getIncomeData("", checkFirstSave);
          // setIsLoading(false);

        } else {
          setIsLoading(false);

          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(decoded_res.message);
        }
      }
    }
  };
  useEffect(() => {
    if (!isExpenseRecurring) {
      setExpenseEndDateError("");
    }
  }, [isExpenseRecurring]);

  const saveExpenseData = async (e) => {
    setUpdateForm(false);
    e.preventDefault();
    if (expenseName == "") {
      setExpenseNameError("Please enter expense name");
    }
    if (expenseAmount == "") {
      setExpenseAmountError("Please enter expense value");
    }
    if (isExpenseRecurring == true) {
      if (expenseEndDate == "" || !expenseEndDate) {
        setExpenseEndDateError("Please select end date");
      }
      if (expenseFrequency == "" || !expenseFrequency) {
        setExpenseFrequencyError("Please select frequency");
      }
    }

    if (isExpenseRecurring == true) {
      if (
        expenseName != "" &&
        expenseAmount != "" &&
        expenseEndDate != "" &&
        expenseEndDate != null &&
        expenseAmountError == "" &&
        expenseEndDateError == "" &&
        expenseNameError == ""
      ) {
        var payload = {
          expenses_name: expenseName,
          user_id: getParentUserId(),
          fp_log_id: fplogid,
          expenses_isRecurring: isExpenseRecurring,
          expenses_isfixed: expenseIsFixed,
          expenses_frequency: isExpenseRecurring
            ? expenseFrequency != "0"
              ? expenseFrequency
              : "1"
            : "0",
          expenses_rate_of_increase: expenseInflationRate,
          expenses_category_id: expenseCategoryId,
          expenses_forretirement: expenseForRetirement,
          expenses_isAfterRetirement: expenseAfterRetirement,
          expenses_start_date: moment(expenseStartDate).format("DD/MM/YYYY"),
          expenses_end_date: moment(expenseEndDate).format("DD/MM/YYYY"),
          // expenses_start_date: new Date(expenseStartDate).toLocaleDateString(),
          // expenses_end_date: new Date(expenseEndDate)
          //   ? new Date(expenseEndDate).toLocaleDateString()
          //   : new Date(expenseStartDate).toLocaleDateString(),
          expenses_for_member: expenseForMember,
          expenses_amount: parseInt(expenseAmount),
          expenses_ismandatory: isWish,
          expenses_footnote: expenseFootnote,
          id: 0,
        };

        if (payload.expenses_frequency === "0") {
          payload.expenses_end_date = payload.expenses_start_date;
        }
        var payload = commonEncode.encrypt(JSON.stringify(payload));
        setIsLoading(true);
        var res = await apiCall(
          BASE_API_URL + "restapi/saveupdateexpensesdata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res.error_code == "100") {
          setIsLoading(false);
          var category_name = expense_options.filter((exp) => {
            return exp.id == expenseCategoryId;
          });

          toastr.options.positionClass = "toast-bottom-left";

          toastr.success(
            "Data saved successfully for " +
            category_name[0].title +
            " - " +
            expenseName
          );
          scrollToExpenseList();
          var checkFirstSave = 1;

          setExpenseName(category_name[0].title);
          setExpenseAmount("");
          setExpenseInflationRate(7);
          setExpenseCategoryId(17);
          setExpenseEndDate("");
          setExpenseFrequency("1");
          setExpenseFixed(false);
          setExpenseRecurring(true);
          setExpenseName("Club Membership and Subscriptions");
          setSelectedOption1("Club Membership and Subscriptions");
          setExpenseStartDate(new Date());
          setselectedEndAge(null);

          await getExpenseData("", checkFirstSave);
          // dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        } else {
          setIsLoading(false);
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(decoded_res.message);
        }
      }
    } else {
      if (
        expenseName != "" &&
        expenseAmount != "" &&
        // expenseEndDate != "" &&
        expenseAmountError == "" &&
        // expenseEndDateError == "" &&
        expenseNameError == ""
      ) {
        var payload = {
          expenses_name: expenseName,
          user_id: getParentUserId(),
          fp_log_id: fplogid,
          expenses_isRecurring: isExpenseRecurring,
          expenses_isfixed: expenseIsFixed,
          expenses_frequency: isExpenseRecurring
            ? expenseFrequency != "0"
              ? expenseFrequency
              : "1"
            : "0",
          expenses_rate_of_increase: expenseInflationRate,
          expenses_category_id: expenseCategoryId,
          expenses_forretirement: expenseForRetirement,
          expenses_isAfterRetirement: expenseAfterRetirement,
          expenses_start_date: moment(expenseStartDate).format("DD/MM/YYYY"),
          expenses_end_date: moment(expenseEndDate).format("DD/MM/YYYY"),
          // expenses_start_date: new Date(expenseStartDate).toLocaleDateString(),
          // expenses_end_date: new Date(expenseEndDate)
          //   ? new Date(expenseEndDate).toLocaleDateString()
          //   : new Date(expenseStartDate).toLocaleDateString(),
          expenses_for_member: expenseForMember,
          expenses_amount: parseInt(expenseAmount),
          expenses_ismandatory: isWish,
          expenses_footnote: expenseFootnote,
          id: 0,
        };

        if (payload.expenses_frequency === "0") {
          payload.expenses_end_date = payload.expenses_start_date;
        }
        var payload = commonEncode.encrypt(JSON.stringify(payload));
        var res = await apiCall(
          BASE_API_URL + "restapi/saveupdateexpensesdata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res.error_code == "100") {
          setIsLoading(false);
          var category_name = expense_options.filter((exp) => {
            return exp.id == expenseCategoryId;
          });

          toastr.options.positionClass = "toast-bottom-left";

          toastr.success(
            "Data saved successfully for " +
            category_name[0].title +
            " - " +
            expenseName
          );
          scrollToExpenseList();
          var checkFirstSave = 1;

          setExpenseName(category_name[0].title);
          setExpenseAmount("");
          setExpenseInflationRate(7);
          setExpenseCategoryId(17);
          setExpenseEndDate("");
          setExpenseFrequency("1");
          setExpenseFixed(false);
          setExpenseRecurring(true);
          setExpenseName("Club Membership and Subscriptions");
          setSelectedOption1("Club Membership and Subscriptions");
          setExpenseStartDate(new Date());
          setselectedEndAge(null);

          await getExpenseData("", checkFirstSave);
          // dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        } else {
          setIsLoading(false);
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(decoded_res.message);
        }
      }
    }
  };
  const handleIncomeName = (incomeName) => {
    var isValid = true;
    if (incomeName == "") {
      isValid = false;
      setIncomeNameError("Please enter income name");
    } else {
      isValid = true;

      if (incomeName.length < 3 || incomeName.length > 35) {
        isValid = false;
        setIncomeNameError("Name must be between 3-35 characters");
      }

      if (!/^[a-zA-Z0-9\s&]+$/.test(incomeName)) {
        isValid = false;

        setIncomeNameError("Please enter valid income name");
      }

      if (isValid) {
        setIncomeNameError("");
      }
    }
  };
  const handleExpenseName = (expenseName) => {
    var isValid = true;
    if (expenseName == "") {
      isValid = false;
      setExpenseNameError("Please enter expense name");
    } else {
      isValid = true;

      if (expenseName.length < 3 || expenseName.length > 35) {
        isValid = false;
        setExpenseNameError("Name must be between 3-35 characters");
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(expenseName)) {
        isValid = false;

        setExpenseNameError("Please enter valid expense name");
      }

      if (isValid) {
        setExpenseNameError("");
      }
    }
  };
  const updateIncomeData = async (e) => {
    e.preventDefault();
    if (incomeName == "") {
      setIncomeNameError("Please enter income name");
    }
    if (incomeAmount == "") {
      setIncomeAmountError("Please enter net income value");
    }
    if (incomeCategoryId == 8) {
      if (incomeType == "") {
        setIncomeTypeError("Please select income type");
      }
    }
    if (isRecurring == true) {
      if (incomeEndDate == "" || !incomeEndDate) {
        setEndDateError("Please select end date");
      }
      if (incomeFrequency == "" || !incomeFrequency) {
        setIncomeFrequencyError("Please select frequency");
      }
    }

    if (incomeCategoryId == 8) {
      if (
        incomeName != "" &&
        incomeAmount != "" &&
        incomeType != "" &&
        endDateError == "" &&
        incomeAmountError == "" &&
        incomeNameError == "" &&
        incomeTypeError == ""
      ) {
        // var incomeStartDateChanged=incomeStartDate.substr(3, 2)+"/"+incomeStartDate.substr(0, 2)+"/"+incomeStartDate.substr(6, 4)
        // var incomeEndDateChanged=incomeEndDate?incomeEndDate.substr(3, 2)+"/"+incomeEndDate.substr(0, 2)+"/"+incomeEndDate.substr(6, 4):incomeStartDateChanged
        var payload = {
          income_name: incomeName,
          user_id: getParentUserId(),
          fp_log_id: fplogid,
          income_isRecurring: isRecurring,
          income_type: incomeType,
          income_isFixed: incomeIsFixed,
          income_frequency: isRecurring
            ? incomeFrequency != "0"
              ? incomeFrequency
              : "1"
            : "0",
          income_annual_increase: incomeAnnualIncrease,
          income_category_id: incomeCategoryId,
          income_forretirement: incomeForRetirement,
          income_isAfterRetirement: incomeAfterRetirement,
          income_end_date: moment(incomeEndDate).format("DD/MM/YYYY"),
          income_start_date: moment(incomeStartDate).format("DD/MM/YYYY"),
          // income_start_date: new Date(incomeStartDate).toLocaleDateString(),
          // income_end_date: new Date(incomeStartDate)
          //   ? new Date(incomeEndDate).toLocaleDateString()
          //   : new Date(incomeStartDate).toLocaleDateString(),
          income_for_member: incomeForMember,
          income_amount: parseInt(incomeAmount),
          income_footnote: incomeFootnote,
          id: incomeId,
        };
        if (payload.income_frequency === "0") {
          payload.income_end_date = payload.income_start_date;
        }
        var payload = commonEncode.encrypt(JSON.stringify(payload));
        var res = await apiCall(
          BASE_API_URL + "restapi/saveupdateincomedata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res.error_code == "100") {
          setIsLoading(true);
          var category_name = income_options.filter((income) => {
            return income.id == incomeCategoryId;
          });

          toastr.options.positionClass = "toast-bottom-left";

          toastr.success(
            "Data updated successfully for " +
            category_name[0].title +
            " - " +
            incomeName
          );
          dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          scrollToIncomeList();
          getIncomeData("", 0);
          setIncomeAmount("");
          setIncomeAnnualIncrease(10);
          setUpdateForm(false);
          setAddForm(true);
          // setIncomeCategoryId("");

          setIncomeName(category_name[0].title);
          setIncomeEndDate("");
          setIncomeFrequency("1");
          setIncomeIsFixed(false);
          setIsRecurring(true);
          setIncomeStartDate(new Date());
          setselectedEndAge(null);
          setIncomeType("1");
        } else {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(decoded_res.message);
        }
      }
    } else {
      if (
        incomeName != "" &&
        incomeAmount != "" &&
        endDateError == "" &&
        incomeNameError == "" &&
        incomeAmountError == ""
      ) {
        // var incomeStartDateChanged=incomeStartDate.substr(3, 2)+"/"+incomeStartDate.substr(0, 2)+"/"+incomeStartDate.substr(6, 4)
        // var incomeEndDateChanged=incomeEndDate?incomeEndDate.substr(3, 2)+"/"+incomeEndDate.substr(0, 2)+"/"+incomeEndDate.substr(6, 4):incomeStartDateChanged
        var payload = {
          income_name: incomeName,
          user_id: getParentUserId(),
          fp_log_id: fplogid,
          income_isRecurring: isRecurring,
          income_type: incomeType,
          income_isFixed: incomeIsFixed,
          income_frequency: isRecurring
            ? incomeFrequency != "0"
              ? incomeFrequency
              : "1"
            : "0",
          income_annual_increase: incomeAnnualIncrease,
          income_category_id: incomeCategoryId,
          income_forretirement: incomeForRetirement,
          income_isAfterRetirement: incomeAfterRetirement,
          income_end_date: moment(incomeEndDate).format("DD/MM/YYYY"),
          income_start_date: moment(incomeStartDate).format("DD/MM/YYYY"),
          // income_start_date: new Date(incomeStartDate).toLocaleDateString(),
          // income_end_date: new Date(incomeStartDate)
          //   ? new Date(incomeEndDate).toLocaleDateString()
          //   : new Date(incomeStartDate).toLocaleDateString(),
          income_for_member: incomeForMember,
          income_amount: parseInt(incomeAmount),
          income_footnote: incomeFootnote,
          id: incomeId,
        };

        if (payload.income_frequency === "0") {
          payload.income_end_date = payload.income_start_date;
        }
        var payload = commonEncode.encrypt(JSON.stringify(payload));
        var res = await apiCall(
          BASE_API_URL + "restapi/saveupdateincomedata/",
          payload,
          false,
          false
        );
        let decoded_res = JSON.parse(commonEncode.decrypt(res));
        if (decoded_res.error_code == "100") {
          setIsLoading(true);
          var category_name = income_options.filter((income) => {
            return income.id == incomeCategoryId;
          });
          toastr.options.positionClass = "toast-bottom-left";

          toastr.success(
            "Data updated successfully for " +
            category_name[0].title +
            " - " +
            incomeName
          );
          dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          scrollToIncomeList();
          getIncomeData("", 0);
          setIncomeAmount("");
          setIncomeAnnualIncrease(10);
          // setIncomeCategoryId("");
          setUpdateForm(false);
          setAddForm(true);

          setIncomeName(category_name[0].title);
          setIncomeEndDate("");
          setIncomeFrequency("1");
          setIncomeIsFixed(false);
          setIsRecurring(true);
          setIncomeStartDate(new Date());
          setIncomeType("1");
          setselectedEndAge(null);
        } else {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(decoded_res.message);
        }
      }
    }
  };
  const updateExpenseData = async (e) => {
    try {
      e.preventDefault();

      if (expenseName == "") {
        isValid = false;
        setExpenseNameError("Please enter expense name");
      }

      if (expenseAmount == "") {
        isValid = false;
        setExpenseAmountError("Please enter expense value");
      }

      if (isExpenseRecurring == true) {
        if (expenseEndDate == "" || !expenseEndDate) {
          setExpenseEndDateError("Please select end date");
        }
        if (expenseFrequency == "" || !expenseFrequency) {
          setExpenseFrequencyError("Please select frequency");
        }
      }

      if (isExpenseRecurring) {
        if (
          expenseName != "" &&
          expenseAmount != "" &&
          expenseEndDate != "" &&
          expenseEndDate != null &&
          expenseAmountError == "" &&
          expenseEndDateError == "" &&
          expenseNameError == ""
        ) {
          // var incomeStartDateChanged=incomeStartDate.substr(3, 2)+"/"+incomeStartDate.substr(0, 2)+"/"+incomeStartDate.substr(6, 4)
          // var incomeEndDateChanged=incomeEndDate?incomeEndDate.substr(3, 2)+"/"+incomeEndDate.substr(0, 2)+"/"+incomeEndDate.substr(6, 4):incomeStartDateChanged

          var payload = {
            expenses_name: expenseName,
            user_id: getParentUserId(),
            fp_log_id: fplogid,
            expenses_isRecurring: isExpenseRecurring,
            expenses_isfixed: expenseIsFixed,
            expenses_frequency: isExpenseRecurring
              ? expenseFrequency != "0"
                ? expenseFrequency
                : "1"
              : "0",
            expenses_rate_of_increase: expenseInflationRate,
            expenses_category_id: expenseCategoryId,
            expenses_forretirement: expenseForRetirement,
            expenses_isAfterRetirement: expenseAfterRetirement,
            expenses_start_date: moment(expenseStartDate).format("DD/MM/YYYY"),
            expenses_end_date: moment(expenseEndDate).format("DD/MM/YYYY"),
            // expenses_start_date: new Date(
            //   expenseStartDate
            // ).toLocaleDateString(),
            // expenses_end_date: new Date(expenseEndDate)
            //   ? new Date(expenseEndDate).toLocaleDateString()
            //   : new Date(expenseStartDate).toLocaleDateString(),
            expenses_for_member: expenseForMember,
            expenses_amount: parseInt(expenseAmount),
            expenses_ismandatory: isWish,
            expenses_footnote: expenseFootnote,
            id: expenseId,
          };
          if (payload.expenses_frequency === "0") {
            payload.expenses_end_date = payload.expenses_start_date;
          }
          setIsLoading(true);
          var payload = commonEncode.encrypt(JSON.stringify(payload));
          var res = await apiCall(
            BASE_API_URL + "restapi/saveupdateexpensesdata/",
            payload,
            false,
            false
          );
          let decoded_res = JSON.parse(commonEncode.decrypt(res));
          if (decoded_res.error_code == "100") {
            var category_name = expense_options.filter((expense) => {
              return expense.id == expenseCategoryId;
            });
            toastr.options.positionClass = "toast-bottom-left";

            toastr.success(
              "Data updated successfully for " +
              category_name[0].title +
              " - " +
              expenseName
            );
            dispatch({ type: "RELOAD_SIDEBAR", payload: true });
            scrollToExpenseList();
            setIsLoading(false);

            setExpenseName(category_name[0].title);
            setExpenseAmount("");
            setUpdateForm(false);
            setAddForm(true);
            setExpenseInflationRate(7);
            setExpenseCategoryId(17);
            setExpenseEndDate("");
            setExpenseFrequency("1");
            setExpenseFixed(false);
            setExpenseRecurring(true);
            // setExpenseName("Club Membership and Subscriptions");
            // setSelectedOption1("Club Membership and Subscriptions");
            setExpenseStartDate(new Date());
            setselectedEndAge(null);
            getExpenseData("", 0);
          } else {
            setIsLoading(false);

            toastr.options.positionClass = "toast-bottom-left";
            toastr.error(decoded_res.message);
          }
        }
      } else {
        if (
          expenseName != "" &&
          expenseAmount != "" &&
          expenseAmountError == "" &&
          expenseNameError == ""
        ) {
          // var incomeStartDateChanged=incomeStartDate.substr(3, 2)+"/"+incomeStartDate.substr(0, 2)+"/"+incomeStartDate.substr(6, 4)
          // var incomeEndDateChanged=incomeEndDate?incomeEndDate.substr(3, 2)+"/"+incomeEndDate.substr(0, 2)+"/"+incomeEndDate.substr(6, 4):incomeStartDateChanged

          var payload = {
            expenses_name: expenseName,
            user_id: getParentUserId(),
            fp_log_id: fplogid,
            expenses_isRecurring: isExpenseRecurring,
            expenses_isfixed: expenseIsFixed,
            expenses_frequency: isExpenseRecurring
              ? expenseFrequency != "0"
                ? expenseFrequency
                : "1"
              : "0",
            expenses_rate_of_increase: expenseInflationRate,
            expenses_category_id: expenseCategoryId,
            expenses_forretirement: expenseForRetirement,
            expenses_isAfterRetirement: expenseAfterRetirement,
            expenses_start_date: moment(expenseStartDate).format("DD/MM/YYYY"),
            expenses_end_date: moment(expenseEndDate).format("DD/MM/YYYY"),
            // expenses_start_date: new Date(
            //   expenseStartDate
            // ).toLocaleDateString(),
            // expenses_end_date: new Date(expenseEndDate)
            //   ? new Date(expenseEndDate).toLocaleDateString()
            //   : new Date(expenseStartDate).toLocaleDateString(),
            expenses_for_member: expenseForMember,
            expenses_amount: parseInt(expenseAmount),
            expenses_ismandatory: isWish,
            expenses_footnote: expenseFootnote,
            id: expenseId,
          };
          if (payload.expenses_frequency === "0") {
            payload.expenses_end_date = payload.expenses_start_date;
          }
          var payload = commonEncode.encrypt(JSON.stringify(payload));
          var res = await apiCall(
            BASE_API_URL + "restapi/saveupdateexpensesdata/",
            payload,
            false,
            false
          );
          let decoded_res = JSON.parse(commonEncode.decrypt(res));
          if (decoded_res.error_code == "100") {
            var category_name = expense_options.filter((expense) => {
              return expense.id == expenseCategoryId;
            });
            toastr.options.positionClass = "toast-bottom-left";

            toastr.success(
              "Data updated successfully for " +
              category_name[0].title +
              " - " +
              expenseName
            );
            dispatch({ type: "RELOAD_SIDEBAR", payload: true });
            scrollToExpenseList();
            setIsLoading(false);

            setExpenseName(category_name[0].title);
            setExpenseAmount("");
            setExpenseInflationRate(7);
            setExpenseCategoryId(17);
            setExpenseEndDate("");
            setExpenseFrequency("1");
            setUpdateForm(false);
            setAddForm(true);
            setExpenseFixed(false);
            setExpenseRecurring(true);
            // setExpenseName("Club Membership and Subscriptions");
            // setSelectedOption1("Club Membership and Subscriptions");
            setExpenseStartDate(new Date());
            setselectedEndAge(null);
            getExpenseData("", 0);
          } else {
            setIsLoading(false);
            toastr.options.positionClass = "toast-bottom-left";
            toastr.error(decoded_res.message);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const scrollToIncomeForm = () => {
    const { offsetTop } = cntRef.current;
    //
    window.scroll({ top: offsetTop - 50 });
  };

  const scrollToIncomeList = () => {
    const { offsetTop } = cntRef.current;
    window.scroll({ top: offsetTop - 1500 });
  };

  const scrollToExpenseForm = () => {
    const { offsetTop } = cntRef.current;
    window.scroll({ top: offsetTop + 1500 });
  };

  const scrollToExpenseList = () => {
    const { offsetTop } = cntRef.current;
    window.scroll({ top: offsetTop - 1500 });
  };

  const sortOptionsIncomeType = [
    { value: "1", label: "Salary" },
    { value: "2", label: "Bonus" },
  ];

  const sortOptions = [
    { value: "1", label: "Self" },
    { value: "2", label: "Family" },
    { value: "3", label: "Dipti Sharma" },
  ];
  const sortOptionsFrequency = [
    { value: "1", label: "Monthly" },
    { value: "2", label: "Quarterly" },
    { value: "3", label: "Half Yearly" },
    { value: "4", label: "Yearly" },
  ];
  const sortOptionsVacationFrequency = [
    { value: "1", label: "Monthly" },
    { value: "2", label: "Quarterly" },
    { value: "3", label: "Half Yearly" },
    { value: "4", label: "Yearly" },
    { value: "5", label: "Once In 2yr" },
    { value: "6", label: "Once In 3yr" },
    { value: "7", label: "Once In 4yr" },
    { value: "8", label: "Once In 5yr" },
  ];
  const sortOptionsEducation = [
    { value: "1", label: "  BSc / BCom / BA / BCA / BMS / BBA etc" },
    { value: "2", label: "Engineering" },
    { value: "3", label: "High School (Upto XIIth)" },
    { value: "4", label: "MSc / MCom / MA / MCA / MMS / MBA etc)" },
    { value: "5", label: "School (Upto Xth)" },
    { value: "6", label: "Other" },
    // { value: "3", label: "Half Yearly" },
  ];
  const sortOptionsGoalPriority = [
    { value: "1", label: "Very High" },
    { value: "2", label: "High" },
    { value: "6", label: "Medium" },
    // { value: "3", label: "Half Yearly" },
  ];
  const frequencylist = {
    0: "One Time",
    1: "Monthly",
    2: "Quarterly",
    3: "Half Yearly",
    4: "Yearly",
    5: "Once In 2yr",
    6: "Once In 3yr",
    7: "Once In 4yr",
    8: "Once In 5yr",
  };

  // const customStyles = {
  //   option: (base, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...base,
  //       backgroundColor: isFocused ? "#ffff" : "#042b62",
  //       color: isFocused ? "#000" : "#fff",
  //       cursor: "pointer",
  //     };
  //   },
  //   menuList: (base) => ({
  //     ...base,
  //     height: "100px",
  //     overflowY: "scroll",
  //     scrollBehavior: "smooth",
  //     "::-webkit-scrollbar": {
  //       width: "4px",
  //       height: "0px",
  //     },
  //     "::-webkit-scrollbar-track": {
  //       background: "#fff",
  //     },
  //     "::-webkit-scrollbar-thumb": {
  //       background: "#042b62",
  //     },
  //     "::-webkit-scrollbar-thumb:hover": {
  //       background: "#555",
  //     },
  //   }),
  // };

  useEffect(() => {
    setAddForm(true);
    setUpdateForm(false);
    setSelectedCategories([]);
    setSelectedExpenseCategories([]);
  }, [tab])
  //
  return (
    <DatagatherLayout>
      <FintooLoader isLoading={isLoading} />

      <div className="">
        <div className="background-div">
          <div
            className={`bg ${currentUrl.indexOf("datagathering/income-expenses") > -1
              ? "active"
              : ""
              }`}
            id="bg-incomeexpense"
          ></div>
        </div>
        <div className="white-box">
          <div className={`d-flex justify-content-md-center tab-box DGheaderFix`}
          >
            <div className="d-flex top-tab-menu income-expense-menu noselect">
              <div
                className={`tab-menu-item ${tab == "tab1" ? "active" : ""}`}
                onClick={() => { setTab("tab1"); ScrollToTop(); }}
              >
                <div className="tab-menu-title">Income</div>
              </div>
              <div
                className={`tab-menu-item ${tab == "tab2" ? "active" : ""}`}
                onClick={() => {
                  setTab("tab2");
                  ScrollToTop();
                }}
              >
                <div className="tab-menu-title">Expenses</div>
              </div>
            </div>
          </div>

          <div>
            <div className={` ${tab == "tab1" ? "d-block" : "d-none"}`}>
              <div className="row ">
                <div className={`col-md-2 ${DGstyles.moneyjarcontainer}`}>
                  <h5
                    className="accordion-heading"
                    style={{
                      marginBottom: "10px",
                      fontSize: "13.2px",
                      fontWeight: 700,
                    }}
                  >
                    Income Vs Expense
                  </h5>
                  <div
                    className="money-jar"
                    style={{
                      position: "relative",
                      width: "70px",
                      margin: "0 auto",
                    }}
                  >
                    <span
                      style={{
                        height: "100%",
                        transition: "0.6s ease all",
                        background: totalIncome == 0 ? "#fff" : "#042b62",
                        width: "100%",
                        display: "block",
                        position: "absolute",
                        // bottom: "1px",
                      }}
                      id="income-jar"
                      className="income-jar"
                    ></span>
                    <img
                      style={{
                        position: "relative",
                        width: "70px",
                        display: "block",
                        marginTop: "3rem"
                      }}
                      src={process.env.REACT_APP_STATIC_URL + "media/DG/jar.svg"}
                      alt="Rupee"
                    />
                  </div>
                  <br></br>
                  <span
                    className="mb-2 total-amt text-center"
                    style={{ fontSize: "16px" }}
                  >
                    Total Income
                  </span>
                  <br></br>
                  <b>{indianRupeeFormat(totalIncome)}</b>
                  <div style={{ fontSize: "14px" }}>
                    <b>
                      (Income Till 31<sup>st</sup> Dec,{" "}
                      {moment().format("YYYY")})
                    </b>
                  </div>
                </div>
                <div className="col-md-10">
                  {/* ngIf: dataassets.length>=100 */}
                  <div className="inner-box">
                    <div className="shimmercard br hide" id="assets-shimmer">
                      <div className="wrapper">
                        <div className="comment br animate w80" />
                        <div className="comment br animate" />
                      </div>
                    </div>
                  </div>
                  {/* ngIf: dataassets.length > 0 */}
                  <div className="inner-box ">
                    <div style={{
                      height: scroll ? "58px" : null
                    }} className={`d-flex align-items-center  top-tab-menu justify-content-between  ${incomeData.length >= 1 && isDataLoading == false ? "FixdgHeader" : null}`}
                    >

                      <div className="d-flex align-items-center">
                        {incomeData.length >= 1 && (
                          <FintooCheckbox
                            checked={tab == "tab1" && selectedCategories.length === incomeData.length}
                            onChange={() => {
                              if (selectedCategories.length === incomeData.length) {
                                setSelectedCategories([]);
                                setDeleteToggle(false);
                              } else {
                                const allIds = incomeData.map(income => income.id);
                                setSelectedCategories(allIds);
                                setDeleteToggle(true);
                              }
                            }}
                          />
                        )}
                        {incomeData.length >= 1 && (
                          <div>
                            <div className="total-amt">
                              <span
                                style={{
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                ADDED INCOME{" "}
                              </span>
                              <span className="info-hover-left-box ms-2">
                                <span className="icon">
                                  <img
                                    alt="More information"
                                    src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                  />
                                </span>
                                <span className="msg">
                                  <b>Note : </b>
                                  All the calculations related to your Income &
                                  Expense are done as per the calendar year. Ex: If
                                  you start your financial planning in the month of
                                  September, the gross inflow analysis for the first
                                  year will be calculated from September to December.
                                </span>
                              </span>
                            </div>
                          </div>

                        )}
                      </div>
                      <>
                        {
                          selectedCategories.length > 0 &&
                          deletetoggle == true && (
                            <span
                              onClick={() => {
                                handleShow();
                                setUpdateForm(false);
                                setAddForm(true);
                              }}
                              className="opt-options-2 pointer"
                              style={{ marginRight: "2rem" }}
                            >
                              <MdDelete style={{ color: "#042b62", fontSize: "1.6rem" }} />
                            </span>
                          )
                        }
                        {/* {incomeData.length > 1 &&
                          lifecycleStatus == 2 &&
                          income.income_isRecurring == false && (
                            <div className="opt-options">
                              <span
                                onClick={() => {
                                  handleShow();
                                  setUpdateForm(false);
                                  setAddForm(true);
                                  setSelectedIncomeId(income.id);
                                  setDeleteIncomeName(
                                    income.income_category_type +
                                    " - " +
                                    income.income_name
                                  );
                                }}
                                style={{ marginRight: "2rem" }}
                                className="opt-options-2"
                              >
                                <MdDelete />
                              </span>
                            </div>
                          )}

                        {recurringTotal > 1 &&
                          lifecycleStatus == 2 &&
                          income.income_isRecurring == true && (
                            <div className="opt-options">
                              <span
                                onClick={() => {
                                  handleShow();
                                  setUpdateForm(false);
                                  setAddForm(true);
                                  setSelectedIncomeId(income.id);
                                  setDeleteIncomeName(
                                    income.income_category_type +
                                    " - " +
                                    income.income_name
                                  );
                                }}
                                style={{ marginRight: "2rem" }}
                                className="opt-options-2"
                              >
                                <MdDelete />
                              </span>
                            </div>
                          )} */}

                      </>
                    </div>
                    {/* {recurringTotal == 1 && lifecycleStatus == 2 && (
                      <div className="mt-2">
                        <div style={{ color: "#F0806D", marginLeft: "30px" }}>
                          Note : In income, there should be atleast one
                          self/spouse's recurring income.
                        </div>
                      </div>
                    )} */}
                    <div>
                      {isDataLoading && (<div>
                        <div className=" inner-container mt-4 pt-4">
                          <div className="shine w-25 mb-1" style={{ height: '.7rem' }}></div>
                          <div className="shine w-100" style={{ height: '.7rem' }}></div>
                        </div>
                        <div className=" inner-container mt-4 pt-4">
                          <div className="shine w-25 mb-1" style={{ height: '.7rem' }}></div>
                          <div className="shine w-100" style={{ height: '.7rem' }}></div>
                        </div>
                      </div>)}
                    </div>
                    {tab === "tab1" && incomeData &&
                      incomeData.map((income, index) => (
                        <div key={index} className="d-flex align-items-center">
                          <FintooCheckbox
                            id={income.id}
                            checked={selectedCategories.includes(income.id)}
                            title={income.title}
                            onChange={() => {
                              setSelectedCategories((prevSelected) => {
                                if (tab == "tab1" && prevSelected.includes(income.id)) {
                                  const updatedSelection = prevSelected.filter((id) => id !== income.id);
                                  setDeleteToggle(updatedSelection.length > 0); // Check if any checkbox is still selected
                                  return updatedSelection;
                                } else {
                                  setDeleteToggle(true);
                                  return [...prevSelected, income.id];
                                }
                              });
                            }}
                          />
                          <div key={income} className="inner-container mt-4 w-100">
                            {/* ngIf: (dataassets.length==1 && log_current_status>1) */}
                            {
                              isDataLoading == false && (
                                <h4>
                                  {income.income_category_type}{" "}
                                  {income.income_name
                                    ? " - " + income.income_name
                                    : ""}
                                </h4>
                              )
                            }
                            {
                              isDataLoading == false && (

                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="display-style">
                                      <span>
                                        Value (
                                        {frequencylist[income.income_frequency]}):
                                      </span>
                                      <p
                                        className="invest-show "
                                        title={`${indianRupeeFormat(
                                          income.income_amount
                                        )}`}
                                      >
                                        {indianRupeeFormat(income.income_amount)}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="display-style"></div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="display-style">
                                      <span>Member:</span>
                                      <p>
                                        {income.incomeforname
                                          ? income.incomeforname
                                          : "Family"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    {lifecycleStatus != 2 && (
                                      <div className="opt-options">
                                        <span>
                                          <BsPencilFill
                                            onClick={() => {
                                              getIncomeData(income.id, 0);
                                              setUpdateForm(true);
                                              setAddForm(false);
                                              setIncomeId(income.id);
                                              scrollToIncomeForm();
                                            }}
                                          />
                                        </span>
                                        {/* <span
                                          onClick={() => {
                                            handleShow();
                                            setUpdateForm(false);
                                            setAddForm(true);
                                            setSelectedIncomeId(income.id);
                                            setDeleteIncomeName(
                                              income.income_category_type +
                                              " - " +
                                              income.income_name
                                            );
                                          }}
                                          className="opt-options-2"
                                        >
                                          <MdDelete />
                                        </span> */}
                                      </div>
                                    )}

                                    {/* {incomeData.length > 1 &&
                                      lifecycleStatus == 2 &&
                                      income.income_isRecurring == false && (
                                        <div className="opt-options">
                                          <span
                                            onClick={() => {
                                              handleShow();
                                              setUpdateForm(false);
                                              setAddForm(true);
                                              setSelectedIncomeId(income.id);
                                              setDeleteIncomeName(
                                                income.income_category_type +
                                                " - " +
                                                income.income_name
                                              );
                                            }}
                                            className="opt-options-2"
                                          >
                                            <MdDelete />
                                          </span>
                                        </div>
                                      )}

                                    {recurringTotal > 1 &&
                                      lifecycleStatus == 2 &&
                                      income.income_isRecurring == true && (
                                        <div className="opt-options">
                                          <span
                                            onClick={() => {
                                              handleShow();
                                              setUpdateForm(false);
                                              setAddForm(true);
                                              setSelectedIncomeId(income.id);
                                              setDeleteIncomeName(
                                                income.income_category_type +
                                                " - " +
                                                income.income_name
                                              );
                                            }}
                                            className="opt-options-2"
                                          >
                                            <MdDelete />
                                          </span>
                                        </div>
                                      )} */}
                                    {lifecycleStatus == 2 && (
                                      <div className="opt-options">
                                        <span>
                                          <BsPencilFill
                                            onClick={() => {
                                              getIncomeData(income.id, 0);
                                              setUpdateForm(true);
                                              setAddForm(false);
                                              setIncomeId(income.id);
                                              scrollToIncomeForm();
                                            }}
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            }
                            {/* ngIf: dataassets.length == 1 && asset_life_cycle_status == 2 */}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* end ngIf: dataassets.length > 0 */}
                </div>
              </div>
              {incomeData.length < 20 && (
                <div className="removefixheader">
                  <div className="col-md-10 col-12">
                    <div className="accordion mt-2">
                      <div className="accordion-panel active">
                        <div className="accordion-header d-flex justify-content-between">
                          <h4 className="accordion-heading">
                            <img
                              className="accordian-img"
                              src={imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/income_add.svg"}
                              alt="Family details"
                            />
                            Add Income
                          </h4>
                          <div
                            onClick={() => setShowIncomeView(!showIncomeView)}
                            className={`${DGstyles.HideSHowicon} hideShowIconCustom`}
                          >
                            {showIncomeView == true ? <>-</> : <>+</>}
                          </div>
                        </div>
                        {showIncomeView && (
                          <div
                            className={`accordion-content  family ${DGstyles.bgincomeexpense}`}
                          >
                            <div className="row py-2">
                              <div className="col-10">
                                <span>
                                  <label className="">
                                    Nature Of Payment : ({selectedOption})
                                  </label>
                                  {/* end ngIf: categorydetail!=''  */}
                                </span>
                                <ul className="card-list">
                                  {income_options.map((v, i) => (
                                    <React.Fragment key={i}>
                                      <li
                                        onClick={() => {
                                          setSelectedOption(v.title);
                                          handleCategoryClick(v, "income");
                                          setIncomeFootnote("");
                                        }}
                                        className={`li-options ${selectedOption == v.title
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        {/* <input type="radio" value="5" id="type-5" name="type" data-show=".recurring-group" // ref="Father" ng-model="family.relation_id" className="" > */}
                                        <label
                                          className="LabelName"
                                          htmlFor="type-2"
                                        >
                                          <img alt={v.title} src={v.image} />
                                          {v.title}
                                        </label>
                                      </li>
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </div>
                            </div>

                            <div ref={cntRef}>
                              {
                                <form
                                  noValidate="novalidate"
                                  name="goldassetform"
                                >
                                  <div className="row d-flex align-items-center py-md-3">
                                    <div className="col-md-5 col-12 ">
                                      <div className="flex-grow-1 custom-input">
                                        <div className={`form-group mt-2 ${incomeName ? "inputData" : null}`} style={{ paddingTop: "15px" }}>
                                          <input type="text" name="income_Name"
                                            value={incomeName}
                                            onChange={(e) => {
                                              setIncomeName(e.target.value);
                                              handleIncomeName(e.target.value);
                                            }}
                                            required autoComplete="off" />
                                          <span class="highlight"></span>
                                          <span class="bar"></span>
                                          <label for="name">Income Name*</label>
                                          <div className="error">
                                            {incomeNameError}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {incomeCategoryId === 8 && (
                                      <div className="col-md-5 col-12">
                                        <div className="material">
                                          <Form.Label>Income Type*</Form.Label>
                                          <Select
                                            classNamePrefix="sortSelect"
                                            isSearchable={false}
                                            styles={customStyles}
                                            onChange={(v) =>
                                              handleIncomeType(v.value)
                                            }
                                            value={sortOptionsIncomeType.filter(
                                              (v) => v.value == incomeType
                                            )}
                                            options={sortOptionsIncomeType}
                                          />
                                        </div>
                                        <div className="error">
                                          {incomeTypeError}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="row  d-flex align-items-center">
                                    <div className="col-md-5 col-12  d-flex align-items-baseline">
                                      <div className="flex-grow-1 custom-input">
                                        <div className={`form-group  ${incomeAmount ? "inputData" : null}`} style={{ paddingTop: "17px" }}>
                                          <span> <input type="number" name="netincomevalue"
                                            min="1"
                                            className=""
                                            maxLength="9"
                                            onChange={(e) => {
                                              checkIncomeAmount(e);
                                            }}
                                            onInput={maxLengthCheck}
                                            value={incomeAmount}
                                            required autoComplete="off" />
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label for="name">Net Income Value*</label></span>
                                          <span className="info-hover-box" style={{ top: '1.7rem' }}>
                                            <span className="icon">
                                              <img
                                                alt="More information"
                                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                              />
                                            </span>
                                            <span className="msg">
                                              This is the Field where you need
                                              to add your Net Income from
                                              Business. The Frequency of the
                                              Business income you can add by
                                              selecting a recurring option.
                                            </span>
                                          </span>
                                        </div>

                                        {/* <p>{simpleValidator.current.message('incomeAmount', incomeAmount, ['required'], {messages: {required:'Please enter net income value'}})}</p> */}
                                        <div className="error">
                                          {incomeAmountError}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-5 mt-md-1 mt-4 col-12">
                                      <div className="material">
                                        <Form.Label>Income From*</Form.Label>
                                        {familyData && incomeForMember && (
                                          <Select
                                            classNamePrefix="sortSelect"
                                            isSearchable={false}
                                            styles={customStyles}
                                            onChange={(v) => {
                                              setIncomeForMember(v.value);
                                              handleIncomeMemberEnddate(v);
                                            }}
                                            value={familyData.filter(
                                              (v) => v.value == incomeForMember
                                            )}
                                            options={familyData.filter(
                                              (v) => v.value != 0
                                            )}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row py-3">
                                    <div className="col-md-8 col-12">
                                      <div className="d-md-flex">
                                        <span className="">
                                          Is This Income Fixed Or Variable?*
                                        </span>
                                        <div className="d-flex ps-md-5 ms-md-2 SwitchElement">
                                          <div>Variable</div>
                                          <Switch
                                            onChange={() =>
                                              setIncomeIsFixed((v) => !v)
                                            }
                                            // boxShadow='0 0 2px 3px #042b62'
                                            activeBoxShadow='0 0 2px 3px #042b62'
                                            checked={incomeIsFixed}
                                            className="react-switch px-2 "
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={20}
                                            width={40}
                                            onColor="#042b62"
                                            offColor="#d8dae5"
                                          />
                                          <div>Fixed</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row pt-md-4 pt-3">
                                    <div className="col-md-8 col-12">
                                      <div className="d-md-flex d-grid">
                                        <span className="">
                                          Is The Income One Time Or Recurring?*
                                        </span>
                                        <div className="d-flex ms-md-4">
                                          <span className="info-hover-box  ps-md-4">
                                            <span className="icon">
                                              <img
                                                alt="More information"
                                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                              />
                                            </span>
                                            <span className="msg">
                                              Date when you are expecting to
                                              receive a lumpsum amount in the
                                              form of maturity, bonus, etc.
                                            </span>
                                          </span>
                                          <div className="d-flex ms-md-4  SwitchElement">
                                            <div
                                              className="ps-2 ps-md-3"
                                              style={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              One Time
                                            </div>
                                            <Switch
                                              onChange={(v) => {
                                                if (
                                                  recurringTotal == 1 &&
                                                  recurFlag == true &&
                                                  addForm == false
                                                ) {
                                                  scrollToIncomeList();
                                                  setUpdateForm(true);
                                                  // setAddForm(true);
                                                  // resetIncomeForm();
                                                } else {
                                                  setIsRecurring((v) => !v);
                                                }
                                              }}
                                              checked={isRecurring}
                                              className="react-switch px-2"
                                              activeBoxShadow='0 0 2px 3px #042b62'
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              height={20}
                                              width={40}
                                              onColor="#042b62"
                                              offColor="#d8dae5"
                                            />
                                            <div>Recurring</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {isRecurring && (
                                    <>
                                      <div className="row py-3">
                                        <div className="col-md-5 col-12">
                                          <div className="material">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Income Start Date*"
                                              className="mb-0 material"
                                              style={{
                                                borderBottom:
                                                  "1px solid #dadada",
                                                paddingTop: "19px",
                                              }}
                                            >
                                              <ReactDatePicker
                                                // setDate={(date)=>setDate(date,'startDate')}
                                                setDate={(date) => {
                                                  setIncomeStartDate(
                                                    date,
                                                    "startDate",
                                                    "income"
                                                  );
                                                }}
                                                select_date={incomeStartDate}
                                                maxDate={""}
                                                minDate={moment().toDate()}
                                                className="pt-2"
                                              />
                                            </FloatingLabel>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row py-2">
                                        <span className="">Upto*</span>
                                        <div className="col-md-5 col-12">
                                          {/* <p>11-{incomeEndDate} </p> */}
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="End Date Of Annual Income"
                                            className="mb-0 material"
                                            style={{
                                              borderBottom: "1px solid #dadada",
                                              paddingTop: "19px",
                                            }}
                                          >
                                            <ReactDatePicker
                                              select_date={incomeEndDate}
                                              setDate={(enddate) =>
                                                setDate(
                                                  enddate,
                                                  "endDate",
                                                  "income"
                                                )
                                              }
                                              minDate={
                                                selectedEndAge === 0
                                                  ? moment(incomeStartDate).add(1, 'month')
                                                  : incomeConditionDate
                                              }
                                              maxDate={""}
                                              className="pt-2"
                                            />
                                          </FloatingLabel>
                                          <p className="error">
                                            {endDateError}
                                          </p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                          <div className="dark-label mt-3 mt-md-4">
                                            <div
                                              className="d-md-flex d-grid "
                                              style={{ clear: "both" }}
                                            >
                                              <FintooRadio2
                                                checked={selectedEndAge == 1}
                                                onClick={() => {
                                                  setselectedEndAge(1);
                                                  handleIncomeEndDate(1);
                                                }}
                                                title="Upto Retirement Age"
                                              />
                                              <FintooRadio2
                                                checked={selectedEndAge == 2}
                                                className=""
                                                onClick={() => {
                                                  setselectedEndAge(2);
                                                  handleIncomeEndDate(2);
                                                }}
                                                title="Upto Life Expectancy Age"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row py-3">
                                        <div className="col-md-5 col-12">
                                          <div className="material ">
                                            <div>
                                              <Form.Label>
                                                Annual Growth Rate (%)* :
                                                {incomeAnnualIncrease}
                                              </Form.Label>
                                            </div>
                                            <div className={`${incomeAnnualIncrease < 2 && "sl-hide-left"} ${incomeAnnualIncrease > 28 && "sl-hide-right"}`}>
                                              <Slider
                                                // x={incomeAnnualIncrease}
                                                min={0}
                                                max={30}
                                                value={incomeAnnualIncrease}
                                                onChange={(x) => {
                                                  setIncomeAnnualIncrease(
                                                    Math.round(
                                                      (parseFloat(x) +
                                                        Number.EPSILON) *
                                                      100
                                                    ) / 100
                                                  );
                                                  // setSliderValue(x)
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row py-3">
                                        <div className="col-md-5 col-12">
                                          <div className="material">
                                            <Form.Label>Frequency* </Form.Label>
                                            <Select
                                              classNamePrefix="sortSelect"
                                              isSearchable={false}
                                              styles={customStyles}
                                              onChange={(selectedOption) => {
                                                setIncomeFrequency(
                                                  selectedOption.value
                                                );
                                                setIncomeFrequencyChange(
                                                  (prevValue) => prevValue + 1
                                                );
                                              }}
                                              value={sortOptionsFrequency.filter(
                                                (f) =>
                                                  f.value === incomeFrequency
                                              )}
                                              options={sortOptionsFrequency}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {isRecurring == false && (
                                    <>
                                      <div className="row py-3">
                                        <div className="col-md-5 col-12">
                                          <div className="material">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Date of One-Time Income*"
                                              className="mb-0 material d-flex"
                                              style={{
                                                borderBottom:
                                                  "1px solid #dadada",
                                                paddingTop: "19px",
                                              }}
                                            >
                                              <ReactDatePicker
                                                setDate={(incomeStartDate) =>
                                                  setDate(
                                                    incomeStartDate,
                                                    "startDate",
                                                    "income"
                                                  )
                                                }
                                                select_date={incomeStartDate}
                                                maxDate={
                                                  new Date(
                                                    new Date().getFullYear(),
                                                    11,
                                                    31
                                                  )
                                                }
                                                minDate={moment().toDate()}
                                                className="pt-2"
                                              />
                                              <span className="info-hover-box">
                                                <span className="icon">
                                                  <img
                                                    alt="More information"
                                                    src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                                  />
                                                </span>
                                                <span className="msg">
                                                  In this section, you can add
                                                  your current year’s one-time
                                                  income.
                                                </span>
                                              </span>
                                            </FloatingLabel>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <div className="row">
                                    <div className="col-md-9 col-12 custom-input">
                                      <div className={`form-group mt-1 ${incomeFootnote ? "inputData" : null}`}>
                                        <input type="text" name="Remarks" onChange={(e) =>
                                          setIncomeFootnote(e.target.value)
                                        } value={incomeFootnote} autoComplete="off" />
                                        <span class="highlight"></span>
                                        <span class="bar"></span>
                                        <label for="name">Remarks</label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row py-2">
                                    <div className=" text-center">
                                      <div>
                                        <div className="btn-container">
                                          <div className="d-flex justify-content-center">
                                            <a
                                              href={
                                                process.env.PUBLIC_URL + "/datagathering/about-you"
                                              }
                                            >
                                              <div className="previous-btn form-arrow d-flex align-items-center">
                                                <FaArrowLeft />
                                                <span className="hover-text">
                                                  &nbsp;Previous
                                                </span>
                                              </div>
                                            </a>
                                            {addForm && (
                                              <button
                                                onClick={(e) =>
                                                  saveIncomeData(e)
                                                }
                                                className="default-btn gradient-btn save-btn"
                                              >
                                                Save & Add More
                                              </button>
                                            )}
                                            {updateForm && (
                                              <div>
                                                <button
                                                  onClick={(e) =>
                                                    cancelFormData(e, "income")
                                                  }
                                                  className="default-btn gradient-btn save-btn"
                                                >
                                                  Cancel
                                                </button>
                                                <button
                                                  onClick={(e) =>
                                                    updateIncomeData(e)
                                                  }
                                                  className="default-btn gradient-btn save-btn"
                                                >
                                                  Update
                                                </button>
                                              </div>
                                            )}
                                            <div
                                              className="next-btn form-arrow d-flex align-items-center"
                                              onClick={() => {
                                                ScrollToTop();
                                                setTab("tab2")
                                              }
                                              }
                                            >
                                              <span
                                                className="hover-text"
                                                style={{ maxWidth: 100 }}
                                              >
                                                Continue&nbsp;
                                              </span>
                                              <FaArrowRight />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {incomeData.length >= 20 && updateForm == false && (
                <div className=" py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container">
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginRight: "15%" }}
                        >
                          <a
                            href={
                              process.env.PUBLIC_URL + "/datagathering/about-you"
                            }
                          >
                            <div className="previous-btn form-arrow d-flex align-items-center">
                              <FaArrowLeft />
                              <span className="hover-text">&nbsp;Previous</span>
                            </div>
                          </a>

                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => {
                              ScrollToTop();
                              setTab("tab2")
                            }
                            }
                          >
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Continue&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={` ${tab == "tab2" ? "d-block" : "d-none"}`}>
              <div className="row">
                <div className={`col-md-2 ${DGstyles.moneyjarcontainer}`}>
                  <h5
                    className="accordion-heading"
                    style={{
                      marginBottom: "10px",
                      fontSize: "13.2px",
                      fontWeight: 700,
                    }}
                  >
                    Income Vs Expense
                  </h5>
                  <div
                    className="money-jar"
                    style={{
                      position: "relative",
                      width: "70px",
                      margin: "0 auto",
                    }}
                  >
                    <span
                      style={{
                        height:
                          incomeRetirementPercent > 0
                            ? incomeRetirementPercent > 50
                              ? incomeRetirementPercent + "%"
                              : incomeRetirementPercent + "%"
                            : "100%",
                        background:
                          totalIncome - totalExpense > 0
                            ? "#042b62"
                            : totalIncome - totalExpense < 0
                              ? "#ff5245"
                              : "#fff",
                        transition: "0.6s ease all",
                        width: "100%",
                        display: "block",
                        // position: "absolute",
                        // bottom: "1px",
                      }}
                      id="expense-jar"
                      className="expense-jar"
                    >
                      <img
                        style={{
                          position: "relative",
                          width: "70px",
                          display: "block",
                          marginTop: "3rem"
                        }}
                        src={process.env.REACT_APP_STATIC_URL + "media/DG/jar.svg"}
                        alt="Rupee"
                      />
                    </span>
                  </div>
                  <br></br>
                  <span
                    className="mb-2 total-amt text-center"
                    style={{ fontSize: "16px" }}
                  >
                    Total{" "}
                    {totalIncome - totalExpense < 0 ? " Deficit" : "Saving"}
                    <span className="info-hover-box ms-2">
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        The below Calculation indicated<br></br>Total Income -
                        Total Expense<br></br>= Total Saving / Deficit
                      </span>
                    </span>
                  </span>
                  <br></br>
                  <b style={{ fontSize: "16px" }}>
                    {formatPrice(totalIncome)} - {formatPrice(totalExpense)}
                  </b>
                  <br></br>={" "}
                  {totalIncome - totalExpense <= 0 && (
                    <span
                      className="error"
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      ({formatPrice(totalExpense - totalIncome)})
                    </span>
                  )}
                  {totalIncome - totalExpense > 0 && (
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {formatPrice(totalIncome - totalExpense)}
                    </span>
                  )}
                  <div style={{ fontSize: "14px" }}>
                    <b>
                      (Saving Till 31<sup>st</sup> Dec,{" "}
                      {moment().format("YYYY")})
                    </b>
                  </div>
                </div>

                <div className="col-md-10">
                  {expenseData.length > 19 && (
                    <div>
                      <p
                        style={{
                          color: "#F0806D",
                          fontSize: "15px",
                          padding: "5px",
                        }}
                      >
                        You have reached maximum limit of 20 to add Expenses,
                        Please delete some records to add new expenses or can
                        edit the existing expenses.
                      </p>
                    </div>
                  )}

                  <div style={{
                    height: scroll ? "58px" : null
                  }} className={`d-flex align-items-center  top-tab-menu justify-content-between ${scroll ? "DGsubheaderFix" : null}`}>
                    <div className="d-flex align-items-center">
                      {expenseData.length >= 1 && (
                        <FintooCheckbox
                          checked={tab == "tab2" && selectedExpenseCategories.length === expenseData.length}
                          onChange={() => {
                            if (tab == "tab2" && selectedExpenseCategories.length === expenseData.length) {
                              setSelectedExpenseCategories([]);
                              setDeleteToggleexpense(false);
                            } else {
                              const allIds = expenseData.map(income => income.id);
                              setSelectedExpenseCategories(allIds);
                              setDeleteToggleexpense(true);
                            }
                          }}
                        />
                      )
                      }

                      {expenseData.length >= 1 && (
                        <div className="total-amt">
                          <span
                            style={{
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            ADDED EXPENSES
                          </span>
                          <span className="info-hover-left-box ms-2">
                            <span className="icon">
                              <img
                                alt="More information"
                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                              />
                            </span>
                            <span className="msg">
                              <b>Note : </b>
                              All the calculations related to your Income & Expense
                              are done as per the calendar year. Ex: If you start
                              your financial planning in the month of September, the
                              gross outflow analysis for the first year will be
                              calculated from September to December.
                            </span>
                          </span>
                        </div>
                      )}
                    </div>

                    {
                      selectedExpenseCategories.length > 0 &&
                      deletetoggleexpense == true && (
                        <span
                          onClick={() => {
                            handleShow();
                            setUpdateForm(false);
                            setAddForm(true);
                          }}
                          className="opt-options-2 pointer"
                          style={{
                            marginRight: "2rem"
                          }}
                        >
                          <MdDelete style={{ color: "#042b62", fontSize: "1.6rem" }} />
                        </span>
                      )
                    }
                  </div>
                  <div className="inner-box ">
                    {tab === "tab2" && expenseData &&
                      expenseData.map((expense, index) => (
                        <div key={index} className="d-flex align-items-center">
                          <FintooCheckbox
                            id={expense.id}
                            checked={selectedExpenseCategories.includes(expense.id)}
                            title={expense.title}
                            onChange={() => {
                              setSelectedExpenseCategories((prevSelected) => {
                                if (tab == "tab2" && prevSelected.includes(expense.id)) {
                                  const updatedSelection = prevSelected.filter((id) => id !== expense.id);
                                  setDeleteToggleexpense(updatedSelection.length > 0); // Check if any checkbox is still selected
                                  return updatedSelection;
                                } else {
                                  setDeleteToggleexpense(true);
                                  return [...prevSelected, expense.id];
                                }
                              });
                            }}
                          />

                          <div key={expense} className="inner-container w-100">
                            {/* ngIf: (dataassets.length==1 && log_current_status>1) */}
                            <h4>
                              {expense.expenses_categoryId}{" "}
                              {expense.expenses_name
                                ? " - " + expense.expenses_name
                                : ""}
                            </h4>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="display-style">
                                  <span>
                                    Value (
                                    {frequencylist[expense.expenses_frequency]}):
                                  </span>
                                  <p
                                    className="invest-show "
                                    title={`${indianRupeeFormat(
                                      expense.expenses_amount
                                    )}`}
                                  >
                                    {indianRupeeFormat(expense.expenses_amount)}
                                  </p>
                                </div>
                              </div>
                              {/* <h2>{{asset_life_cycle_status}}</h2> */}
                              <div className="col-md-4">
                                <div className="display-style">
                                  <span>Type:</span>
                                  <p>
                                    {expense.expenses_isfixed
                                      ? "Fixed"
                                      : "Variable"}{" "}
                                    {expense.expenses_ismandatory
                                      ? "Mandatory"
                                      : "Wishful"}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="display-style">
                                  <span>Member:</span>
                                  <p>
                                    {expense.expenseforname
                                      ? expense.expenseforname
                                      : "Family"}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                {lifecycleStatus != 2 && (
                                  <div className="opt-options">
                                    <span>
                                      <BsPencilFill
                                        onClick={() => {
                                          getExpenseData(expense.id, 0);
                                          setUpdateForm(true);
                                          setAddForm(false);
                                          setExpenseId(expense.id);
                                          scrollToExpenseForm();
                                        }}
                                      />
                                    </span>
                                    {/* <span
                                      onClick={() => {
                                        handleExpenseDeletePopup();
                                        setUpdateForm(false);
                                        setAddForm(true);
                                        setSelectedExpenseId(expense.id);
                                        setDeleteExpenseName(
                                          expense.expenses_categoryId +
                                          " - " +
                                          expense.expense_name
                                        );
                                      }}
                                      className="opt-options-2"
                                    >
                                      <MdDelete />
                                    </span> */}
                                  </div>
                                )}

                                {/* {expenseData.length > 1 &&
                                  lifecycleStatus == 2 &&
                                  expense.expenses_isRecurring == false && (
                                    <div className="opt-options">
                                      <span
                                        onClick={() => {
                                          handleExpenseDeletePopup();
                                          setSelectedExpenseId(expense.id);
                                          setDeleteExpenseName(
                                            expense.expenses_categoryId +
                                            " - " +
                                            expense.expenses_name
                                          );
                                          setUpdateForm(false);
                                          setAddForm(true);
                                        }}
                                        className="opt-options-2"
                                      >
                                        <MdDelete />
                                      </span>
                                    </div>
                                  )} */}

                                {/* {expenseRecurringTotal > 1 &&
                                  lifecycleStatus == 2 &&
                                  expense.expenses_isRecurring == true && (
                                    <div className="opt-options">
                                      <span
                                        onClick={() => {
                                          handleExpenseDeletePopup();
                                          setUpdateForm(false);
                                          setAddForm(true);
                                          setSelectedExpenseId(expense.id);
                                          setDeleteExpenseName(
                                            expense.expenses_categoryId +
                                            " - " +
                                            expense.expenses_name
                                          );
                                        }}
                                        className="opt-options-2"
                                      >
                                        <MdDelete />
                                      </span>
                                    </div>
                                  )} */}
                                {lifecycleStatus == 2 && (
                                  <div className="opt-options">
                                    <span>
                                      <BsPencilFill
                                        onClick={() => {
                                          getExpenseData(expense.id, 0);
                                          setUpdateForm(true);
                                          setAddForm(false);
                                          setExpenseId(expense.id);
                                          scrollToExpenseForm();
                                        }}
                                      />
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* ngIf: dataassets.length == 1 && asset_life_cycle_status == 2 */}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {expenseData.length < 20 && (
                  <div className="removefixheader ">
                    <div className="col-md-10 col-12">
                      <div className="accordion mt-2">
                        <div className="accordion-panel active">
                          <div className="accordion-header d-flex justify-content-between">
                            <h3
                              className="accordion-heading"
                              style={{ paddingBottom: 0 }}
                            >
                              <img
                                tyle={{
                                  verticalAlign: "middle",
                                }}
                                className="accordian-img"
                                src={imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expense_add.svg"}
                                alt="expense add"
                              />
                              <span
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {" "}
                                Your Expenses
                              </span>
                            </h3>
                            <div
                              onClick={() => setShowExpenseView(!showExpenseView)}
                              className={`${DGstyles.HideSHowicon} hideShowIconCustom`}
                            >
                              {showExpenseView == true ? <>-</> : <>+</>}
                            </div>
                          </div>
                          {showExpenseView && (
                            <div
                              className={`accordion-content  family ${DGstyles.bgincomeexpense}`}
                            >
                              <span>
                                <label className="">
                                  Category : ({selectedOption1})
                                </label>
                                {/* end ngIf: categorydetail!=''  */}
                              </span>

                              <div className="row py-2">
                                <div className="col-10">
                                  <ul className="card-list">
                                    {expense_options.map((v, i) => (
                                      <React.Fragment key={i}>
                                        <li
                                          onClick={() => {
                                            setSelectedOption1(v.title);
                                            handleCategoryClick(v, "expense");
                                            setExpenseFootnote("");
                                          }}
                                          className={`li-options ${selectedOption1 == v.title
                                            ? "active"
                                            : ""
                                            }`}
                                        >
                                          {/* <input type="radio" value="5" id="type-5" name="type" data-show=".recurring-group" // ref="Father" ng-model="family.relation_id" className="" > */}
                                          <label htmlFor="type-2">
                                            <img alt={v.title} src={v.image} />
                                            {v.title}
                                          </label>
                                        </li>
                                      </React.Fragment>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              {expenseCategoryId == 104 && (
                                <div>
                                  <p
                                    style={{
                                      color: "#F0806D",
                                      fontSize: "15px",
                                      padding: "5px",
                                      position: "relative", top: "0"
                                    }}
                                  >
                                    If you already have the required amount to
                                    pay for your vacation, you must add it in
                                    the ‘Expenses’ section.<br></br>
                                    In case you wish to start saving for your
                                    vacation, add it in the ‘Goals’ section.
                                  </p>
                                </div>
                              )}
                              <div className="row py-2">
                                <div className="col-md-5 col-12 d-flex align-items-baseline custom-input">
                                  <div className={`form-group mt-1 w-100 ${expenseName ? "inputData" : null}`}>
                                    <input type="text" name="expenseName" onChange={(e) => {
                                      setExpenseName(e.target.value);
                                      handleExpenseName(e.target.value);
                                    }}
                                      value={expenseName} required autoComplete="off" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="name">Expense Name*</label>
                                  </div>
                                  <div className="error">{expenseNameError}</div>
                                </div>
                                <div className="col-md-5 col-12  custom-input">
                                  <div className={`form-group mt-1 w-100 ${expenseAmount ? "inputData" : null}`}>
                                    <input type="number" name="expenseAmount" min="1"
                                      maxLength="9"
                                      onChange={(e) => {
                                        checkExpenseAmount(e);
                                      }}
                                      onInput={maxLengthCheck}
                                      value={expenseAmount} required autoComplete="off" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="name">Expense Value*</label>
                                  </div>
                                  <div className="error">
                                    {expenseAmountError}
                                  </div>

                                </div>
                              </div>
                              <div className="row py-2">
                                <div className="col-md-5 col-12">
                                  <div className="material">
                                    <Form.Label>Expense For*</Form.Label>
                                    {familyData && expenseForMember >= 0 && (
                                      <Select
                                        classNamePrefix="sortSelect"
                                        isSearchable={false}
                                        styles={customStyles}
                                        onChange={(v) => {
                                          setExpenseForMember(v.value);
                                          handleExpenseMemberEnddate(v);
                                        }}
                                        value={familyData.filter(
                                          (v) => v.value == expenseForMember
                                        )}
                                        options={familyData}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="row py-3">
                                <div className="col-md-8 col-12">
                                  <div className="d-md-flex">
                                    <span className="">
                                      Is This Expense Fixed Or Variable?*
                                    </span>
                                    <div className="d-flex ps-md-4 SwitchElement">
                                      <div>Variable</div>
                                      <Switch
                                        onChange={() =>
                                          setExpenseFixed((v) => !v)
                                        }
                                        checked={expenseIsFixed}
                                        className="react-switch px-2"
                                        activeBoxShadow='0 0 2px 3px #042b62'
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={20}
                                        width={40}
                                        onColor="#042b62"
                                        offColor="#d8dae5"
                                      />
                                      <div>Fixed</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row py-3">
                                <div className="col-md-8 col-12">
                                  <div className="d-md-flex">
                                    <span className="">
                                      Is This Expense Mandatory Or Wishful?*
                                    </span>
                                    <div className="d-flex ps-md-4 SwitchElement">
                                      <div>Wishful</div>
                                      <Switch
                                        onChange={() => setIsWish((v) => !v)}
                                        checked={isWish}
                                        className="react-switch px-2"
                                        activeBoxShadow='0 0 2px 3px #042b62'
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={20}
                                        width={40}
                                        onColor="#042b62"
                                        offColor="#d8dae5"
                                      />
                                      <div>Mandatory</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row py-3">
                                <div className="col-md-8 col-12">
                                  <div className="d-md-flex d-grid">
                                    <span className="">
                                      Is The Expense One Time Or Recurring?*
                                    </span>
                                    <div className="d-flex ms-md-4">
                                      <span className="info-hover-left-box ps-md-2">
                                        <span className="icon">
                                          <img
                                            alt="More information"
                                            src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                          />
                                        </span>
                                        <span className="msg">
                                          Date when you are expecting to spend a
                                          lumpsum amount for gifting, medical
                                          expense, etc.
                                        </span>
                                      </span>
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                        className="ps-2 ps-md-3"
                                      >
                                        One Time
                                      </div>
                                      <Switch
                                        onChange={(v) => {
                                          if (
                                            expenseRecurringTotal == 1 &&
                                            v == false &&
                                            addForm == false
                                          ) {
                                            scrollToExpenseList();
                                            setUpdateForm(true);
                                            // setAddForm(true);
                                            // resetExpenseForm();
                                          } else {
                                            setExpenseRecurring((v) => !v);
                                          }
                                        }}
                                        checked={isExpenseRecurring}
                                        className="react-switch px-2"
                                        activeBoxShadow='0 0 2px 3px #042b62'
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={20}
                                        width={40}
                                        onColor="#042b62"
                                        offColor="#d8dae5"
                                      />
                                      <div>Recurring</div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {isExpenseRecurring && (
                                <>
                                  <div className="row pt-md-3 pt-4">
                                    <div className="col-md-5 col-12">
                                      <div className="material ">
                                        <div className="d-flex justify-content-end">
                                          <Form.Label>
                                            Inflation Rate* :{" "}
                                            {expenseInflationRate}
                                          </Form.Label>
                                          <span
                                            className="info-hover-box"
                                            style={{ bottom: "38px" }}
                                          >
                                            <span className="icon">
                                              <img
                                                alt="More information"
                                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                              />
                                            </span>
                                            <span className="msg">
                                              It refers to the yearly increase
                                              in the prices of goods and
                                              commodities.
                                              <br />
                                              Ex: The cost of 1 liter of milk
                                              was approx. Rs. 45 in 2010. In
                                              2021 it costs approx. Rs.60 and in
                                              2040 it may cost approx. Rs. 100.
                                            </span>
                                          </span>
                                        </div>
                                        <div className={`${expenseInflationRate < 2 && "sl-hide-left"} ${expenseInflationRate > 18 && "sl-hide-right"}`}>
                                          <Slider
                                            min={0}
                                            max={20}
                                            value={expenseInflationRate}
                                            onChange={(x) => {
                                              setExpenseInflationRate(
                                                Math.round(
                                                  (parseFloat(x) +
                                                    Number.EPSILON) *
                                                  100
                                                ) / 100
                                              );
                                              // setSliderValue(x)
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row py-3">
                                    <div className="col-md-5 col-12">
                                      <div className="material">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Expense Start Date*"
                                          className="mb-0 material"
                                        >
                                          <div
                                            className="dt-conbx"
                                            style={{
                                              borderBottom: "1px solid #dadada",
                                              paddingTop: "19px",
                                            }}
                                          >
                                            <ReactDatePicker
                                              select_date={expenseStartDate}
                                              setDate={(date) => setDate(date, "startDate", "expense")}
                                              maxDate={expenseCategoryId === 121 ? lifeExpectancyDate : ""}
                                              minDate={expenseCategoryId === 121 ? retirementDate : moment().toDate()}
                                              className="pt-2"
                                            // readOnly={true}
                                            />
                                          </div>
                                        </FloatingLabel>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row py-2">
                                    <span className="">Upto*</span>
                                    <div className="col-md-5">
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="End Date Of Annual Expense"
                                        className="mb-0 material"
                                      >
                                        <div
                                          className="dt-conbx"
                                          style={{
                                            borderBottom: "1px solid #dadada",
                                            paddingTop: "19px",
                                          }}
                                        >
                                          <ReactDatePicker
                                            select_date={expenseEndDate}
                                            setDate={(date) => {
                                              setDate(date, "endDate", "expense");
                                              if (date != "" || date) {
                                                setExpenseEndDateError("");
                                              }
                                            }}
                                            maxDate={expenseCategoryId !== 121 ? "" : lifeExpectancyDate}
                                            minDate={
                                              expenseCategoryId !== 121
                                                ? moment(expenseStartDate).add(1, 'months')
                                                : expenseStartDate ? moment(expenseStartDate).add(1, 'months') : moment(retirementDate).add(1, 'months')
                                            }
                                          />
                                        </div>
                                      </FloatingLabel>
                                      <div style={{ position: "relative" }} className="error">
                                        {expenseEndDateError}
                                      </div>
                                    </div>

                                    <div className="col-md-6 col-12">
                                      <div className="dark-label mt-md-4 mt-3">
                                        {expenseCategoryId != 121 && (
                                          <div
                                            className="d-md-flex"
                                            style={{ clear: "both" }}
                                          >
                                            <FintooRadio2
                                              checked={selectedEndAge == 1}
                                              onClick={() => {
                                                setselectedEndAge(1);
                                                handleExpenseEndDate(1);
                                              }}
                                              title="Upto Retirement Age"
                                            />
                                            <FintooRadio2
                                              checked={selectedEndAge == 2}
                                              onClick={() => {
                                                setselectedEndAge(2);
                                                handleExpenseEndDate(2);
                                              }}
                                              title="Upto Life Expectancy Age"
                                            />
                                          </div>
                                        )}
                                        {expenseCategoryId == 121 && (
                                          <div
                                            className="d-md-flex"
                                            style={{ clear: "both" }}
                                          >
                                            <FintooRadio2
                                              checked={selectedEndAge == 2}
                                              onClick={() => {
                                                setselectedEndAge(2);
                                                handleExpenseEndDate(2);
                                              }}
                                              title="Upto Life Expectancy Age"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row py-3">
                                    <div className="col-md-5 col-12">
                                      <div className="material">
                                        <Form.Label>Frequency* </Form.Label>
                                        {(expenseCategoryId == 104 ||
                                          expenseCategoryId == 7) && (
                                            <Select
                                              classNamePrefix="sortSelect"
                                              isSearchable={false}
                                              styles={customStyles}
                                              onChange={(selectedOption) => {
                                                setExpenseFrequency(
                                                  selectedOption.value
                                                );
                                                setExpenseFrequencyChange(
                                                  (prevValue) => prevValue + 1
                                                );
                                              }}
                                              value={sortOptionsVacationFrequency.filter(
                                                (f) =>
                                                  f.value === expenseFrequency
                                              )}
                                              options={
                                                sortOptionsVacationFrequency
                                              }
                                            />
                                          )}
                                        {expenseCategoryId != 104 &&
                                          expenseCategoryId != 7 && (
                                            <Select
                                              classNamePrefix="sortSelect"
                                              isSearchable={false}
                                              styles={customStyles}
                                              onChange={(selectedOption) => {
                                                setExpenseFrequency(
                                                  selectedOption.value
                                                );
                                                setExpenseFrequencyChange(
                                                  (prevValue) => prevValue + 1
                                                );
                                              }}
                                              value={sortOptionsFrequency.filter(
                                                (f) =>
                                                  f.value === expenseFrequency
                                              )}
                                              options={sortOptionsFrequency}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {isExpenseRecurring == false && (
                                <>
                                  <div className="row py-3">
                                    <div className="col-md-5 col-12">
                                      <div className="material">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Date of One-Time Expense*"
                                          className="mb-0 material d-flex"
                                          style={{
                                            borderBottom: "1px solid #dadada",
                                            paddingTop: "19px",
                                          }}
                                        >
                                          <ReactDatePicker
                                            setDate={(date) => setDate(date, "startDate", "expense")}
                                            select_date={
                                              expenseStartDate
                                            }
                                            minDate={
                                              expenseCategoryId !== 121 ? moment().toDate() : retirementDate
                                            }
                                            maxDate={
                                              expenseCategoryId !== 121 ? new Date(new Date().getFullYear(), 11, 31) : lifeExpectancyDate
                                            }
                                          />



                                          <span className="info-hover-box">
                                            <span className="icon">
                                              <img
                                                alt="More information"
                                                src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                              />
                                            </span>
                                            <span className="msg">
                                              In this section, you can add your
                                              current year’s one-time expense.
                                            </span>
                                          </span>
                                        </FloatingLabel>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="row">
                                <div className="col-md-9 col-12 custom-input">
                                  <div className={`form-group mt-1 ${expenseFootnote ? "inputData" : null}`}>
                                    <input type="text" name="Remarks"
                                      onChange={(e) =>
                                        setExpenseFootnote(e.target.value)
                                      }
                                      value={expenseFootnote} autoComplete="off" />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label for="name">Remarks</label>
                                  </div>

                                </div>
                              </div>
                              <div className="row py-2">
                                <div className=" text-center">
                                  <div>
                                    <div className="btn-container">
                                      <div className="d-flex justify-content-center">
                                        <div
                                          className="previous-btn form-arrow d-flex align-items-center"
                                          onClick={() => {
                                            ScrollToTop();
                                            setTab("tab1")
                                          }
                                          }
                                        >
                                          <FaArrowLeft />
                                          <span className="hover-text">
                                            &nbsp;Previous
                                          </span>
                                        </div>
                                        {addForm && (
                                          <button
                                            onClick={(e) => saveExpenseData(e)}
                                            className="default-btn gradient-btn save-btn"
                                          >
                                            Save & Add More
                                          </button>
                                        )}
                                        {updateForm && (
                                          <div>
                                            <button
                                              onClick={(e) =>
                                                cancelFormData(e, "expense")
                                              }
                                              className="default-btn gradient-btn save-btn"
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              onClick={(e) =>
                                                updateExpenseData(e)
                                              }
                                              className="default-btn gradient-btn save-btn"
                                            >
                                              Update
                                            </button>
                                          </div>
                                        )}

                                        <a
                                          href={
                                            process.env.PUBLIC_URL +
                                            "/datagathering/goals"
                                          }
                                        >
                                          <div className="next-btn form-arrow d-flex align-items-center">
                                            <span
                                              className="hover-text"
                                              style={{ maxWidth: 100 }}
                                            >
                                              Continue&nbsp;
                                            </span>
                                            <FaArrowRight />
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {expenseData.length == 20 && updateForm && (
                  <div className=" ">
                    <div className="col-md-10 col-12">
                      <div className="accordion mt-2">
                        <div className="accordion-panel active">
                          <div className="accordion-header">
                            <h3
                              className="accordion-heading"
                              style={{ paddingBottom: 0 }}
                            >
                              <img
                                tyle={{
                                  verticalAlign: "middle",
                                }}
                                className="accordian-img"
                                src={imagePath + "https://static.fintoo.in/static/assets/img/income-expenses/expense_add.svg"}
                                alt="expense add"
                              />
                              <span
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {" "}
                                Your Expenses
                              </span>
                            </h3>
                          </div>
                          <div
                            className={`accordion-content  family ${DGstyles.bgincomeexpense}`}
                          >
                            <span>
                              <label className="">
                                Category : ({selectedOption1})
                              </label>
                              {/* end ngIf: categorydetail!=''  */}
                            </span>

                            <div className="row py-2">
                              <div className="col-10">
                                <ul className="card-list">
                                  {expense_options.map((v, i) => (
                                    <React.Fragment key={i}>
                                      <li
                                        onClick={() => {
                                          setSelectedOption1(v.title);
                                          handleCategoryClick(v, "expense");
                                        }}
                                        className={`li-options ${selectedOption1 == v.title
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        {/* <input type="radio" value="5" id="type-5" name="type" data-show=".recurring-group" // ref="Father" ng-model="family.relation_id" className="" > */}
                                        <label htmlFor="type-2">
                                          <img alt={v.title} src={v.image} />
                                          {v.title}
                                        </label>
                                      </li>
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            {expenseCategoryId == 104 && (
                              <div>
                                <div className="error">
                                  If you already have the required amount to pay
                                  for your vacation, you must add it in the
                                  ‘Expenses’ section.<br></br>
                                  In case you wish to start saving for your
                                  vacation, add it in the ‘Goals’ section.
                                </div>
                              </div>
                            )}
                            <div className="row py-3">
                              <div className="col-md-5 col-12 custom-input d-flex align-items-baseline">
                                <div className={`form-group mt-1 w-100 ${expenseName ? "inputData" : null}`}>
                                  <input type="text" name="expenseName_s" onChange={(e) => {
                                    setExpenseName(e.target.value);
                                    handleExpenseName(e.target.value);
                                  }}
                                    value={expenseName} required autoComplete="off" />
                                  <span class="highlight"></span>
                                  <span class="bar"></span>
                                  <label for="name">Expense Name*</label>
                                </div>
                                <div className="error">{expenseNameError}</div>
                              </div>
                              <div className="col-md-5 col-12 custom-input d-flex align-items-baseline">
                                <div className={`form-group mt-1 w-100 ${expenseAmount ? "inputData" : null}`}>
                                  <input type="text" name="expenseAmount"
                                    min="1"
                                    maxLength="13"
                                    onChange={(e) => {
                                      checkExpenseAmount(e);
                                    }}
                                    onInput={maxLengthCheck}
                                    value={expenseAmount} required autoComplete="off" />
                                  <span class="highlight"></span>
                                  <span class="bar"></span>
                                  <label for="name">Expense Value*</label>
                                </div>
                                <div className="error">{expenseAmountError}</div>
                              </div>
                            </div>
                            <div className="row py-3">
                              <div className="col-md-5 col-12">
                                <div className="material mt-md-0 mt-4">
                                  <Form.Label>Expense For*</Form.Label>
                                  {familyData && expenseForMember >= 0 && (
                                    <Select
                                      classNamePrefix="sortSelect"
                                      isSearchable={false}
                                      styles={customStyles}
                                      onChange={(v) =>
                                        setExpenseForMember(v.value)
                                      }
                                      value={familyData.filter(
                                        (v) => v.value == expenseForMember
                                      )}
                                      options={familyData}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-md-8 col-12">
                                <div className="d-md-flex">
                                  <span className="">
                                    Is This Expense Fixed Or Variable?*
                                  </span>
                                  <div className="d-flex ps-md-4 SwitchElement">
                                    <div>Variable</div>
                                    <Switch
                                      onChange={() =>
                                        setExpenseFixed((v) => !v)
                                      }
                                      checked={expenseIsFixed}
                                      className="react-switch px-2"
                                      activeBoxShadow='0 0 2px 3px #042b62'
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={20}
                                      width={40}
                                      onColor="#042b62"
                                      offColor="#d8dae5"
                                    />
                                    <div>Fixed</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row py-3">
                              <div className="col-md-8 col-12">
                                <div className="d-md-flex">
                                  <span className="">
                                    Is This Expense Mandatory Or Wishful?*
                                  </span>
                                  <div className="d-flex ps-md-4 SwitchElement">
                                    <div>Wishful</div>
                                    <Switch
                                      onChange={() => setIsWish((v) => !v)}
                                      checked={isWish}
                                      className="react-switch px-2"
                                      activeBoxShadow='0 0 2px 3px #042b62'
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={20}
                                      width={40}
                                      onColor="#042b62"
                                      offColor="#d8dae5"
                                    />
                                    <div>Mandatory</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row py-3">
                              <div className="col-md-8 col-12">
                                <div className="d-md-flex d-grid">
                                  <span className="">
                                    Is The Expense One Time Or Recurring?*
                                  </span>
                                  <div className="d-flex ms-md-4">
                                    <span className="info-hover-left-box ps-md-4">
                                      <span className="icon">
                                        <img
                                          alt="More information"
                                          src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                        />
                                      </span>
                                      <span className="msg">
                                        Date when you are expecting to spend a
                                        lumpsum amount for gifting, medical
                                        expense, etc.
                                      </span>
                                    </span>
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                      className="ps-2 ps-md-0"
                                    >
                                      One Time
                                    </div>
                                    <Switch
                                      onChange={(v) => {
                                        if (
                                          expenseRecurringTotal == 1 &&
                                          v == false &&
                                          addForm == false
                                        ) {
                                          scrollToExpenseList();
                                          setUpdateForm(true);
                                          // setAddForm(true);
                                          // resetExpenseForm();
                                        } else {
                                          setExpenseRecurring((v) => !v);
                                        }
                                      }}
                                      checked={isExpenseRecurring}
                                      className="react-switch px-2"
                                      activeBoxShadow='0 0 2px 3px #042b62'
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={20}
                                      width={40}
                                      onColor="#042b62"
                                      offColor="#d8dae5"
                                    />
                                    <div>Recurring</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {isExpenseRecurring && (
                              <>
                                <div className="row py-3">
                                  <div className="col-md-5 col-12">
                                    <div className="material ">
                                      <div className="d-flex justify-content-end">
                                        <Form.Label>
                                          Inflation Rate* :{" "}
                                          {expenseInflationRate}
                                        </Form.Label>
                                        <span
                                          className="info-hover-box"
                                          style={{ bottom: "38px" }}
                                        >
                                          <span className="icon">
                                            <img
                                              alt="More information"
                                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                            />
                                          </span>
                                          <span className="msg">
                                            It refers to the yearly increase in
                                            the prices of goods and commodities.
                                            <br />
                                            Ex: The cost of 1 liter of milk was
                                            approx. Rs. 45 in 2010. In 2021 it
                                            costs approx. Rs.60 and in 2040 it
                                            may cost approx. Rs. 100.
                                          </span>
                                        </span>
                                      </div>
                                      <Slider
                                        min={0}
                                        max={20}
                                        defaultValue={expenseInflationRate}
                                        onChange={(v) => {
                                          setSliderValue(v),
                                            setExpenseInflationRate(v);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row py-3">
                                  <div className="col-md-5 col-12">
                                    <div className="material">
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="Expense Start Date*"
                                        className="mb-0 material"
                                      >
                                        <div
                                          className="dt-conbx"
                                          style={{
                                            borderBottom: "1px solid #dadada",
                                            paddingTop: "19px",
                                          }}
                                        >
                                          <ReactDatePicker
                                            select_date={expenseStartDate}
                                            setDate={(date) => setDate(date, "startDate", "expense")}
                                            maxDate={expenseCategoryId === 121 ? lifeExpectancyDate : ""}
                                            minDate={expenseCategoryId === 121 ? retirementDate : moment().toDate()}
                                            className="pt-2"
                                          // readOnly={true}
                                          />
                                        </div>
                                      </FloatingLabel>
                                    </div>
                                  </div>
                                </div>
                                <div className="row py-2">
                                  <span className="">Upto*</span>
                                  <div className="col-md-5">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="End Date Of Annual Expense"
                                      className="mb-0 material"
                                    >
                                      <div
                                        className="dt-conbx"
                                        style={{
                                          borderBottom: "1px solid #dadada",
                                          paddingTop: "19px",
                                        }}
                                      >
                                        <ReactDatePicker
                                          select_date={expenseEndDate}
                                          setDate={(date) => setDate(date, "endDate", "expense")}
                                          maxDate={expenseCategoryId !== 121 ? "" : lifeExpectancyDate}
                                          minDate={
                                            expenseCategoryId !== 121
                                              ? moment(expenseStartDate).add(1, 'months')
                                              : expenseStartDate ? moment(expenseStartDate).add(1, 'months') : moment(retirementDate).add(1, 'months')
                                          }
                                        />

                                      </div>
                                    </FloatingLabel>
                                    <div style={{ position: "relative" }} className="error">
                                      {expenseEndDateError}
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <div className="dark-label mt-md-4 mt-3">
                                      {expenseCategoryId != 121 && (
                                        <div
                                          className="d-md-flex"
                                          style={{ clear: "both" }}
                                        >
                                          <FintooRadio2
                                            checked={selectedEndAge == 1}
                                            onClick={() => {
                                              setselectedEndAge(1);
                                              handleExpenseEndDate(1);
                                            }}
                                            title="Upto Retirement Age"
                                          />
                                          <FintooRadio2
                                            checked={selectedEndAge == 2}
                                            onClick={() => {
                                              setselectedEndAge(2);
                                              handleExpenseEndDate(2);
                                            }}
                                            title="Upto Life Expectancy Age"
                                          />
                                        </div>
                                      )}
                                      {expenseCategoryId == 121 && (
                                        <div
                                          className="d-md-flex"
                                          style={{ clear: "both" }}
                                        >
                                          <FintooRadio2
                                            checked={selectedEndAge == 2}
                                            onClick={() => {
                                              setselectedEndAge(2);
                                              handleExpenseEndDate(2);
                                            }}
                                            title="Upto Life Expectancy Age"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="row py-3">
                                  <div className="col-md-5 col-12">
                                    <div className="material">
                                      <Form.Label>Frequency*</Form.Label>
                                      {(expenseCategoryId == 104 ||
                                        expenseCategoryId == 7) && (
                                          <Select
                                            classNamePrefix="sortSelect"
                                            isSearchable={false}
                                            styles={customStyles}
                                            onChange={(selectedOption) => {
                                              setExpenseFrequency(
                                                selectedOption.value
                                              );
                                              setExpenseFrequencyChange(
                                                (prevValue) => prevValue + 1
                                              );
                                            }}
                                            value={sortOptionsVacationFrequency.filter(
                                              (f) => f.value === expenseFrequency
                                            )}
                                            options={sortOptionsVacationFrequency}
                                          />
                                        )}
                                      {expenseCategoryId != 104 &&
                                        expenseCategoryId != 7 && (
                                          <Select
                                            classNamePrefix="sortSelect"
                                            isSearchable={false}
                                            styles={customStyles}
                                            onChange={(selectedOption) => {
                                              setExpenseFrequency(
                                                selectedOption.value
                                              );
                                              setExpenseFrequencyChange(
                                                (prevValue) => prevValue + 1
                                              );
                                            }}
                                            value={sortOptionsFrequency.filter(
                                              (f) =>
                                                f.value === expenseFrequency
                                            )}
                                            options={sortOptionsFrequency}
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {isExpenseRecurring == false && (
                              <>
                                <div className="row py-3">
                                  <div className="col-md-5 col-12">
                                    <div className="material">
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="Date of One-Time Expense*"
                                        className="mb-0 material d-flex"
                                        style={{
                                          borderBottom: "1px solid #dadada",
                                          paddingTop: "19px",
                                        }}
                                      >
                                        {expenseCategoryId != 121 && (
                                          <ReactDatePicker
                                            setDate={(expenseStartDate) =>
                                              setDate(
                                                expenseStartDate,
                                                "startDate",
                                                "expense"
                                              )
                                            }
                                            select_date={expenseStartDate}
                                            maxDate={
                                              new Date(
                                                new Date().getFullYear(),
                                                11,
                                                31
                                              )
                                            }
                                            minDate={
                                              new Date(
                                                new Date().getFullYear(),
                                                0,
                                                1
                                              )
                                            }
                                          />
                                        )}
                                        {expenseCategoryId == 121 && (
                                          <div>
                                            <ReactDatePicker
                                              setDate={(expenseStartDate) =>
                                                setDate(
                                                  expenseStartDate,
                                                  "startDate",
                                                  "expense"
                                                )
                                              }
                                              select_date={retirementDate}
                                              minDate={retirementDate}
                                              maxDate={lifeExpectancyDate}
                                            />
                                          </div>
                                        )}

                                        <span className="info-hover-box">
                                          <span className="icon">
                                            <img
                                              alt="More information"
                                              src="https://static.fintoo.in/static/assets/img/more_information.svg"
                                            />
                                          </span>
                                          <span className="msg">
                                            In this section, you can add your
                                            current year’s one-time expense.
                                          </span>
                                        </span>
                                      </FloatingLabel>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="row">
                              <div className="col-md-9 col-12">
                                <div className={`form-group mt-1 ${expenseFootnote ? "inputData" : null}`}>
                                  <input type="text" name="Remarks"
                                    onChange={(e) =>
                                      setExpenseFootnote(e.target.value)
                                    }
                                    value={expenseFootnote} autoComplete="off" />
                                  <span class="highlight"></span>
                                  <span class="bar"></span>
                                  <label for="name">Remarks</label>
                                </div>
                              </div>
                            </div>

                            <div className="row py-2">
                              <div className=" text-center">
                                <div>
                                  <div className="btn-container">
                                    <div className="d-flex justify-content-center">
                                      <div
                                        className="previous-btn form-arrow d-flex align-items-center"
                                        onClick={() => {
                                          ScrollToTop();
                                          setTab("tab1")
                                        }
                                        }
                                      >
                                        <FaArrowLeft />
                                        <span className="hover-text">
                                          &nbsp;Previous
                                        </span>
                                      </div>
                                      {addForm && (
                                        <button
                                          onClick={(e) => saveExpenseData(e)}
                                          className="default-btn gradient-btn save-btn"
                                        >
                                          Save & Add More
                                        </button>
                                      )}
                                      {updateForm && (
                                        <div>
                                          <button
                                            onClick={(e) =>
                                              cancelFormData(e, "expense")
                                            }
                                            className="default-btn gradient-btn save-btn"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            onClick={(e) =>
                                              updateExpenseData(e)
                                            }
                                            className="default-btn gradient-btn save-btn"
                                          >
                                            Update
                                          </button>
                                        </div>
                                      )}

                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/datagathering/goals"
                                        }
                                      >
                                        <div className="next-btn form-arrow d-flex align-items-center">
                                          <span
                                            className="hover-text"
                                            style={{ maxWidth: 100 }}
                                          >
                                            Continue&nbsp;
                                          </span>
                                          <FaArrowRight />
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {expenseData.length >= 20 && updateForm == false && (
                  <div className=" py-2">
                    <div className=" text-center">
                      <div>
                        <div className="btn-container">
                          <div
                            className="d-flex justify-content-center"
                            style={{ marginRight: "15%" }}
                          >
                            <a
                              to={
                                process.env.PUBLIC_URL + "/datagathering/about-you"
                              }
                            >
                              <div className="previous-btn form-arrow d-flex align-items-center">
                                <FaArrowLeft />
                                <span className="hover-text">
                                  &nbsp;Previous
                                </span>
                              </div>
                            </a>

                            <div
                              className="next-btn form-arrow d-flex align-items-center"
                              onClick={() => {
                                ScrollToTop();
                                setTab("tab2")
                              }
                              }
                            >
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Continue&nbsp;
                              </span>
                              <FaArrowRight />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {tab === "tab1" ? (
        recurringTotal === recurringCheckboxCount && lifecycleStatus === 2 ? (
          <Modal className="popupmodal" centered show={show} onHide={handleClose}>
            <Modal.Header className="ModalHead">
              <div className="text-center">Alert</div>
            </Modal.Header>
            <div className=" p-5 d-grid place-items-center align-item-center">
              <div className=" HeaderModal">
                <div
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  As you have already generated the report there should be at least one self/spouse's recurring income.
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center pb-5">
              <button
                onClick={() => {
                  handleClose("no", "income");
                }}
                className="outline-btn m-2"
              >
                Ok
              </button>
            </div>
          </Modal>
        ) : (
          <Modal className="popupmodal" centered show={show} onHide={handleClose}>
            <Modal.Header className="ModalHead">
              <div className="text-center">Delete Confirmation</div>
            </Modal.Header>
            <div className=" p-5 d-grid place-items-center align-item-center">
              <div className=" HeaderModal">
                <div
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  This will permanently erase the record and its associated information.
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center pb-5">
              <button
                onClick={() => {
                  handleClose("yes", "income");
                }}
                className="outline-btn m-2"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  handleClose("no", "income");
                }}
                className="outline-btn m-2"
              >
                No
              </button>
            </div>
          </Modal>
        )
      ) : (
        expenseData && expenseRecurringTotal === recurringExpesneCheckboxCount && lifecycleStatus === 2 ? (
          <Modal className="popupmodal" centered show={show} onHide={handleClose}>
            <Modal.Header className="ModalHead">
              <div className="text-center">Alert</div>
            </Modal.Header>
            <div className=" p-5 d-grid place-items-center align-item-center">
              <div className=" HeaderModal">
                <div
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  As you have already generated the report there should be at least one self/spouse's recurring expense.
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center pb-5">
              <button
                onClick={() => {
                  handleClose("no", "expense");
                }}
                className="outline-btn m-2"
              >
                Ok
              </button>
            </div>
          </Modal>
        ) : (
          <Modal className="popupmodal" centered show={show} onHide={handleClose}>
            <Modal.Header className="ModalHead">
              <div className="text-center">Delete Confirmation</div>
            </Modal.Header>
            <div className=" p-5 d-grid place-items-center align-item-center">
              <div className=" HeaderModal">
                <div
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  This will permanently erase the record and its associated information.
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center pb-5">
              <button
                onClick={() => {
                  handleClose("yes", "expense");
                }}
                className="outline-btn m-2"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  handleClose("no", "expense");
                }}
                className="outline-btn m-2"
              >
                No
              </button>
            </div>
          </Modal>
        )
      )}

    </DatagatherLayout>
  );
};
export default IncomeExpense;
